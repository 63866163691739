import React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components/macro";

import { fontStack, fonts } from "./Fonts";
import media, { breakpoints } from "./lib/media";
import { rgba } from "global-styles/lib/colors";

export const colors = {
  primary: "#4760FF",
  accent: "#140C40",
  white: "#FFF",
  blueFaint: "#E8ECF9",
  disabled: rgba("#9995A9", 0.35),
  text: "#140C40",
  rule: rgba("#140C40", 0.12),
  red: "#F05F5A",
  green: "#32BB81",
  fieldBorders: "#D1D5FC",
};

// Define our theme type
export type Theme = {
  colors: typeof colors;
  fontStack: typeof fontStack;
  fonts: typeof fonts;
  media: typeof media;
  breakpoints: typeof breakpoints;
};

// Extend DefaultTheme with our theme so that typescript can
// typecheck the theme object inside styled components
declare module "styled-components" {
  export interface DefaultTheme extends Theme {}
}

export const ThemeProvider = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <StyledThemeProvider
      theme={{ colors, fontStack, fonts, media, breakpoints }}
    >
      {children}
    </StyledThemeProvider>
  );
};
