import styled, { css } from "styled-components/macro";

import { rgba } from "global-styles/lib/colors";

import ColorPicker from "components/Fields/ColorPicker";
import TextButton from "components/Button/Text";
import ResetIcon from "components/Svg/Reset";

export const LogoRow = styled.div`
  display: flex;
  gap: 8rem;

  ${({ theme }) => theme.media.medium} {
    gap: 4rem;
  }
`;

export const LogoField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${({ theme }) => theme.media.medium} {
    justify-content: space-between;
  }
`;

export const ColorField = styled(ColorPicker)`
  ${({ theme }) => theme.media.small} {
    margin-top: 1rem;
  }
`;

const color = css`
  color: ${({ theme }) => rgba(theme.colors.text, 0.6)}};
`;

const Description = styled.span`
  ${color};
`;

const ResetButton = styled(TextButton)`
  ${color};
`;

const Label = styled.span`
  display: flex;
  gap: 0.4rem;

  ${({ theme }) => theme.media.small} {
    flex-direction: column;
    align-items: start;
    gap: 0.5rem;
  }
`;

const LabelText = styled.span`
  display: flex;
  gap: 0.4rem;
`;

type ColorLabelProps = React.PropsWithChildren<{
  type: string;
  description: string;
  onReset: () => void;
}>;

export const ColorFieldLabel = ({
  type,
  description,
  onReset,
}: ColorLabelProps) => {
  return (
    <Label>
      <LabelText>
        <strong>{type}</strong>
        <Description>{description}</Description>
      </LabelText>
      <ResetButton onClick={onReset} leftIcon={<ResetIcon />}>
        reset
      </ResetButton>
    </Label>
  );
};

type LogoLabelProps = {
  title: string;
  dimensions: string;
  description: string;
};

const LogoLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  max-width: 22rem;
`;

const LogoLabelTitleSection = styled.div`
  display: flex;
  gap: 0.5rem;

  ${({ theme }) => theme.media.medium} {
    flex-direction: column;
  }
`;

const Title = styled.h3`
  ${({ theme }) => theme.fontStack.grotesqueBold};
`;

const Dimensions = styled.span`
  color: ${({ theme }) => rgba(theme.colors.text, 0.6)}};
`;

export const LogoLabel = ({
  title,
  dimensions,
  description,
}: LogoLabelProps) => {
  return (
    <LogoLabelWrapper>
      <LogoLabelTitleSection>
        <Title>{title}</Title> <Dimensions>({dimensions} +)</Dimensions>
      </LogoLabelTitleSection>
      <Description>{description}</Description>
    </LogoLabelWrapper>
  );
};
