import { AxiosInstance } from "axios";

import config from "lib/config";
import createClient from "lib/networkClient";
import { IRepository } from "repository/base";

import createAuthRepository from "./auth";

const createDomain = (client: AxiosInstance) => ({
  auth: createAuthRepository(client),
});

type Domain = ReturnType<typeof createDomain>;

class LocalRepository implements IRepository<Domain> {
  client: AxiosInstance;
  domain: Domain;

  constructor(configOverrides = {}) {
    this.client = createClient({
      ...configOverrides,
      baseURL: `${config.LOCAL_API_URL}/api`,
    });

    this.domain = createDomain(this.client);
  }
}

export default LocalRepository;
