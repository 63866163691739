import React from "react";

import * as Styled from "./styles";

export type TextButtonProps = React.PropsWithChildren<
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
  }
>;

const TextButton = ({
  children,
  leftIcon,
  rightIcon,
  ...props
}: TextButtonProps) => {
  if (!children) {
    return null;
  }

  return (
    <Styled.TextButton type="button" {...props}>
      {!!leftIcon && <>{leftIcon}</>}

      {children}

      {!!rightIcon && <>{rightIcon}</>}
    </Styled.TextButton>
  );
};

export default TextButton;
