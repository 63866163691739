import React from "react";
import { useHistory } from "react-router-dom";

import routes, { RouteKey, RouteValue } from "lib/routes";

import * as Styled from "./styles";

type SkipButtonProps = {
  to: RouteKey | RouteValue;
};

const SkipButton = ({ to }: SkipButtonProps) => {
  const { push: pushRoute } = useHistory();

  const onClick = React.useCallback(() => {
    let nextRoute = routes[to as RouteKey] ?? to;

    pushRoute(nextRoute);
  }, [to, pushRoute]);

  return (
    <Styled.SkipButton type="button" onClick={onClick}>
      Skip and do this later
    </Styled.SkipButton>
  );
};

export default SkipButton;
