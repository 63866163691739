import { AxiosInstance, AxiosRequestConfig } from "axios";

import config from "lib/config";
import createClient from "lib/networkClient";
import { IRepository } from "repository/base";
import { sessionCache } from "context/session";

import createCountryRepository from "./country";
import createTeamRepository from "./team";
import createManagerRepository from "./manager";
import createHubspotRepository from "./hubspot";

const createDomain = (client: AxiosInstance) => ({
  country: createCountryRepository(client),
  team: createTeamRepository(client),
  manager: createManagerRepository(client),
  hubspot: createHubspotRepository(client),
});

type Domain = ReturnType<typeof createDomain>;

function setSession(config: AxiosRequestConfig) {
  if (!!sessionCache.accessToken) {
    config!.headers = {
      ...(config!.headers || {}),
      Authorization: `Bearer ${sessionCache.accessToken}`,
    };
  }
}

class DeskpassRepository implements IRepository<Domain> {
  client: AxiosInstance;
  domain: Domain;

  constructor(
    configOverrides: AxiosRequestConfig = {},
    onClientInit: (c: AxiosInstance) => void = () => null
  ) {
    const client = createClient({
      ...configOverrides,
      baseURL: config.API_URL,
    });

    // Make sure Auth header is set if session is stablished
    client.interceptors.request.use((config) => {
      setSession(config);
      return config;
    });

    this.client = client;

    onClientInit!(client);

    this.domain = createDomain(client);
  }
}

export default DeskpassRepository;
