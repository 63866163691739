const VALID_QUERY_PARAMS = [
  "firstName",
  "lastName",
  "email",
  "company",
  "country",
  "coupon",
];

export default VALID_QUERY_PARAMS;
