import styled from "styled-components/macro";
import { rgba } from "global-styles/lib/colors";

export const Tabs = styled.div`
  position: relative;
`;

export const TabList = styled.div`
  display: inline-flex;
`;

type StyledTabProps = {
  active: boolean;
  clickable: boolean;
};

export const Tab = styled.div<StyledTabProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  font-size: 1.5rem;
  font-weight: bold;

  height: 2.5rem;
  margin-left: 1.5rem;
  padding: 0 1rem;
  color: ${({ active, theme: { colors } }) =>
    active ? colors.primary : rgba(colors.text, 0.2)};

  user-select: none;
  cursor: ${({ active, clickable }) =>
    !active && !clickable ? "not-allowed" : "pointer"};

  &:first-child {
    margin-left: 0;
  }

  /* Innactive bar */
  :before {
    content: "";
    position: absolute;
    bottom: 0;
    height: 0.5rem;
    width: 100%;
    background: ${({ theme }) => rgba(theme.colors.text, 0.2)};
  }

  /* Active bar with animation */
  :after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 0.5rem;
    background: ${({ theme }) => theme.colors.primary};
    transition: width 0.1s linear;
    width: ${({ active }) => (active ? "100%" : "0")};
  }

  ${({ theme }) => theme.media.smallMedium} {
    font-size: 1.2rem;
    padding: 0 0.6rem;
    margin-left: 1rem;

    &:before,
    &:after {
      height: 0.4rem;
    }
  }

  ${({ theme }) => theme.media.xsmall} {
    font-size: 1.1rem;
    padding: 0 0.6rem;
    margin-left: 0.5rem;

    &:before,
    &:after {
      height: 0.3rem;
    }
  }
`;
