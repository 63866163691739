import React from "react";

type Ref = React.ForwardedRef<SVGSVGElement> | null;

type DefaultProps = {
  width?: number | string;
  height?: number | string;
};

type Props = React.SVGProps<SVGSVGElement> & DefaultProps;
type DeskSVGProps = Props & {
  svgRef?: Ref;
};

const defaultProps: DefaultProps = {
  width: 67,
  height: 34,
};

function DeskSVG({ width, height, svgRef, ...props }: DeskSVGProps) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M33.48 7.485a3.536 3.536 0 1 0 0-7.072 3.536 3.536 0 0 0 0 7.072ZM33.67 9.138h-.354a5.967 5.967 0 0 0-5.967 5.967v4.087a5.967 5.967 0 0 0 5.967 5.967h.354a5.967 5.967 0 0 0 5.967-5.967v-4.087a5.967 5.967 0 0 0-5.967-5.967Z"
          fill="#4760FF"
        />
        <path
          d="M36.067 22.038h-5.143L28.96 33.16h9.07l-1.964-11.12Z"
          fill="#4760FF"
        />
        <g opacity={0.25} fill="#140C40">
          <path d="M17.573 7.485a3.536 3.536 0 1 0 0-7.072 3.536 3.536 0 0 0 0 7.072ZM17.754 9.138H17.4a5.967 5.967 0 0 0-5.967 5.967v4.087a5.967 5.967 0 0 0 5.967 5.967h.354a5.967 5.967 0 0 0 5.967-5.967v-4.087a5.967 5.967 0 0 0-5.967-5.967Z" />
          <path d="M20.152 22.038h-5.144L13.045 33.16h9.07l-1.963-11.12Z" />
        </g>
        <g opacity={0.25} fill="#140C40">
          <path d="M49.38 7.485a3.536 3.536 0 1 0 0-7.072 3.536 3.536 0 0 0 0 7.072ZM49.56 9.138h-.354a5.967 5.967 0 0 0-5.967 5.967v4.087a5.967 5.967 0 0 0 5.967 5.967h.354a5.967 5.967 0 0 0 5.968-5.967v-4.087a5.967 5.967 0 0 0-5.968-5.967Z" />
          <path d="M51.957 22.038h-5.143L44.852 33.16h9.069l-1.964-11.12Z" />
        </g>
        <path
          d="M66.957 14.14H0v4.006h66.957V14.14ZM6.912 19.726H2.906V33.18h4.006V19.726ZM64.056 19.726h-4.005V33.18h4.005V19.726Z"
          fill="#140C40"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill="#fff"
            transform="translate(0 .413)"
            d="M0 0h66.957v32.772H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

DeskSVG.defaultProps = defaultProps;

const ForwardRef = React.forwardRef((props: Props, ref: Ref) => (
  <DeskSVG {...props} svgRef={ref} />
));
const Memo = React.memo(ForwardRef);

export default Memo;
