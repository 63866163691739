import queryString from "query-string";
import { AxiosResponse, AxiosInstance, AxiosRequestConfig } from "axios";

export interface IRepository<D> {
  client: AxiosInstance;
  domain: D;
}

export type ResponsePromise<T> = Promise<AxiosResponse<T>>;

export type RepositoryProps = {
  path: string;
  client: AxiosInstance;
};

export type Query = Record<string, any>;
export type ID = string | number;

export type MethodOptions = {
  query?: Query;
  config?: AxiosRequestConfig;
};

export type FindAll<T> = (o?: MethodOptions) => ResponsePromise<[T]>;
export type FindOne<T> = (id: ID, o?: MethodOptions) => ResponsePromise<T>;

// TODO remove the void bit after implementing the methods
export type Save<T> = (args: any) => ResponsePromise<T> | void;
export type Update<T> = (args: any) => ResponsePromise<T> | void;
export type Remove<T> = (args: any) => ResponsePromise<T> | void;

const _pathBuilder = (path: string, query?: Query) => {
  if (!!Object.keys(query ?? {}).length) {
    path += `?${queryString.stringify(query ?? {})}`;
  }

  return path;
};

const createBaseRepository = <T>({ client, path }: RepositoryProps) => {
  const findAll: FindAll<T> = ({ query, config } = {}) => {
    let finalPath = _pathBuilder(path, query);

    return client.get<[T]>(finalPath, config);
  };

  const findOne: FindOne<T> = (id: ID, { query, config } = {}) => {
    let finalPath = _pathBuilder(`${path}/${id}`, query);

    return client.get<T>(finalPath, config);
  };

  // TODO implement the remaining methods
  const save: Save<T> = () => {};
  const update: Update<T> = () => {};
  const remove: Remove<T> = () => {};

  return { findAll, findOne, save, update, remove };
};

export default createBaseRepository;
