import styled, { css } from "styled-components/macro";

import { rgba } from "global-styles/lib/colors";
import { renderStyleFromProps } from "global-styles/lib/props";

const borderColor = css`
  ${({ theme }) => theme.colors.fieldBorders}
`;

const innerCrossBackground = css`
  background: linear-gradient(
      to top left,
      transparent 0%,
      transparent calc(50% - 1px),
      ${borderColor} 50%,
      transparent calc(50% + 1px),
      transparent 100%
    ),
    linear-gradient(
      to top right,
      transparent 0%,
      transparent calc(50% - 1px),
      ${borderColor} 50%,
      transparent calc(50% + 1px),
      transparent 100%
    );
`;

export const FileField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
`;

export const Inner = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type CrossedBackgroundProps = {
  blurred: boolean;
};

export const CrossedBackground = styled.div<CrossedBackgroundProps>`
  ${innerCrossBackground}
  height: 100%;

  ${({ blurred }) =>
    blurred &&
    `
    filter: blur(2px);
  `}
`;

type DropAreaProps = {
  width: string;
  height: string;
};

export const DropArea = styled.div<DropAreaProps>`
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  outline: 0;

  &:focus-within {
    box-shadow: 0 0 0 0.2rem
      ${({ theme }) => rgba(theme.colors.fieldBorders, 0.3)};
  }

  ${renderStyleFromProps("width")}
  ${renderStyleFromProps("height")}
`;

export const Borders = styled.div`
  height: 100%;
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: ${borderColor};
  padding: 2px;
  background: transparent;
  position: absolute;
  z-index: 2;
`;

export const Image = styled.img`
  height: auto;
  width: 100%;
  padding: 5px;
`;
