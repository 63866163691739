import { matchPath } from "react-router-dom";

const routes = {
  setup: "/setup",
  reservation: "/reservation",
  customization: "/customization",
  team: "/team",
  billing: "/billing",
};

type ValueOf<T> = T[keyof T];

export type RouteKey = keyof typeof routes;
export type RouteValue = ValueOf<typeof routes>;

type Rules = {
  isAccessible: (...args: any[]) => boolean;
  fallback: string;
};

let fallback = routes.setup;

export const rulesByRoute: Record<RouteKey, Rules> = {
  setup: {
    isAccessible: () => true,
    fallback: routes.reservation,
  },
  reservation: {
    isAccessible: (authenticated) => authenticated,
    fallback,
  },
  customization: {
    isAccessible: (authenticated) => authenticated,
    fallback,
  },
  team: {
    isAccessible: (authenticated) => authenticated,
    fallback,
  },
  billing: {
    isAccessible: (authenticated) => authenticated,
    fallback,
  },
};

type GetRouteNameByPath = (
  path: string,
  exact?: boolean
) => RouteKey | undefined;

export const getRouteKeyByPath: GetRouteNameByPath = (
  path = "",
  exact = true
) => {
  // Remove query params to test if the route exists
  const pathname = path.split("?")[0];
  const routeKeys = Object.keys(routes) as RouteKey[];

  return routeKeys.find((routeKey) => {
    const path = routes[routeKey];
    return !!matchPath(pathname, { path, exact });
  });
};

export default routes;
