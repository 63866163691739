import styled, { css } from "styled-components/macro";
import Lottie from "lottie-react";

import bummedLogo from "assets/lottie/bummed-logo.json";

import { scaleWithVW } from "global-styles/lib/scaling";

const vertical = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2.5rem;
`;

const defaultSpacing = css`
  padding: 5rem 5% 5rem 5%;

  ${({ theme }) => theme.media.small} {
    padding-left: ${scaleWithVW("2rem")};
    padding-right: ${scaleWithVW("2rem")};
  }
`;

export const Page = styled.div`
  width: 100%;
  height: 100%;

  ${vertical};
  ${defaultSpacing}
`;

export const HeaderSection = styled.section`
  width: 100%;
`;

export const MainSection = styled.section`
  ${vertical};
`;

export const FooterSection = styled.section`
  width: 100%;
`;

export const Title = styled.h1`
  ${({ theme }) => theme.fontStack.grotesqueMedium};

  font-size: 2.8em;
  margin: 0;

  ${({ theme }) => theme.media.medium} {
    font-size: 2em;
  }

  ${({ theme }) => theme.media.small} {
    font-size: 1.5em;
  }
`;

export const Logo = styled.div`
  height: 4rem;
  width: 17rem;
`;

export const Description = styled.h2`
  ${({ theme }) => theme.fontStack.simula};

  text-align: center;
  max-width: 60rem;
  font-size: 1.5em;
  line-height: 1.5;

  ${({ theme }) => theme.media.medium} {
    font-size: 1.3em;
    line-height: 1.3;
  }
`;

export const ButtonArea = styled.div`
  width: 15rem;

  ${({ theme }) => theme.media.medium} {
    width: 100%;
  }
`;

const BummedLogoWrapper = styled(Lottie)`
  max-width: ${scaleWithVW("10rem", { ratio: 50 })};
`;

export const BummedLogo = () => {
  return <BummedLogoWrapper animationData={bummedLogo} loop />;
};
