import React from "react";

import { ThemeProvider } from "global-styles/Theme";
import { HubspotProvider } from "context/hubspot";

import { Compose } from "lib/react";

import GenericErrorPage from "./GenericError";

//
// If we want to add more types of errors in the future this
// can receive the error from the boundary as props and decide
// what error page to render.
//
const ErrorPages = () => {
  return (
    <Compose components={[ThemeProvider, HubspotProvider]}>
      <GenericErrorPage />
    </Compose>
  );
};

export default ErrorPages;
