import React from "react";
import Helmet from "react-helmet";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { useForm, UseFormRegisterReturn } from "react-hook-form";
import asyncDebounce from "awesome-debounce-promise";
import {
  AsYouType,
  validatePhoneNumberLength,
  isValidPhoneNumber,
  parseNumber,
  CountryCode,
  ParsedNumber,
} from "libphonenumber-js";

import Field from "components/Fields/Field";
import Button from "components/Button";
import CountrySelect from "components/Fields/Select/Country";

import useDeskpassRepository from "hooks/useRepository/deskpass";
import useEvent from "hooks/useEvent";

import SessionContext from "context/session";
import NotificationContext from "context/notification";

import * as patterns from "lib/patterns";
import routes from "lib/routes";
import * as browserStorage from "lib/browserStorage";
import logger from "lib/log";

import * as StyledPage from "pages/styles";
import * as Styled from "./styles";

export type TeamSetupFields = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  company: string;
  password: string;
  country: string;
  coupon: string;
};

const SetupPage = browserStorage.withFormSubmittedState("setup")(
  ({ submittedState }) => {
    const { push: pushRoute } = useHistory();
    const { displayError } = React.useContext(NotificationContext);
    const { search } = useLocation();
    const queryParams = queryString.parse(search);

    const {
      signup,
      updateTeamAndManager,
      currentUser,
      currentTeam,
      authenticated,
    } = React.useContext(SessionContext);

    const editMode = submittedState && authenticated;

    const instance = React.useRef<Record<string, any>>({});

    const [processing, setProcessing] = React.useState(() => false);
    const [processingTeamNameCheck, setProcessingTeamNameCheck] =
      React.useState(() => false);

    const [, checkTeamExistsWithName] = useDeskpassRepository(
      (repository) => (name: string) => repository.team.existsWithName(name),
      { fireOnMount: false }
    );

    React.useEffect(() => {
      instance.current.checkTeamExistsWithNameDebounced = asyncDebounce(
        checkTeamExistsWithName,
        400
      );
    }, [checkTeamExistsWithName]);

    const defaultTeamName = currentTeam?.name;

    const getDefault = React.useCallback(
      (
        fieldName: keyof TeamSetupFields,
        getValue: Function = () => "",
        fallback: any = ""
      ) => {
        if (!editMode) {
          return queryParams[fieldName] ?? fallback;
        }

        return getValue();
      },
      [editMode, queryParams]
    );

    const countryInitialValue = getDefault(
      "country",
      () => currentTeam!.country.code.toLowerCase(),
      "us"
    );

    const defaultValues = {
      firstName: getDefault("firstName", () => currentUser!.firstName),
      lastName: getDefault("lastName", () => currentUser!.lastName),
      email: getDefault("email", () => currentUser?.email?.toLowerCase?.()),
      phone: getDefault("phone", () => currentUser?.phone),
      company: getDefault("company", () => defaultTeamName),
      country: countryInitialValue,
      password: "",
      coupon: getDefault("coupon", () => defaultTeamName),
    };

    const { register, formState, handleSubmit, watch, resetField } =
      useForm<TeamSetupFields>({
        mode: "all",
        shouldUnregister: true,
        defaultValues,
      });

    const firstNameField = register("firstName", {
      required: "First name is required.",
    });

    const lastNameField = register("lastName", {
      required: "Last name is required.",
    });

    const couponField = register("coupon");

    const countryField = register("country", {
      onChange: () => {
        resetField("phone");
      },
    });

    const country = watch("country");

    const handlePhoneKeyPress = useEvent(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        const countryCode = country.toUpperCase() as CountryCode;

        if (
          ["TOO_LONG", undefined].includes(
            validatePhoneNumberLength(e.currentTarget.value, countryCode)
          )
        ) {
          e.preventDefault();
          return;
        }
      }
    );

    const phoneField = register("phone", {
      required: editMode ? false : "Phone number is required.",
      onChange: (e) => {
        const countryCode = country.toUpperCase() as CountryCode;
        const formatter = new AsYouType(countryCode);

        e.target.value = formatter.input(e.target.value);
      },
      validate: (phone: string) => {
        const countryCode = country.toUpperCase() as CountryCode;

        if (!!phone && !isValidPhoneNumber(phone, countryCode)) {
          return `Invalid "${countryCode}" phone number.`;
        }
      },
    });

    const emailField = register("email", {
      required: "Email is required.",
      onChange: (e) => {
        e.target.value = e.target.value.toLowerCase();
      },
      pattern: {
        value: patterns.email,
        message: "A valid email address is required",
      },
    });

    const validateCompany = React.useCallback(
      async (company: string) => {
        const errorMessage = "This team already exists.";
        const {
          lastCompanyValue,
          companyFailedLastCheck,
          checkTeamExistsWithNameDebounced,
        } = instance.current;

        if (!!company) {
          const isEditModeDefaultValue =
            editMode && company === defaultTeamName;

          // When the field value does not change just shoot back the last result
          if (lastCompanyValue === company || isEditModeDefaultValue) {
            if (companyFailedLastCheck) {
              return errorMessage;
            }

            return;
          }

          try {
            setProcessingTeamNameCheck(true);

            const { data: companyExists } =
              await checkTeamExistsWithNameDebounced(company);

            instance.current.lastCompanyValue = company;

            if (companyExists) {
              instance.current.companyFailedLastCheck = true;
              return errorMessage;
            }

            instance.current.companyFailedLastCheck = false;
          } catch (err) {
            logger.error(
              `An error has occurred while checking if company with name ${company} exists.`
            );
          } finally {
            setProcessingTeamNameCheck(false);
          }
        }
      },
      [editMode, defaultTeamName]
    );

    const companyField = register("company", {
      required: "Company Name is required.",
      validate: validateCompany,
    });

    const passwordField = register("password", {
      required: editMode ? false : "Password is required.",
      minLength: {
        value: 12,
        message: "Password should have at least 12 characters.",
      },
    });

    const { errors, touchedFields, isValid, dirtyFields, isDirty } = formState;

    const isFieldValid = React.useCallback(
      (fieldName: keyof TeamSetupFields) => {
        const isTouched = touchedFields[fieldName];
        const hasError = !!errors[fieldName]?.message;

        return isTouched && !hasError;
      },
      [touchedFields, errors]
    );

    const teamSignup = React.useCallback(
      async (data: TeamSetupFields) => {
        try {
          setProcessing(true);

          await signup(data);
          await browserStorage.setFormSubmittedState("setup", true);

          // Only update pathname to remove query params
          // if they come from the marketing site
          pushRoute({ pathname: routes.reservation });
        } catch (err) {
          displayError(err);

          logger.error("Error submitting Setup page: ", err);
          setProcessing(false);
        }
      },
      [signup, pushRoute, displayError]
    );

    const editTeamManager = React.useCallback(
      async (data: TeamSetupFields) => {
        try {
          setProcessing(true);

          const getDirtyFields = (
            fieldNames: Array<keyof TeamSetupFields>,
            fieldNameMap?: Record<string, string>
          ) => {
            return fieldNames.reduce((acc, fieldName) => {
              if (dirtyFields[fieldName]) {
                const name = fieldNameMap?.[fieldName] ?? fieldName;
                return { ...acc, [name]: data[fieldName] };
              }

              return acc;
            }, {});
          };

          await updateTeamAndManager(
            {
              teamOrganization: getDirtyFields(["company", "country"], {
                company: "name",
                country: "countryCode",
              }),
              teamManager: getDirtyFields([
                "firstName",
                "lastName",
                "email",
                "password",
              ]),
            },
            "updateSetup"
          );

          pushRoute(routes.reservation);
        } catch (err) {
          displayError(err);

          logger.error("Error editting Setup page: ", err);
          setProcessing(false);
        }
      },
      [updateTeamAndManager, dirtyFields, pushRoute, displayError]
    );

    const onSubmit = React.useCallback(
      (teamSetupFields: TeamSetupFields) => {
        const countryCode = country.toUpperCase() as CountryCode;

        const data = {
          ...teamSetupFields,
          email: teamSetupFields.email.toLowerCase(),
          phone: (
            parseNumber(teamSetupFields.phone, countryCode) as ParsedNumber
          ).phone,
        };

        if (editMode) {
          editTeamManager(data);
        } else {
          teamSignup(data);
        }
      },
      [teamSignup, editTeamManager, editMode, country]
    );

    const submitDisabled = !isValid || processing || (editMode && !isDirty);

    const getFieldProps = React.useCallback(
      (field: UseFormRegisterReturn) => {
        const fieldName = field.name as keyof TeamSetupFields;

        const showValidIcon = editMode
          ? touchedFields[fieldName] && dirtyFields[fieldName]
          : touchedFields[fieldName];

        return {
          ...field,
          errorMessage: errors[fieldName]?.message,
          valid: isFieldValid(fieldName),
          showValidIcon,
        };
      },
      [errors, touchedFields, isFieldValid, editMode, dirtyFields]
    );

    return (
      <StyledPage.Container compact>
        <Helmet>
          <title>Deskpass - Teams Onboarding | Setup Account</title>
          <meta
            name="description"
            content="Sets up an account for your organization"
          />
        </Helmet>

        <StyledPage.HeaderSection>
          <StyledPage.Header>Set up your account</StyledPage.Header>

          <StyledPage.Subheader>
            It’s free to set up your account. You’ll only be charged whenever
            someone on your team books something.
          </StyledPage.Subheader>
        </StyledPage.HeaderSection>

        <StyledPage.Form onSubmit={handleSubmit(onSubmit)}>
          <Styled.Row>
            <Field {...getFieldProps(emailField)} placeholder="Email" />

            <CountrySelect
              {...countryField}
              initialValue={countryInitialValue}
            />
          </Styled.Row>

          <Styled.Row>
            <Field
              {...getFieldProps(firstNameField)}
              placeholder="First name"
            />

            <Field {...getFieldProps(lastNameField)} placeholder="Last name" />
          </Styled.Row>

          <Field
            {...getFieldProps(companyField)}
            processing={processingTeamNameCheck}
            placeholder="Company Name"
          />

          <Field
            {...getFieldProps(passwordField)}
            type="password"
            placeholder={editMode ? "Change password" : "Password"}
            sufix="12+ characters"
          />

          <Field
            {...getFieldProps(phoneField)}
            placeholder={`Phone Number${editMode ? " (Optional)" : ""}`}
            onKeyPress={handlePhoneKeyPress}
          />

          {!editMode && (
            <Field
              {...getFieldProps(couponField)}
              placeholder={"Promotional Code (Optional)"}
              showValidIcon={false}
            />
          )}

          <Button
            type="submit"
            processing={processing}
            disabled={submitDisabled}
          >
            {editMode ? "Update" : "Create"} Team
          </Button>
        </StyledPage.Form>
      </StyledPage.Container>
    );
  }
);

export default SetupPage;
