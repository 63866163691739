import React from "react";

import Logo from "components/Svg/Logo";
import Footer from "components/Layout/Footer";
import SupportLink from "components/Button/SupportLink";
import Button from "components/Button";

import useEvent from "hooks/useEvent";

import * as Styled from "./styles";

type Props = {
  title?: React.ReactNode;
  description?: React.ReactNode;
};

const GenericError = ({ title, description }: Props) => {
  const reload = useEvent(() => window.location.reload());

  return (
    <Styled.Page>
      <Styled.HeaderSection>
        <Styled.Logo>
          <Logo />
        </Styled.Logo>
      </Styled.HeaderSection>

      <Styled.MainSection>
        <Styled.BummedLogo />
        <Styled.Title>{title}</Styled.Title>
        <Styled.Description>{description}</Styled.Description>
        <Styled.ButtonArea>
          <Button onClick={reload}>Refresh page</Button>
        </Styled.ButtonArea>
      </Styled.MainSection>

      <Styled.FooterSection>
        <Footer />
      </Styled.FooterSection>
    </Styled.Page>
  );
};

GenericError.defaultProps = {
  title: "Whoops - something's up.",
  description: (
    <>
      An error occurred and we can't figure out what went wrong yet. Try
      refreshing in a few minutes a few minutes. If you still have issues,
      please <SupportLink primary>contact support</SupportLink>.
    </>
  ),
};

export default GenericError;
