import React from "react";

import useEvent from "hooks/useEvent";
import useMount from "hooks/useMount";
import useUnmount from "hooks/useUnmount";

type Event = MouseEvent | TouchEvent;
type Handler = (e: Event) => void;

const useOutsideClick = <T extends HTMLElement = HTMLElement>(
  ref: React.Ref<T> | React.MutableRefObject<T>,
  handler: Handler
) => {
  const disposerRef = React.useRef<Handler>(() => null);

  const getCurrentRef = useEvent(() => ref);

  const onResize = useEvent(handler);

  const registerListener = useEvent((ref: T | React.MutableRefObject<T>) => {
    const listener = (event: Event) => {
      let domEl = ref;

      if (!!(ref as React.MutableRefObject<T>).current) {
        domEl = (ref as React.MutableRefObject<T>).current;
      }

      if ((domEl as T).contains?.((event?.target as Node) || null)) {
        return;
      }

      // Call the handler only if the click is outside of the element passed.
      onResize(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return listener;
  });

  let newRef = ref;

  // TODO test this better as I think it's still possible to add many listeners
  // when a callback ref is provided instead of a regular ref
  //
  // Handles callback refs. This only works if the
  // caller of this custom hook pass the returned
  // ref to the element that it's intended to go.
  const funcRef = useEvent((domEl: T) => {
    if (!!ref && typeof ref === "function") {
      ref(domEl);
      const listener = registerListener(domEl);
      disposerRef.current = listener;
    }
  });

  if (!!ref && typeof ref === "function") {
    newRef = funcRef;
  }

  useMount(() => {
    const ref = getCurrentRef() as React.MutableRefObject<T>;

    if (!!ref && typeof ref !== "function") {
      const listener = registerListener(ref);
      disposerRef.current = listener!;
    }
  });

  useUnmount(() => {
    if (disposerRef.current) {
      // Disposes of the event listeners
      document.removeEventListener("mousedown", disposerRef.current);
      document.removeEventListener("touchstart", disposerRef.current);
    }
  });

  // Returns the ref to the caller. Important for callback refs to work
  return newRef;
};

export default useOutsideClick;
