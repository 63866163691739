import React from "react";

type Ref = React.ForwardedRef<SVGSVGElement> | null;

type DefaultProps = {
  width?: number | string;
  height?: number | string;
};

type Props = React.SVGProps<SVGSVGElement> & DefaultProps;
type GuestsSVGProps = Props & {
  svgRef?: Ref;
};

const defaultProps: DefaultProps = {
  width: 64,
  height: 32,
};

function GuestsSVG({ width, height, svgRef, ...props }: GuestsSVGProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      width={width}
      height={height}
      xmlSpace="preserve"
      ref={svgRef}
      {...props}
    >
      <path
        fill="#c2c2cc"
        d="M31.9 6.8c1.9 0 3.4-1.5 3.4-3.4S33.8 0 31.9 0s-3.4 1.5-3.4 3.4c.1 1.9 1.6 3.4 3.4 3.4zM37.8 18v-3.9c0-3.1-2.5-5.7-5.7-5.7h-.3c-3.1 0-5.7 2.5-5.7 5.7V18c0 2.2 1.2 4.1 3 5l-1.5 8.3h8.7L34.8 23c1.8-1 3-2.8 3-5z"
      />
      <path
        fill="#4760ff"
        d="M16.8 6.8c1.9 0 3.4-1.5 3.4-3.4S18.7 0 16.8 0s-3.4 1.5-3.4 3.4 1.5 3.4 3.4 3.4zM22.6 18v-3.9c0-3.1-2.5-5.7-5.7-5.7h-.3c-3.1 0-5.7 2.5-5.7 5.7V18c0 2.2 1.2 4.1 3 5l-1.5 8.3h8.7L19.6 23c1.8-1 3-2.8 3-5z"
      />
      <path
        fill="#28afb0"
        d="M47.1 6.8c1.9 0 3.4-1.5 3.4-3.4S49 0 47.1 0s-3.4 1.5-3.4 3.4c.1 1.9 1.6 3.4 3.4 3.4zM53 18v-3.9c0-3.1-2.5-5.7-5.7-5.7H47c-3.1 0-5.7 2.5-5.7 5.7V18c0 2.2 1.2 4.1 3 5l-1.5 8.3h8.7L50 23c1.8-1 3-2.8 3-5z"
      />
      <path
        fill="#140c40"
        d="M0 13.2h63.9V17H0zM2.8 18.5h3.8v12.8H2.8zM57.3 18.5h3.8v12.8h-3.8z"
      />
    </svg>
  );
}

GuestsSVG.defaultProps = defaultProps;

const ForwardRef = React.forwardRef((props: Props, ref: Ref) => (
  <GuestsSVG {...props} svgRef={ref} />
));
const Memo = React.memo(ForwardRef);

export default Memo;
