import React from "react";

type Ref = React.ForwardedRef<SVGSVGElement> | null;

type DefaultProps = {
  width?: number | string;
  height?: number | string;
};

type Props = React.SVGProps<SVGSVGElement> & DefaultProps;
type OfficeSVGProps = Props & {
  svgRef?: Ref;
};

const defaultProps: DefaultProps = {
  width: 106,
  height: 29,
};

function OfficeSVG({ width, height, svgRef, ...props }: OfficeSVGProps) {
  const color1 = "#c2c2cc";
  const color2 = "#140c40";

  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      width={width}
      height={height}
      xmlSpace="preserve"
      ref={svgRef}
      {...props}
    >
      <path
        fill={color1}
        d="M14.2 6.8c1.6 0 2.9-1.3 2.9-2.9S15.8 1 14.2 1c-1.6 0-2.9 1.3-2.9 2.9s1.3 2.9 2.9 2.9zM19.3 16.5v-3.4c0-2.7-2.2-5-5-5H14c-2.7 0-5 2.2-5 5v3.4c0 1.9 1.1 3.5 2.6 4.3l-1.3 7.3H18l-1.3-7.3c1.5-.8 2.6-2.4 2.6-4.3zM27.4 6.8c1.6 0 2.9-1.3 2.9-2.9S29 1 27.4 1s-2.9 1.3-2.9 2.9 1.3 2.9 2.9 2.9zM32.5 16.5v-3.4c0-2.7-2.2-5-5-5h-.3c-2.7 0-5 2.2-5 5v3.4c0 1.9 1.1 3.5 2.6 4.3l-1.3 7.3H31l-1.3-7.3c1.8-.8 2.8-2.4 2.8-4.3z"
      />
      <path fill={color2} d="M5.5 16.9H2.2v11.2h3.3V16.9z" />
      <path
        fill={color1}
        d="M78.2 7.3c1.6 0 2.9-1.3 2.9-2.9s-1.3-2.9-2.9-2.9c-1.6 0-2.9 1.3-2.9 2.9s1.2 2.9 2.9 2.9zM83.3 17.1v-3.4c0-2.7-2.2-5-5-5H78c-2.7 0-5 2.2-5 5v3.4c0 1.9 1.1 3.5 2.6 4.3l-1.3 7.3h7.5l-1.3-7.3c1.7-.8 2.8-2.4 2.8-4.3zM65 7.3c1.6 0 2.9-1.3 2.9-2.9S66.6 1.5 65 1.5c-1.6 0-2.9 1.3-2.9 2.9s1.3 2.9 2.9 2.9zM70.1 17.1v-3.4c0-2.7-2.2-5-5-5h-.3c-2.7 0-5 2.2-5 5v3.4c0 1.9 1.1 3.5 2.6 4.3l-1.3 7.3h7.5l-1.3-7.3c1.7-.8 2.8-2.4 2.8-4.3zM91.4 7.3c1.6 0 2.9-1.3 2.9-2.9S93 1.5 91.4 1.5c-1.6 0-2.9 1.3-2.9 2.9s1.3 2.9 2.9 2.9zM96.5 17.1v-3.4c0-2.7-2.2-5-5-5h-.3c-2.7 0-5 2.2-5 5v3.4c0 1.9 1.1 3.5 2.6 4.3l-1.3 7.3H95l-1.3-7.3c1.7-.8 2.8-2.4 2.8-4.3z"
      />
      <path
        fill={color2}
        d="M0 12.3h42v3.3H0zM36.3 16.9h3.3v11.2h-3.3zM50.4 12.8H106v3.3H50.4zM52.8 17.5h3.3v11.2h-3.3zM103.6 17.5h-3.3v11.2h3.3V17.5z"
      />
    </svg>
  );
}

OfficeSVG.defaultProps = defaultProps;

const ForwardRef = React.forwardRef((props: Props, ref: Ref) => (
  <OfficeSVG {...props} svgRef={ref} />
));
const Memo = React.memo(ForwardRef);

export default Memo;
