import { css } from "styled-components/macro";

type CustomMediaQuery = (maxWidth: number) => string;

const customMediaQuery: CustomMediaQuery = (maxWidth) =>
  `@media (max-width: ${maxWidth}px)`;

export const breakpoints = {
  xsmall: 320,
  small: 360,
  smallMedium: 414,
  medium: 768,
  large: 1024,
  xlarge: 1280,
};

type BreakpointKey = keyof typeof breakpoints;
type Media = { [key in BreakpointKey]: string };

const mediaFromBreakpoints = () =>
  Object.keys(breakpoints).reduce<Media>(
    (acc, key) => ({
      ...acc,
      [key]: customMediaQuery(breakpoints[key as BreakpointKey]),
    }),
    {} as Media
  );

const cssPxCoercion = (value: string | number) => {
  let parsed = +value;
  return isNaN(parsed) ? value : `${value}px`;
};

const isNumber = (value: string | number | undefined) => {
  if (value === undefined) return false;

  return !isNaN(+value);
};

/*
 * There are more valid units than this but we're not using.
 * Here's a list: https://www.w3schools.com/cssref/css_units.asp
 * If we ever need more just add here.
 */
const validUnits = ["px", "rem", "em", "%", "vw", "vh"];

/*
 * Validate if a provided arg is a valid css number with a valid unit
 * from the ones we declared in "validUnits".
 */
export const isValidCssUnit = (unitString: string | undefined) => {
  if (typeof unitString !== "string") return false;

  let unitUsed = validUnits.find((it) => unitString.endsWith(it));

  if (!unitUsed) return false;

  let [numberBits] = unitString.split(unitUsed);

  return isNumber(numberBits);
};

export const parseValidMeasureUnitOrBreakpoint = (
  value: string | number | undefined
) => {
  // Returns valid breakpoints
  if (!!breakpoints[value as BreakpointKey]) {
    return cssPxCoercion(breakpoints[value as BreakpointKey]);
  }

  // Coerce numbers to "px"
  if (isNumber(value)) {
    return cssPxCoercion(value as number);
  }

  // Accept valid CSS
  if (isValidCssUnit(value as string)) {
    return value;
  }

  // Otherwise yield null
  return null;
};

type RangeArgs =
  | string
  | {
      from?: string;
      until?: string;
    };

export const query = (args: RangeArgs) => {
  let from, until;

  if (typeof args === "object" && (!!args.from || !!args.until)) {
    from = parseValidMeasureUnitOrBreakpoint(args.from);
    until = parseValidMeasureUnitOrBreakpoint(args.until);
  } else {
    from = parseValidMeasureUnitOrBreakpoint(args as string);
  }

  // Prettier was breaking lines and adding ; to the end of the media
  // which would break them before we define brackets where this is called
  // so I added prettier-ignore here to make sure this doesn't happen...
  if (!!from && !!until) {
    // prettier-ignore
    return css`@media (min-width: ${from}) and (max-width: ${until})`;
  } else if (!!from) {
    // prettier-ignore
    return css`@media (min-width: ${from})`;
  } else if (!!until) {
    // prettier-ignore
    return css`@media (max-width: ${until})`;
  }

  // Else adds nothing to the styled template string
  return "";
};

type CustomMedia = {
  query: typeof query;
  custom: CustomMediaQuery;
};

const media: Media & CustomMedia = {
  query,
  custom: customMediaQuery,
  ...mediaFromBreakpoints(),
};

export default media;
