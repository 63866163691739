import React from "react";

import * as Styled from "./styles";

export type NotificationType = "success" | "error" | "info";

export type Props = {
  message: string;
  // TODO create implementation for info and success types
  type: NotificationType;
  duration?: number;
  id?: string;
  onClose?: () => void;
};

const Notification = ({ message, onClose }: Props) => {
  return (
    <Styled.Notification onClick={onClose}>
      <Styled.Exclamation /> {message} <Styled.Close />
    </Styled.Notification>
  );
};

export default Notification;
