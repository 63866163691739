import { AxiosInstance } from "axios";
import createBaseRepository from "repository/base";

import { DeskpassResponse } from "repository/deskpass/types/meta";
import { Country } from "repository/deskpass/types/data";

export type CountryError = {};

const path = "/api/countries";

const createCountryRepository = (client: AxiosInstance) => {
  const { findAll } = createBaseRepository<
    DeskpassResponse<Country, CountryError>
  >({ client, path });

  return {
    findAll,
  };
};

export default createCountryRepository;
