import React from "react";
import { useTheme } from "styled-components/macro";

import { Customization } from "repository/deskpass/types/data";

import useEvent from "hooks/useEvent";

type CustomizationContextState = {
  updateCustomization: Function;
  customization: Customization;
};

const Context = React.createContext({} as CustomizationContextState);

export const CustomizationProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { colors } = useTheme();

  const [customization, setCustomization] = React.useState<Customization>(
    () => ({
      companyLogo: undefined,
      simplifiedLogo: undefined,
      primaryColor: colors.primary,
      secondaryColor: colors.accent,
    })
  );

  const updateCustomization = useEvent((customization: Customization) => {
    setCustomization((prevCustomizationState) => ({
      primaryColor: customization.primaryColor ?? colors.primary,
      secondaryColor: customization.secondaryColor ?? colors.accent,
      ...prevCustomizationState,
      ...customization,
    }));
  });

  const context = React.useMemo(
    () => ({
      customization,
      updateCustomization,
    }),
    [customization, updateCustomization]
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default Context;
