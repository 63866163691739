import styled from "styled-components/macro";
import { TransitionGroup } from "react-transition-group";

export const NotificationTransitionGroup = styled(TransitionGroup)`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  margin: 4rem 0;
  z-index: 12;
`;

type AnimationProps = {
  state: string;
  duration: number;
};

export const Animation = styled.div<AnimationProps>`
  transition: opacity ${({ duration }) => duration}ms ease-in-out;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
`;
