import { AxiosInstance } from "axios";

import { DeskpassResponse } from "repository/deskpass/types/meta";

const createHubspotRepository = (client: AxiosInstance) => {
  return {
    getContactToken: () => {
      return client.get<DeskpassResponse<string>>(
        "/api/hubspot/conversations/token"
      );
    },
  };
};

export default createHubspotRepository;
