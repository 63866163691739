import config from "lib/config";

declare global {
  interface Window {
    HubSpotConversations: Record<string, any>;
    hsConversationsSettings: Record<string, any>;
    hsConversationsOnReady: Array<any>;
  }
}

const hsConversations = window.HubSpotConversations;

const hubspot = {
  setSettings: setHsSettings,
  getSettings: () => window.hsConversationsSettings ?? {},
  loadScript,
  widget: {
    open: openWidget,
    reload: reloadWidget,
    status: () => hsConversations.status(),
  },
};

function loadScript() {
  if (!config.HUBSPOT_APP_ID) return;

  // Build script element for main Hubspot script
  let script = document.createElement("script");

  script.src = `https://js.hs-scripts.com/${config.HUBSPOT_APP_ID}.js`;
  script.type = "text/javascript";
  script.async = true;
  script.defer = true;

  const promise = new Promise<void>((resolve) => {
    // Wait for basic script to load in before doing anything else
    script.onload = () => {
      // Put chat client-related functions into object we can export out of
      // here for use elsewhere
      if (!!window.HubSpotConversations) {
        resolve();
      } else {
        window.hsConversationsOnReady = [resolve];
      }
    };
  });

  document.head.appendChild(script);

  return promise;
}

type HsSettings = {
  loadImmediately: boolean;
  identificationToken?: string;
  identificationEmail?: string;
};

function setHsSettings({
  loadImmediately,
  identificationToken,
  identificationEmail,
}: HsSettings) {
  let settings = {};
  if (!!identificationToken) {
    settings = {
      identificationEmail,
      identificationToken,
    };
  }

  window.hsConversationsSettings = {
    loadImmediately,
    ...settings,
  };
}

function reloadWidget() {
  if (!!hsConversations && hsConversations.widget.status().loaded) {
    hsConversations.clear({ resetWidget: true });
  }
}

function openWidget() {
  return new Promise<void>((resolve) => {
    if (hsConversations.widget.status().loaded) {
      hsConversations.widget.open();
      return resolve();
    }

    // WidgetOpen here is supposed to load the widget with the message window
    // already open, but it kind of... doesn't do that, see thread here in the
    // future, hopefully they'll actually fix it:
    //
    // https://community.hubspot.com/t5/APIs-Integrations/window-HubSpotConversations-widget-load-widgetOpen-true-doesn-t/td-p/288938
    hsConversations.widget.load({ widgetOpen: true });

    // Until they do though, we just have to use this hacky bullshit instead
    // because they also don't provide a loaded callback or loaded event
    let interval = setInterval(() => {
      if (hsConversations.widget.status().loaded) {
        clearInterval(interval);

        hsConversations.widget.open();
        resolve();
      }
    }, 200);
  });
}

export default hubspot;
