import React from "react";
import { Switch, Redirect, Route, RouteProps } from "react-router-dom";

import routes, { rulesByRoute, RouteKey } from "lib/routes";

import SessionContext from "context/session";
import CountryContext from "context/country";

import { LoadingSpinner } from "components/ProgressIndicator";

import * as pages from "pages";

type AsyncRouteProps = RouteProps & {
  loading: boolean;
  name: RouteKey;
};

const SessionAwareRoute = ({
  loading,
  name,
  ...routeProps
}: AsyncRouteProps) => {
  const { authenticated } = React.useContext(SessionContext);
  const rules = rulesByRoute[name];
  const accessible = rules.isAccessible(authenticated);

  if (!accessible) {
    return <Redirect to={rules.fallback} />;
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return <Route {...routeProps} />;
};

const MainContentRouter = () => {
  const { ready: countriesReady } = React.useContext(CountryContext);
  const { ready: sessionReady } = React.useContext(SessionContext);

  const setupLoading = !countriesReady || !sessionReady;
  const loading = !sessionReady;

  return (
    <Switch>
      <SessionAwareRoute
        exact
        path={routes.setup}
        component={pages.Setup}
        name="setup"
        loading={setupLoading}
      />

      <SessionAwareRoute
        exact
        name="reservation"
        path={routes.reservation}
        component={pages.Reservation}
        loading={loading}
      />

      <SessionAwareRoute
        exact
        name="customization"
        path={routes.customization}
        component={pages.Customization}
        loading={loading}
      />

      <SessionAwareRoute
        exact
        name="team"
        path={routes.team}
        component={pages.Team}
        loading={loading}
      />

      <SessionAwareRoute
        exact
        name="billing"
        path={routes.billing}
        component={pages.Billing}
        loading={loading}
      />

      <Redirect path="*" to={routes.setup} />
    </Switch>
  );
};

export default MainContentRouter;
