import React from "react";
import { useTheme } from "styled-components/macro";

type Ref = React.ForwardedRef<SVGSVGElement> | null;

type DefaultProps = {
  width?: number | string;
  height?: number | string;
};

type Props = React.SVGProps<SVGSVGElement> & DefaultProps;
type DollarSignSVGProps = Props & {
  svgRef?: Ref;
  color?: string;
};

const defaultProps: DefaultProps = {
  width: "2.2rem",
  height: "2.2rem",
};

// style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"

function DollarSignSVG({
  width,
  color,
  height,
  svgRef,
  ...props
}: DollarSignSVGProps) {
  const { colors } = useTheme();

  return (
    <svg
      fill={color ?? colors.white}
      width={width}
      height={height}
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
      ref={svgRef}
      {...props}
    >
      <path
        d="M38.467 102.229c4.383 2.108 9.043 3.218 13.758 3.939 3.717.61 3.828.777 3.884 4.605 0 1.775.055 3.494.055 5.27 0 2.219 1.11 3.495 3.329 3.55 2.607.056 5.159.056 7.766 0 2.108-.055 3.218-1.22 3.218-3.328 0-2.386.111-4.771 0-7.212s.943-3.662 3.273-4.327c5.381-1.498 9.985-4.383 13.536-8.654 9.819-11.928 6.046-29.402-7.878-37.113-4.382-2.441-8.987-4.272-13.591-6.158-2.663-1.11-5.215-2.385-7.434-4.216-4.438-3.551-3.606-9.265 1.609-11.539 1.442-.61 2.995-.832 4.604-.943 5.992-.333 11.706.777 17.198 3.384 2.718 1.331 3.605.887 4.549-1.942.943-2.995 1.775-5.991 2.662-9.042.611-1.997-.111-3.384-2.052-4.216-3.495-1.554-7.101-2.663-10.818-3.218-4.937-.777-4.937-.777-4.937-5.714-.056-6.99-.056-6.99-6.99-6.99h-3.051c-3.273.111-3.828.666-3.883 3.939-.056 1.498 0 2.94 0 4.438 0 4.383-.056 4.327-4.217 5.825-10.096 3.661-16.365 10.54-17.03 21.58-.611 9.763 4.493 16.365 12.481 21.136 4.938 2.94 10.43 4.715 15.644 6.99 2.053.887 3.995 1.941 5.714 3.328 5.049 4.161 4.106 11.095-1.886 13.703-3.217 1.387-6.546 1.719-10.041 1.331-5.325-.666-10.485-2.053-15.311-4.604-2.829-1.498-3.661-1.11-4.604 1.997-.832 2.663-1.554 5.325-2.275 7.988-1.054 3.662-.721 4.549 2.718 6.213Z"
        style={{ fillRule: "nonzero" }}
      />
    </svg>
  );
}

DollarSignSVG.defaultProps = defaultProps;

const ForwardRef = React.forwardRef((props: Props, ref: Ref) => (
  <DollarSignSVG {...props} svgRef={ref} />
));

export default ForwardRef;
