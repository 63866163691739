import React from "react";

import Check from "components/Svg/Check";
import Close from "components/Svg/Close";
import { LoadingSpinner } from "components/ProgressIndicator";

import * as Styled from "./styles";

export type FieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  showValidIcon?: boolean;
  valid?: boolean;
  processing?: boolean;
  errorMessage?: string;
  prefix?: React.ReactNode;
  sufix?: React.ReactNode;
};

type Ref = React.ForwardedRef<HTMLInputElement> | null;

const Field = React.forwardRef(
  (
    {
      showValidIcon,
      processing,
      valid,
      errorMessage,
      prefix,
      sufix,
      className,
      ...props
    }: FieldProps,
    ref: Ref
  ) => {
    const showIconRight =
      (!processing && showValidIcon) || processing || !!sufix;
    const showIconLeft = !!prefix;

    const [leftIconSpacing, setLeftIconSpacing] = React.useState<number>(
      () => 0
    );
    const [rightIconSpacing, setRightIconSpacing] = React.useState<number>(
      () => 0
    );

    const iconSpacingCallbackRef = React.useCallback(
      (domEl: HTMLDivElement, setState: Function) => {
        if (!domEl) return;

        setState(domEl.offsetWidth);
      },
      []
    );

    const leftIconSpacingCallbackRef = React.useCallback(
      (domEl: HTMLDivElement) =>
        iconSpacingCallbackRef(domEl, setLeftIconSpacing),
      [iconSpacingCallbackRef]
    );

    const rightIconSpacingCallbackRef = React.useCallback(
      (domEl: HTMLDivElement) =>
        iconSpacingCallbackRef(domEl, setRightIconSpacing),
      [iconSpacingCallbackRef]
    );

    return (
      <Styled.Field className={className}>
        <Styled.FieldPlaceholder>
          {showIconLeft && (
            <Styled.LeftContentPlaceholder ref={leftIconSpacingCallbackRef}>
              {prefix}
            </Styled.LeftContentPlaceholder>
          )}

          <Styled.Input
            {...props}
            ref={ref}
            valid={!!valid}
            leftIconSpacing={leftIconSpacing}
            rightIconSpacing={rightIconSpacing}
          />

          {showIconRight && (
            <Styled.RightContentPlaceholder ref={rightIconSpacingCallbackRef}>
              {!!sufix && <Styled.Suffix>{sufix}</Styled.Suffix>}

              {!processing && showValidIcon && (
                <Styled.ValidIcon valid={!!valid}>
                  {valid ? <Check /> : <Close />}
                </Styled.ValidIcon>
              )}

              {processing && (
                <Styled.SpinnerPlaceholder>
                  <LoadingSpinner inline small />
                </Styled.SpinnerPlaceholder>
              )}
            </Styled.RightContentPlaceholder>
          )}
        </Styled.FieldPlaceholder>

        {!valid && <Styled.ErrorMessage>{errorMessage}</Styled.ErrorMessage>}
      </Styled.Field>
    );
  }
);

Field.defaultProps = {
  showValidIcon: false,
  processing: false,
  valid: true,
  type: "text",
};

export default Field;
