import styled, { css, keyframes } from "styled-components/macro";

import { rgba } from "global-styles/lib/colors";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

const visibility = css<{ visible?: boolean }>`
  display: ${({ visible }) => (visible ? "visible" : "none")};
`;

export const CardNumber = styled(CardNumberElement)`
  ${visibility}
`;
export const CardExpiry = styled(CardExpiryElement)`
  ${visibility}
`;
export const CardCvc = styled(CardCvcElement)`
  ${visibility}
`;

export const Field = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

type FiledPlaceholderProps = {
  focused: boolean;
  hasError: boolean;
};

const shine = keyframes`
  to {
    background-position: 100% 0;
  }
`;

export const SkelletonLoader = styled.span`
  width: 100%;
  height: 15px;
  display: block;
  border-radius: 8px;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    ${({ theme }) => rgba(theme.colors.disabled, 0.2)};
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: ${shine} 1s infinite;
`;

export const StripeFieldWrapper = styled.div<FiledPlaceholderProps>`
  height: 5rem;
  width: 100%;
  padding: 1.2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: 0;
  border-radius: 25px;
  border: 2px solid
    ${({ theme: { colors }, hasError }) =>
      hasError ? rgba(colors.red, 0.5) : colors.fieldBorders};

  ${({ focused, hasError, theme: { colors } }) =>
    focused &&
    css`
      box-shadow: 0 0 0 0.2rem
        ${hasError ? rgba(colors.red, 0.1) : rgba(colors.fieldBorders, 0.3)};
    `}
`;

export const ErrorMessage = styled.span`
  margin-top: 1.5rem;
  margin-left: 1.5rem;
  color: ${({ theme }) => theme.colors.red};
`;
