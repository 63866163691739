import styled, { css } from "styled-components/macro";

import { rgba } from "global-styles/lib/colors";
import { fieldBorders } from "global-styles/lib/borders";

const borderColor = css`
  ${({ theme }) => theme.colors.fieldBorders}
`;

export const Field = styled.div<{ disabled: boolean }>`
  width: 100%;
  position: relative;
  height: fit-content;

  ${({ disabled }) =>
    !!disabled &&
    css`
      cursor: pointer;
      pointer-events: none;
    `}
`;

type SelectProps = {
  open: boolean;
};

export const Select = styled.div<SelectProps>`
  display: flex;
  align-items: center;
  outline: 0;
  appearance: none;
  width: 100%;
  padding: 1.2rem 1.5rem;
  // takes up the arrow space so that
  // inner content does not overlap
  padding-right: 4.5rem;

  // borders
  border-radius: 25px;
  border: 2px solid ${borderColor};
  ${({ open }) =>
    open &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-right-radius: 25px;
      border-top-left-radius: 25px;
    `}

  ${fieldBorders.focused}
`;

export const SelectPlaceholder = styled.div`
  position: relative;
  z-index: 11;
  outline: 0;
`;

export const SelectArrow = styled.div`
  position: absolute;
  top: 0;
  right: 2rem;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Panel = styled.div`
  position: absolute;
  top: 100%;
  z-index: 10;
  width: 100%;
  background: white;
  padding: 0.4rem 0;
  border-radius: 1rem;
  border: 2px solid ${borderColor};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 0;
  box-shadow: 0 2px 5px ${({ theme }) => rgba(theme.colors.text, 0.12)};
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;

  &:focus-within,
  &:hover {
    outline: 0;
    background: ${borderColor};
  }
`;
