import React from "react";
import { useTheme } from "styled-components/macro";

type Ref = React.ForwardedRef<SVGSVGElement> | null;

type DefaultProps = {
  width?: number | string;
  height?: number | string;
};

type Props = React.SVGProps<SVGSVGElement> & DefaultProps;
type CheckboxSelectedSVGProps = Props & {
  svgRef?: Ref;
  color?: string;
};

const defaultProps: DefaultProps = {
  width: "2rem",
  height: "2rem",
};

function CheckboxSelectedSVG({
  width,
  height,
  color,
  svgRef,
  ...props
}: CheckboxSelectedSVGProps) {
  const { colors } = useTheme();

  return (
    <svg
      width={width}
      height={height}
      viewBox="2 2 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <rect width="20" height="20" rx="3" fill={color ?? colors.primary} />
      <path
        d="M9 0L3 6L0 3"
        transform="translate(6 7)"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
}

CheckboxSelectedSVG.defaultProps = defaultProps;

const ForwardRef = React.forwardRef((props: Props, ref: Ref) => (
  <CheckboxSelectedSVG {...props} svgRef={ref} />
));

export default ForwardRef;
