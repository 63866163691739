import React from "react";
import { useTheme } from "styled-components/macro";

import { rgba } from "global-styles/lib/colors";

type DefaultProps = {
  width?: number | string;
  height?: number | string;
};

type Ref = React.ForwardedRef<SVGSVGElement> | null;

type Props = React.SVGProps<SVGSVGElement> & DefaultProps;
type ArrowSVGProps = Props & {
  svgRef?: Ref;
};

const defaultProps: DefaultProps = {
  width: "1.8rem",
  height: "1.8rem",
};

function ArrowSvg({ width, height, color, svgRef, ...props }: ArrowSVGProps) {
  const { colors } = useTheme();

  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 -5 20 20"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="m.353.797 8 8 8-8" stroke={color ?? rgba(colors.text, 0.7)} />
    </svg>
  );
}

ArrowSvg.defaultProps = defaultProps;

const ForwardRef = React.forwardRef((props: Props, ref: Ref) => (
  <ArrowSvg {...props} svgRef={ref} />
));

export default ForwardRef;
