import { createGlobalStyle } from "styled-components/macro";
import normalizeCSS from "styled-normalize";
import resetCSS from "styled-reset";

import { fontStack } from "./Fonts";
import { colors } from "./Theme";

const PageStyles = createGlobalStyle`
  ${resetCSS}
  ${normalizeCSS}

  html,
  body,
  #root {
    background-color: ${colors.white};
    min-height: 100%;
    height: 100%;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
    color: ${colors.text};
    ${fontStack.grotesque}
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  // Remove gray tap highlight color on mobile
  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    font-size: 1.5rem;
  }
`;

export default PageStyles;
