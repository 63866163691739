import axios, { AxiosRequestConfig } from "axios";

import logger from "lib/log";

type ClientSettints = AxiosRequestConfig & {};

const defaultSettings: AxiosRequestConfig = {
  timeout: 20000,
};

const createClient = (overrideSettings: ClientSettints = {}) => {
  const axiosConfig = {
    ...defaultSettings,
    ...overrideSettings,
  };

  const client = axios.create(axiosConfig);

  client.interceptors.response.use(
    (response) => {
      logger.info(
        "Successfully completed request to: ",
        response.request.responseURL
      );
      return response;
    },
    (error) => {
      logger.warn(
        "Network request has returned the following error: ",
        error.message
      );
      return Promise.reject(error);
    }
  );

  return client;
};

export default createClient;
