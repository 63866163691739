import styled, { css } from "styled-components/macro";

const listOverrides = css`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const Carousel = styled.div`
  position: relative;
  perspective: 100px;
`;

export const SlideList = styled.ol`
  ${listOverrides}

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow-x: scroll;
  counter-reset: item;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scrollbar-color: transparent transparent; /* thumb and track color */
`;

export const Slide = styled.li`
  ${listOverrides}

  position: relative;
  flex: 0 0 100%;
  width: 100%;
  counter-increment: item;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: center;
`;

type SnapperProps = {
  transitionDuration: number;
};

export const Snapper = styled.div<SnapperProps>`
  scroll-snap-align: center;
  width: 100%;
  height: 100%;

  transition: ${(props) => `${props.transitionDuration}ms`};
  position: absolute;
  top: 0;
`;

export const Navigation = styled.aside`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
`;

export const NavigationList = styled.ol`
  display: inline-block;
`;

export const NavigationItem = styled.li`
  display: inline-block;
`;

type NavigationButtonProps = {
  active: boolean;
};

export const NavigationButton = styled.a<NavigationButtonProps>`
  cursor: pointer;
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  margin: 2rem 0.4rem;
  background-clip: content-box;
  border: 0.25rem solid transparent;
  border-radius: 50%;
  font-size: 0;
  transition: transform 0.1s;
  background-color: ${(props) => (props.active ? "#333" : "#b3b4cb")};

  ${({ theme }) => theme.media.medium} {
    margin: 1rem 0.4rem;
    width: 1.2rem;
    height: 1.2rem;
  }
`;
