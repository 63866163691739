import styled, { css } from "styled-components/macro";

import { spin } from "global-styles/lib/keyframes";

export type StyledSpinnerProps = {
  inline: boolean;
  small: boolean;
};

export const LoadingSpinner = styled.div<StyledSpinnerProps>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ inline }) =>
    !inline &&
    css`
      min-height: 50vh;
    `}

  &:before {
    content: "";
    position: absolute;
    margin: auto;
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    border: 3px solid ${({ theme }) => theme.colors.rule};
    border-right-color: ${({ theme }) => theme.colors.primary};

    will-change: transform;
    animation-name: ${spin};
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    ${({ small }) =>
      small &&
      css`
        width: 2rem;
        height: 2rem;
        border-width: 2px;
      `}
  }
`;
