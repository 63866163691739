import React from "react";

type CountryFlagProps = {
  country: string;
};

const CountryFlag = ({ country, ...props }: CountryFlagProps) => {
  if (typeof country !== "string") {
    return null;
  }

  return (
    <img
      {...props}
      src={`${process.env.PUBLIC_URL}/flags/${country}.svg`}
      alt={`${country} flag`}
    />
  );
};

CountryFlag.defaultProps = {
  width: "auto",
  height: "20px",
};

export default CountryFlag;
