const defaultOptions = {
  ratio: 100,
  negative: false,
};

type CssUnit = string | number;
type ScalingOptions = {
  negative?: boolean;
  ratio?: number;
};
type ScalingMode = "vw" | "vh";

const scale = (
  width: CssUnit,
  options: ScalingOptions = {},
  mode: ScalingMode
) => {
  const _options = {
    ...defaultOptions,
    ...options,
  };

  let formula = `${width} + 1 * (100${mode} / ${_options.ratio})`;

  if (_options.negative) {
    formula = `-1 * (${formula})`;
  }

  return `calc(${formula})`;
};

export const scaleWithVW = (width: CssUnit, options: ScalingOptions = {}) => {
  return scale(width, options, "vw");
};

export const scaleWithVH = (width: CssUnit, options: ScalingOptions = {}) => {
  return scale(width, options, "vh");
};
