import React from "react";

type DomElement<E extends HTMLElement = HTMLElement> = E | Window | Document;

/**
 * useEventListener
 *
 * @param {string} eventName - The name of event to listen
 * @param {function} handler - The callback function that injects the event itself for event manipulation
 * @param {object} elementOrRef - The Dom elementOrRef where the event will be added. Defaults to window
 */
function useEventListener<T extends HTMLElement = HTMLElement>(
  eventName: keyof WindowEventMap | string, // string to allow custom event
  handler: (event: Event) => void,
  elementOrRef: DomElement<T> | React.RefObject<DomElement<T>> = window
) {
  // Create a ref that stores handler
  const savedHandler = React.useRef<(event: Event) => void>();

  let targetElement: DomElement<T> = elementOrRef as DomElement<T>;

  if ((elementOrRef as React.RefObject<DomElement<T>>)?.current) {
    targetElement = (elementOrRef as React.RefObject<DomElement<T>>)
      ?.current as DomElement<T>;
  }

  React.useEffect(() => {
    if (
      !(targetElement && typeof targetElement.addEventListener === "function")
    ) {
      return;
    }

    // Update saved handler if necessary
    if (savedHandler.current !== handler) {
      savedHandler.current = handler;
    }

    // Create event listener that calls handler function stored in ref
    const eventListener = (event: Event) => {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!savedHandler?.current) {
        savedHandler.current(event);
      }
    };

    targetElement.addEventListener(eventName, eventListener);

    // Remove event listener on cleanup
    return () => {
      targetElement.removeEventListener(eventName, eventListener);
    };
  }, [eventName, targetElement, handler]);
}

export default useEventListener;
