import React from "react";
import styled from "styled-components/macro";

import { rgba } from "global-styles/lib/colors";

import mailTo from "lib/mailTo";

type StyleProps = {
  primary?: boolean;
};

const StyledLink = styled.a<StyleProps>`
  white-space: nowrap;
  color: ${({ theme, primary }) =>
    primary ? theme.colors.primary : rgba(theme.colors.text, 0.6)};
`;

type Props = React.PropsWithChildren<
  {
    body?: string;
    subject?: string;
    cc?: string;
    bcc?: string;
    teamName: string;
    name: string;
  } & StyleProps
>;

const SalesLink = ({
  children,
  subject,
  cc,
  bcc,
  name,
  teamName,
  primary,
}: Props) => {
  let linkProps: Record<string, any> = { primary };

  linkProps.href = mailTo("teams@deskpass.com", {
    subject,
    cc,
    bcc,
    body: createDefaultEmailBody(name, teamName).trim?.(),
  });

  return <StyledLink {...linkProps}>{children}</StyledLink>;
};

const createDefaultEmailBody = (name: string, team: string) => `
Hi Deskpass,

I'm interested in learning more about additional pricing options for my team ${team}.

Best,

${name}
`;

SalesLink.defaultProps = {
  children: "contact us here",
  primary: false,
  subject: "I'm interested in additional Deskpass payment options",
};

export default SalesLink;
