import { css } from "styled-components/macro";

import { rgba } from "global-styles/lib/colors";

export const fieldBorders = {
  focused: css`
    &:focus {
      box-shadow: 0 0 0 0.2rem
        ${({ theme }) => rgba(theme.colors.fieldBorders, 0.3)};
    }
  `,
};
