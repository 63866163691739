declare global {
  interface Window {
    SERVER_CONFIG: Record<string, any>;
  }
}

// Make sure window.SERVER_CONFIG is always initialized
// in case the local server is not doing so.
window.SERVER_CONFIG = window.SERVER_CONFIG ?? {};

function env(name: string, fallback: any = null) {
  return (
    process.env[`REACT_APP_${name}`] ||
    window.SERVER_CONFIG[name] ||
    extraConfig[name] ||
    fallback
  );
}

const ENVIRONMENT = env("ENVIRONMENT", "development");

const extraConfig: Record<string, any> = {};

if (ENVIRONMENT === "staging") {
  extraConfig.HUBSPOT_APP_ID = 21742877;
}

if (ENVIRONMENT === "production") {
  extraConfig.HUBSPOT_APP_ID = 20236705;
}

// Used for serving images from Cloudflare Images
extraConfig.CLOUDFLARE_IMAGES_INSTANCE_ID = "NTBbUGXbgnQKXhDzmZxa9Q";

// Front end config loaded from REACT_APP vars injected at build time
// or from variables injected in the page by the local server through
// window.SERVER_CONFIG.
const config = {
  ENVIRONMENT,
  NODE_ENV: env("NODE_ENV", "development"),
  API_URL: env("API_URL", "https://api-staging.deskpass.com"),
  LOCAL_API_URL: env("LOCAL_API_URL", `http://${window.location.host}`),
  STRIPE_KEY: env("STRIPE_KEY"),
  ACTIVE_CRM: env("ACTIVE_CRM"),
  HUBSPOT_APP_ID: env("HUBSPOT_APP_ID"),
  CLOUDFLARE_IMAGES_INSTANCE_ID: env("CLOUDFLARE_IMAGES_INSTANCE_ID"),
  OPENREPLAY_PROJECT_KEY: env("OPENREPLAY_PROJECT_KEY"),
};

export default config;
