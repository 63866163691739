import styled, { css } from "styled-components/macro";

type StyledVariantProps = {
  compact?: boolean;
  padded?: string;
};

const compactStyles = css`
  ${({ theme }) => theme.media.query({ from: "large" })} {
    max-width: 80%;
  }
`;

const styleVariants = css<StyledVariantProps>`
  && {
    ${({ compact }) => compact && compactStyles}

    ${({ padded }) =>
      !!padded &&
      css`
        margin-top: ${padded};
      `}
  }
`;

const verticalSpacing = css`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  ${({ theme }) => theme.media.medium} {
    gap: 1.5rem;
  }
`;

export const Container = styled.div`
  ${styleVariants}

  ${({ theme }) => theme.media.large} {
    padding: 2rem 1rem;
  }

  ${({ theme }) => theme.media.medium} {
    padding: 1rem 0;
  }
`;

export const HeaderSection = styled.header`
  ${styleVariants}
`;

export const Header = styled.h1`
  ${({ theme }) => theme.fontStack.grotesqueMedium};

  font-size: 2.8em;
  margin: 1.5rem 0;

  ${({ theme }) => theme.media.medium} {
    font-size: 2em;
    margin: 1.3rem 0;
  }
`;

export const Subheader = styled.h2`
  ${({ theme }) => theme.fontStack.simula};

  font-size: 1.5em;
  line-height: 1.5;

  ${({ theme }) => theme.media.medium} {
    font-size: 1.3em;
    line-height: 1.3;
  }
`;

export const FooterSection = styled.footer`
  ${styleVariants}
  ${verticalSpacing}

  align-items: center;

  ${({ theme }) => theme.media.query({ from: "medium" })} {
    max-width: 60%;
  }

  ${({ theme }) => theme.media.query({ from: "large" })} {
    max-width: 80%;
  }
`;

export const Form = styled.form<StyledVariantProps>`
  margin: 3rem 0;

  ${verticalSpacing}
  ${styleVariants}
`;
