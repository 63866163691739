import React from "react";

/*
 * TODO remove this when it becomes offial
 *
 * This will be implemented in react 18 but as I use the concept a lot
 * I'm getting ahead and adding it here.
 */
function useEvent<F extends (...args: Parameters<F>) => ReturnType<F>>(
  handler: F
) {
  const handlerRef = React.useRef<F | null>(null);

  // In a real implementation, this would run before layout effects
  React.useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  return React.useCallback((...args: Parameters<F>): ReturnType<F> => {
    // In a real implementation, this would throw if called during render
    const fn = handlerRef.current;

    if (!fn) {
      throw new Error(
        '"useEvent" should not be invoked inside the render context.\n' +
          "Call it inside effects, event handlers or other async contexts instead."
      );
    }

    return fn(...args);
  }, []);
}

export default useEvent;
