import styled, { css } from "styled-components/macro";

import { fieldBorders } from "global-styles/lib/borders";

const borderColor = css`
  ${({ theme }) => theme.colors.fieldBorders}
`;
const primaryColor = css`
  ${({ theme }) => theme.colors.primary}
`;

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  height: 1.8rem;
  width: 1.8rem;
  opacity: 0;
  cursor: pointer;
`;

type BoxProps = {
  checked?: boolean | string | undefined;
};

export const Check = styled.div`
  position: absolute;
  pointer-events: none;
`;

export const Box = styled.div<BoxProps>`
  position: relative;
  border: 2px solid ${({ checked }) => (checked ? primaryColor : borderColor)};
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  background: ${({ checked }) => (checked ? primaryColor : "transparent")};
  outline: 0;

  ${fieldBorders.focused}
`;

export const Label = styled.label`
  padding-left: 1.2rem;
  display: block;
  cursor: pointer;
  line-height: 1.2;
`;
