import React from "react";
import styled from "styled-components/macro";
import config from "lib/config";

type ImageProps = {
  imageName?: string;
  src?: string;
  alt: string;
  width?: number;
  height?: number;
};

const cloudFlareURL = (imageName: string, width?: number, height?: number) =>
  `https://imagedelivery.net/${
    config.CLOUDFLARE_IMAGES_INSTANCE_ID
  }/${imageName}/${!width && !height ? "default" : ""}${
    width ? `width=${width}` : ""
  }${width && height ? "," : ""}${height ? `height=${height}` : ""}`;

const Img = styled.img`
  width: auto;
  height: 100%;
`;

const Image = ({
  src,
  imageName,
  alt,
  width,
  height,
  ...props
}: ImageProps) => {
  const imgSrc = imageName ? cloudFlareURL(imageName, width, height) : src;

  return <Img {...props} alt={alt} src={imgSrc} />;
};

export default Image;
