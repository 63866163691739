import React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";

import { Transition } from "react-transition-group";

import Field from "components/Fields/Field";
import Button from "components/Button";
import TextButton from "components/Button/Text";
import SkipButton from "components/Button/Skip";

import useDeskpassRepository from "hooks/useRepository/deskpass";
import useEvent from "hooks/useEvent";

import SessionContext from "context/session";
import NotificationContext from "context/notification";

import * as patterns from "lib/patterns";
import routes from "lib/routes";
import logger from "lib/log";

import { NewMember } from "repository/deskpass/types/data";

import * as StyledPage from "pages/styles";
import * as Styled from "./styles";

type TeamInviteListFields = {
  inviteList: Array<NewMember>;
};

const emptyMemberEntry = {
  firstName: "",
  lastName: "",
  email: "",
};

const MAX_INVITES = 25;

const TeamPage = () => {
  const { push: pushRoute } = useHistory();

  const { displayError } = React.useContext(NotificationContext);
  const { currentTeam } = React.useContext(SessionContext);

  const [{ loading: processing }, inviteTeamMembers] = useDeskpassRepository(
    (repository) => (inviteList) =>
      repository.team.invite(currentTeam!.id, inviteList),
    { fireOnMount: false }
  );

  const { control, formState, handleSubmit, register, getFieldState } =
    useForm<TeamInviteListFields>({
      mode: "onTouched",
      shouldUnregister: true,
      defaultValues: {
        inviteList: [emptyMemberEntry],
      },
    });

  const {
    fields: memberInviteListFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "inviteList",
  });

  const entriesCount = memberInviteListFields.length;
  const disableAdd = entriesCount > MAX_INVITES;

  const addMember = useEvent((shouldFocus: boolean = false) => {
    if (!disableAdd) {
      append(emptyMemberEntry, { shouldFocus });
    }
  });

  const onAddMemberClick = useEvent(() => addMember());
  const removeMember = useEvent((index: number) => remove(index));

  const onSubmit = useEvent(async ({ inviteList }: TeamInviteListFields) => {
    try {
      await inviteTeamMembers(inviteList);

      pushRoute(routes.billing);
    } catch (err) {
      displayError(err);
      logger.error("Error submitting Team page: ", err);
    }
  });

  const { isValid } = formState;

  const submitDisabled = !isValid || !entriesCount || processing;

  return (
    <StyledPage.Container>
      <Helmet>
        <title>Deskpass - Teams Onboarding | Team Member Invites</title>
        <meta name="description" content="Invite team members to your team" />
      </Helmet>

      <StyledPage.HeaderSection>
        <StyledPage.Header>Invite your teammates</StyledPage.Header>

        <StyledPage.Subheader>
          Enter team members info below.
        </StyledPage.Subheader>
      </StyledPage.HeaderSection>

      <Styled.Note>
        <strong>Note for larger teams:</strong> you will be able to upload a
        .CSV file for the rest inside your dash later on.
      </Styled.Note>

      <StyledPage.Form onSubmit={handleSubmit(onSubmit)}>
        <Styled.InviteMemberTransitionGroup>
          {memberInviteListFields.map(({ id }, index) => {
            const firNameFieldKey = `inviteList.${index}.firstName` as const;
            const firstNameState = getFieldState(firNameFieldKey, formState);
            const firstNameFieldProps = register(firNameFieldKey, {
              required: `First name is required`,
            });

            const lastNameFieldKey = `inviteList.${index}.lastName` as const;
            const lastNameState = getFieldState(lastNameFieldKey, formState);
            const lastNameFieldProps = register(lastNameFieldKey, {
              required: `Last name is required`,
            });

            const emailFieldKey = `inviteList.${index}.email` as const;
            const emailState = getFieldState(emailFieldKey, formState);
            const emailFieldProps = register(emailFieldKey, {
              required: `Email is required`,
              pattern: {
                value: patterns.email,
                message: "Invalid email",
              },
            });

            // Temporary fix for react-transition-group warning about findDomNode usage:
            // https://github.com/reactjs/react-transition-group/issues/668#issuecomment-695162879
            const ref = React.createRef<HTMLDivElement>();

            return (
              <Transition nodeRef={ref} key={id} timeout={300}>
                {(state) => (
                  <Styled.FadeContainer state={state}>
                    <Styled.InviteFieldGroupContainer
                      ref={(domEl) => {
                        if (index !== 0 && !!domEl) {
                          domEl.scrollIntoView?.({
                            behavior: "smooth",
                          });
                        }
                      }}
                    >
                      <Styled.InviteHeader>
                        Invite Employee{index === 0 ? "" : ` ${index}`}
                      </Styled.InviteHeader>

                      <Styled.InviteFieldGroup>
                        <Field
                          {...firstNameFieldProps}
                          placeholder="First name"
                          errorMessage={firstNameState?.error?.message}
                          showValidIcon={firstNameState?.isTouched}
                          valid={!firstNameState.invalid}
                        />

                        <Field
                          {...lastNameFieldProps}
                          placeholder="Last name"
                          errorMessage={lastNameState?.error?.message}
                          showValidIcon={lastNameState?.isTouched}
                          valid={!lastNameState.invalid}
                        />

                        <Field
                          {...emailFieldProps}
                          placeholder="Email"
                          errorMessage={emailState?.error?.message}
                          showValidIcon={emailState?.isTouched}
                          valid={!emailState.invalid}
                        />

                        <Styled.CloseButton
                          onClick={() => removeMember(index)}
                        />
                      </Styled.InviteFieldGroup>
                    </Styled.InviteFieldGroupContainer>
                  </Styled.FadeContainer>
                )}
              </Transition>
            );
          })}
        </Styled.InviteMemberTransitionGroup>

        <Styled.AddSection>
          <TextButton disabled={disableAdd} onClick={onAddMemberClick}>
            Add another employee...
          </TextButton>

          {disableAdd && (
            <Styled.MaxInvites>
              Maximum of {MAX_INVITES} members at a time.
            </Styled.MaxInvites>
          )}
        </Styled.AddSection>

        <StyledPage.FooterSection padded="6rem">
          <Button
            processing={processing}
            disabled={submitDisabled}
            type="submit"
          >
            Invite team members
          </Button>

          <SkipButton to={routes.billing} />
        </StyledPage.FooterSection>
      </StyledPage.Form>
    </StyledPage.Container>
  );
};

export default TeamPage;
