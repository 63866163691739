import React from "react";
import styled from "styled-components/macro";
import { useMediaQuery } from "react-responsive";

import Img from "components/Image";

import { breakpoints } from "global-styles/lib/media";

const AccentImagePlaceholder = styled.div<{ background: string }>`
  display: flex;
  width: 100%;
  height: 100%;
  background: ${({ background }) => background};
`;

const BillingAccentImage = styled(Img)`
  object-fit: cover;
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.media.large} {
    object-fit: contain;
    transform: scaleY(3) scaleX(4.8) translateY(1%);
  }

  ${({ theme }) => theme.media.smallMedium} {
    object-fit: contain;
    transform: scale(2.9) translateY(1%);
  }
`;

export const Billing = () => {
  return (
    <AccentImagePlaceholder background="#28AFB0">
      <BillingAccentImage
        alt="Deskpass Space Gallery"
        imageName="space-gallery"
        width={1500}
      />
    </AccentImagePlaceholder>
  );
};

const ReservationAccentImage = styled(Img)`
  object-fit: cover;
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.media.large} {
    object-fit: contain;
    transform: scale(1.6);
  }

  ${({ theme }) => theme.media.smallMedium} {
    transform: scale(1.4);
  }
`;

export const Reservation = () => {
  const isTablet = useMediaQuery({ maxWidth: breakpoints.large });
  const isMobile = useMediaQuery({ maxWidth: breakpoints.smallMedium });

  let assetName = "coworking";

  if (isTablet) assetName = "coworking-tablet";
  if (isMobile) assetName = "coworking-mobile";

  return (
    <AccentImagePlaceholder background="#F05F5A">
      <ReservationAccentImage
        alt="Deskpass Space Gallery"
        imageName={assetName}
        width={1500}
      />
    </AccentImagePlaceholder>
  );
};
