import React from "react";
import { useTheme } from "styled-components/macro";

type Ref = React.ForwardedRef<SVGSVGElement> | null;

type DefaultProps = {
  width?: number | string;
  height?: number | string;
};

type Props = React.SVGProps<SVGSVGElement> & DefaultProps;
type CheckSVGProps = Props & {
  svgRef?: Ref;
  color?: string;
};

const defaultProps: DefaultProps = {
  width: "1.5rem",
  height: "1.5rem",
};

function CheckSVG({ width, height, color, svgRef, ...props }: CheckSVGProps) {
  const { colors } = useTheme();

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 -2 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.58 1.22L18.516.155 6.983 11.686l-5.23-5.23L.688 7.523l6.296 6.296L19.581 1.22z"
        fill={color ?? colors.green}
      />
    </svg>
  );
}

CheckSVG.defaultProps = defaultProps;

const ForwardRef = React.forwardRef((props: Props, ref: Ref) => (
  <CheckSVG {...props} svgRef={ref} />
));

export default ForwardRef;
