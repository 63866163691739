import styled from "styled-components/macro";

export const ColorPicker = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  position: relative;
`;

const buttonAttrs = () => ({ type: "button" });
export const Button = styled.button.attrs(buttonAttrs)`
  /* Removes default button styles */
  padding: 0;
  font: inherit;
  outline: inherit;

  cursor: pointer;
  height: 3.4rem;
  width: 3.4rem;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.fieldBorders};

  transition: transform;
  &:focus-within {
    transform: scale(1.1);
  }
`;

export const Label = styled.label`
  cursor: pointer;
`;

export const Popover = styled.div`
  position: absolute;
  z-index: 10;
  top: 100%;
`;
