import React from "react";
import { useTheme } from "styled-components/macro";

type Ref = React.ForwardedRef<SVGSVGElement> | null;

type DefaultProps = {
  width?: number | string;
  height?: number | string;
};

type Props = React.SVGProps<SVGSVGElement> & DefaultProps;
type CloseSVGProps = Props & {
  svgRef?: Ref;
  color?: string;
};

const defaultProps: DefaultProps = {
  width: "1.5rem",
  height: "1.5rem",
};

function CloseSVG({ color, width, height, svgRef, ...props }: CloseSVGProps) {
  const { colors } = useTheme();

  return (
    <svg
      width={width}
      height={height}
      viewBox="10 10 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.782 26.268L12.976 13.462l1.131-1.132 12.807 12.806-1.132 1.132z"
        fill={color ?? colors.red}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.914 13.462L14.107 26.268l-1.131-1.132L25.782 12.33l1.132 1.132z"
        fill={color ?? colors.red}
      />
    </svg>
  );
}

CloseSVG.defaultProps = defaultProps;

const ForwardRef = React.forwardRef((props: Props, ref: Ref) => (
  <CloseSVG {...props} svgRef={ref} />
));

export default ForwardRef;
