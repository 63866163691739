import styled, { css } from "styled-components/macro";

import { rgba } from "global-styles/lib/colors";
import { spin, reverseSpin } from "global-styles/lib/keyframes";

export const Field = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ErrorMessage = styled.div`
  margin-left: 1.5rem;
  color: ${({ theme }) => theme.colors.red};
  line-height: 1.2;
`;

export const FieldPlaceholder = styled.div`
  position: relative;
`;

export const SpinnerPlaceholder = styled.div`
  padding: 1rem;
`;

export const ContentPlaceholder = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LeftContentPlaceholder = styled(ContentPlaceholder)`
  left: 0;

  & > * + * {
    padding-right: 1rem;
  }

  &:first-child {
    padding-left: 2rem;
  }
`;

export const RightContentPlaceholder = styled(ContentPlaceholder)`
  right: 0;

  & > * + * {
    padding-left: 1rem;
  }

  &:last-child {
    padding-right: 2rem;
  }
`;

export const Suffix = styled.span`
  color: ${({ theme }) => rgba(theme.colors.text, 0.3)}};
`;

type StyledValidIconProps = {
  valid: boolean;
};

export const ValidIcon = styled.span<StyledValidIconProps>`
  margin-top: 3px;
  animation-duration: 0.1s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-name: ${({ valid }) => (valid ? spin : reverseSpin)};
`;

type StyledInputProps = {
  valid: boolean;
  leftIconSpacing: number;
  rightIconSpacing: number;
};

export const Input = styled.input<StyledInputProps>`
  outline: 0;
  line-height: 1;
  border-radius: 25px;
  letter-spacing: 0.025em;
  height: 5rem;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: 2px solid ${({ theme, valid }) =>
    valid ? theme.colors.fieldBorders : rgba(theme.colors.red, 0.5)};

  ::placeholder {
    color: ${({ theme }) => rgba(theme.colors.text, 0.3)}};
  }

  &:focus {
    &::placeholder {
      opacity: 0;
    }

    box-shadow: 0 0 0 0.2rem ${({ theme, valid }) =>
      valid
        ? rgba(theme.colors.fieldBorders, 0.3)
        : rgba(theme.colors.red, 0.1)}};
  }

  padding: 1.2rem 2rem;

  ${({ leftIconSpacing }) =>
    leftIconSpacing > 0 &&
    css`
      padding-left: calc(${leftIconSpacing}px + 4px);
    `}

  ${({ rightIconSpacing }) =>
    rightIconSpacing > 0 &&
    css`
      padding-right: calc(${rightIconSpacing}px + 4px);
    `}

`;
