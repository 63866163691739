import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

(async () => {
  if (typeof window.IntersectionObserver === "undefined") {
    // @ts-ignore
    await import("intersection-observer");
  }
})();
