import React from "react";
import styled from "styled-components/macro";

import { rgba } from "global-styles/lib/colors";

import mailTo from "lib/mailTo";

import HubspotContext from "context/hubspot";

type StyleProps = {
  primary?: boolean;
};

const StyledLink = styled.a<StyleProps>`
  color: ${({ theme, primary }) =>
    primary ? theme.colors.primary : rgba(theme.colors.text, 0.4)};
`;

type Props = React.PropsWithChildren<{} & StyleProps>;

const SupportLink = ({ children, primary }: Props) => {
  const { ready, open } = React.useContext(HubspotContext);

  let linkProps: Record<string, any> = { primary };

  if (ready) {
    linkProps.onClick = open;
  } else {
    linkProps.href = mailTo("support@deskpass.com");
  }

  return <StyledLink {...linkProps}>{children}</StyledLink>;
};

SupportLink.defaultProps = {
  children: "Support",
  primary: false,
};

export default SupportLink;
