import React from "react";
import { v4 as uuid } from "uuid";

import { Props as Notification } from "components/Notifications/Notification";

import { DeskpassError } from "repository/deskpass/types/meta";

type Notifications = Array<Notification>;

type NotificationContextState = {
  notifications: Notifications;
  addNotififcation: Function;
  displayError: Function;
  closeNotification: Function;
};

type Props = React.PropsWithChildren<{}>;

const Context = React.createContext({} as NotificationContextState);

// Minimum notification duration of 2 seconds otherwise it won't show
// due to the animation time of 500ms to appear and then 500ms to fade.
const minDuration = 2 * 1000;
// If not specified notification will be visible for 5 seconds
const defaultDuration = 5 * 1000;

const getDefaultNotification = () => ({
  id: uuid(),
  type: "error",
});

export const NotificationProvider = ({ children }: Props) => {
  const [notifications, setNotifications] = React.useState<Notifications>(
    () => []
  );

  const closeNotification = React.useCallback((id: string) => {
    setNotifications((notifications) =>
      notifications.filter((notification) => notification.id !== id)
    );
  }, []);

  const addNotififcation = React.useCallback(
    (_notification: Notification) => {
      const defaultNotification = getDefaultNotification();
      const duration =
        _notification.duration && _notification.duration! >= minDuration
          ? _notification.duration
          : defaultDuration;

      const notification = {
        ...defaultNotification,
        ..._notification,
        onClose: () => closeNotification(notification.id),
        duration,
      };

      setNotifications((notifications) => [...notifications, notification]);

      setTimeout(() => {
        closeNotification(notification.id);
      }, notification.duration);
    },
    [closeNotification]
  );

  const displayError = React.useCallback(
    (err: Error | DeskpassError, fallbackMsg?: string) => {
      if (!!(err as DeskpassError).displayError) {
        return addNotififcation({
          type: "error",
          message: (err as DeskpassError).displayError!,
        });
      }

      if (!!fallbackMsg) {
        return addNotififcation({
          type: "error",
          message: fallbackMsg,
        });
      }

      if (!!(err as Error).message) {
        return addNotififcation({
          type: "error",
          message: (err as Error).message!,
        });
      }

      addNotififcation({
        type: "error",
        message: "An unexpected error has occurred",
      });
    },
    [addNotififcation]
  );

  const context = React.useMemo(
    () => ({
      notifications,
      addNotififcation,
      displayError,
      closeNotification,
    }),
    [notifications, addNotififcation, closeNotification, displayError]
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default Context;
