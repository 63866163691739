import styled, { css } from "styled-components/macro";

export const Placeholder = styled.div`
  position: relative;
  width: 100%;
`;

export const IconRight = styled.div`
  position: absolute;
  right: 3rem;
  top: 0;
  bottom: 0;
`;

export const IconLeft = styled.div`
  position: absolute;
  left: 3rem;
  top: 0;
  bottom: 0;
`;

export const Button = styled.button`
  cursor: pointer;
  border: 0;
  height: 5rem;
  width: 100%;
  border-radius: 25px;
  padding: 1.2rem 2rem;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme: { colors }, disabled }) =>
    disabled ? colors.disabled : colors.primary};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);

  &:hover {
    ${({ disabled, theme: { colors } }) => css`
      background: ${disabled ? colors.disabled : colors.accent};
      ${!disabled && "transform: translateY(-2px)"};
    `}
  }
`;
