import React from "react";
import { useTheme } from "styled-components/macro";

import { rgba } from "global-styles/lib/colors";

type DefaultProps = {
  width?: number | string;
  height?: number | string;
  color?: string;
};

type Ref = React.ForwardedRef<SVGSVGElement> | null;

type Props = React.SVGProps<SVGSVGElement> & DefaultProps;
type ResetSVGProps = Props & {
  svgRef?: Ref;
};

const defaultProps: DefaultProps = {
  width: "1.7rem",
  height: "1rem",
};

function ResetSvg({ width, height, color, svgRef, ...props }: ResetSVGProps) {
  const { colors } = useTheme();

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 550 439.7"
      xmlSpace="preserve"
      ref={svgRef}
      {...props}
    >
      <path
        d="M373.9 87.5H136.8V0L0 109.5 136.8 219v-87.5h237.1c72.8 0 132.1 59.3 132.1 132.1s-59.3 132.1-132.1 132.1H188.7v44h185.2c97.1 0 176.1-79 176.1-176.1S471 87.5 373.9 87.5z"
        style={{
          fill: color ?? rgba(colors.text, 0.6),
        }}
      />
    </svg>
  );
}

ResetSvg.defaultProps = defaultProps;

const ForwardRef = React.forwardRef((props: Props, ref: Ref) => (
  <ResetSvg {...props} svgRef={ref} />
));

export default ForwardRef;
