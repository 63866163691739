import * as React from "react";
import { useTheme } from "styled-components/macro";

type DefaultProps = {
  width?: number | string;
  height?: number | string;
};

type Props = React.SVGProps<SVGSVGElement> & DefaultProps;
type MonthSVGProps = Props & {
  svgRef?: React.Ref<SVGSVGElement>;
  color?: string;
};

const defaultProps: DefaultProps = {
  width: "1.6rem",
  height: "1.7rem",
};

function MonthSVG({ width, height, color, svgRef, ...props }: MonthSVGProps) {
  const { colors } = useTheme();

  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.09.74v2.217a.745.745 0 0 1-.212.522.721.721 0 0 1-1.029 0 .745.745 0 0 1-.213-.522V.739c0-.196.077-.384.213-.523a.721.721 0 0 1 1.029 0A.745.745 0 0 1 5.09.74ZM16 2.956v12.565c0 .392-.153.768-.426 1.045a1.443 1.443 0 0 1-1.028.433H1.455c-.386 0-.756-.156-1.029-.433A1.49 1.49 0 0 1 0 15.522V2.957c0-.393.153-.769.426-1.046a1.443 1.443 0 0 1 1.029-.433h1.09a.36.36 0 0 1 .258.109c.068.069.106.163.106.26v1.046c-.006.375.124.74.367 1.023.242.283.578.465.945.512a1.433 1.433 0 0 0 1.12-.376 1.478 1.478 0 0 0 .477-1.096V1.848c0-.098.038-.192.107-.262a.36.36 0 0 1 .257-.108h3.636a.36.36 0 0 1 .257.108c.069.07.107.164.107.262v1.045c-.006.375.124.74.366 1.023.242.283.58.465.945.512a1.433 1.433 0 0 0 1.12-.376 1.479 1.479 0 0 0 .478-1.096V1.848c0-.098.038-.192.106-.262a.36.36 0 0 1 .258-.108h1.09c.386 0 .756.156 1.029.433.273.277.426.653.426 1.046ZM14.182 7.39H1.818a.36.36 0 0 0-.257.109.373.373 0 0 0-.106.26v7.392c0 .098.038.192.106.262a.36.36 0 0 0 .257.108h12.364a.36.36 0 0 0 .257-.108.373.373 0 0 0 .107-.262V7.761a.374.374 0 0 0-.225-.342.358.358 0 0 0-.14-.028ZM11.636 0c.193 0 .378.078.515.216a.745.745 0 0 1 .213.523v2.218a.745.745 0 0 1-.213.522.721.721 0 0 1-1.029 0 .745.745 0 0 1-.213-.522V.739a.75.75 0 0 1 .213-.523.726.726 0 0 1 .514-.216Z"
        fill={color ?? colors.white}
      />
      <path
        d="M3 9h2v2H3V9ZM11 9h2v2h-2V9ZM3 12h2v2H3v-2ZM11 12h2v2h-2v-2Z"
        fill={color ?? colors.white}
      />
    </svg>
  );
}

MonthSVG.defaultProps = defaultProps;

const ForwardRef = React.forwardRef(
  (props: Props, ref: React.Ref<SVGSVGElement>) => (
    <MonthSVG {...props} svgRef={ref} />
  )
);

export default ForwardRef;
