import React from "react";
import isEqual from "lodash.isequal";

import useLocalRepository from "hooks/useRepository/local";
import useDeskpassRepository from "hooks/useRepository/deskpass";
import useOnRouteChange from "hooks/useOnRouteChange";

import { TeamSetupFields } from "pages/Setup";

import CustomizationContext from "context/customization";

import * as browserStorage from "lib/browserStorage";
import { removeEmpty } from "lib/utilities";
import logger from "lib/log";

import {
  TeamManager,
  TeamOrganization,
  TeamAndManager,
} from "repository/deskpass/types/data";

type SessionContextState = {
  ready: boolean;
  loading: boolean;
  authenticated: boolean;
  currentUser?: TeamManager;
  currentTeam?: TeamOrganization;
  loadSelf: Function;
  signup: Function;
  updateTeam: Function;
  updateTeamAndManager: Function;
  logout: Function;
  localLogout: Function;
};

const Context = React.createContext({} as SessionContextState);

type SessionCache = {
  accessToken?: string;
};

export const sessionCache: SessionCache = {};

const setAccessToken = (accessToken: string) => {
  if (!sessionCache.accessToken) {
    sessionCache.accessToken = accessToken;
  }
};

export const SessionProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { customization, updateCustomization } =
    React.useContext(CustomizationContext);

  const [currentTeam, setCurrentTeam] = React.useState<TeamOrganization>();

  const [authenticated, setAuthenticated] = React.useState<boolean>(
    () => false
  );

  const [teamAndManagerProcessing, setTeamAndManagerProcessing] =
    React.useState<boolean>(() => false);

  const [{ ready: tokenReady, data: tokens, error: tokenError }] =
    useLocalRepository((repository) => () => repository.auth.getToken());

  const [
    { ready: selfReady, loading: selfLoading, data: currentUser },
    loadSelf,
  ] = useDeskpassRepository(
    (repository) => () => repository.manager.loadSelf(),
    {
      fireOnMount: false,
    }
  );

  // Reset customization context to default upon navigation
  // This is necessary in case the user sets a different color
  // in the customization page and just skips it.
  useOnRouteChange(() => {
    if (
      !!currentTeam?.customization &&
      !isEqual(currentTeam?.customization, customization)
    ) {
      updateCustomization(currentTeam.customization);
    }
  });

  const loadSelfAndTeam = React.useCallback(async () => {
    const storedId = await browserStorage.getTeamId();
    const teamId = currentTeam?.id ?? storedId;
    const { data: currentUser } = await loadSelf();

    const team = currentUser.teamOrganizations.find(
      (team: TeamOrganization) => team.id === teamId
    );
    setCurrentTeam(team);
    updateCustomization(team.customization);
  }, [loadSelf, currentTeam?.id, updateCustomization]);

  const [{ loading: updateManagerProcessing }, updateTeamAndManagerRequest] =
    useDeskpassRepository(
      (repository) => (teamId, fields, triggerEvent) => {
        return repository.team.updateTeamAndManager(
          teamId,
          fields,
          triggerEvent
        );
      },
      { fireOnMount: false }
    );

  const [{ loading: updateProcessing }, updateTeamRequest] =
    useDeskpassRepository(
      (repository) => (teamFields, triggerEvent) => {
        return repository.team.update(
          currentTeam?.id!,
          teamFields,
          triggerEvent
        );
      },
      { fireOnMount: false }
    );

  const [, teamSignup] = useDeskpassRepository(
    (repository) => (fields) => repository.team.signup(fields),
    { fireOnMount: false }
  );

  const [, login] = useLocalRepository(
    (repository) =>
      ({ email, password }) =>
        repository.auth.login(email, password),
    { fireOnMount: false }
  );

  const [, logoutRequest] = useLocalRepository(
    (repository) => () => repository.auth.logout(),
    { fireOnMount: false }
  );

  const [, localLogoutRequest] = useLocalRepository(
    (repository) => () => repository.auth.localLogout(),
    { fireOnMount: false }
  );

  const selfRef = React.useRef<Record<string, any>>({});

  React.useEffect(() => {
    selfRef.current.authenticate = async (accessToken?: string) => {
      const token = accessToken ?? tokens?.accessToken;

      if (!!token) {
        setAccessToken(token);
        await loadSelfAndTeam();
        setAuthenticated(true);
      }

      return token;
    };
  });

  React.useEffect(() => {
    if (!!tokens?.accessToken) {
      selfRef.current.authenticate();
    }
  }, [tokens?.accessToken]);

  const signup = React.useCallback(
    async (data: TeamSetupFields) => {
      const {
        data: { teamOrganization },
      } = await teamSignup(data);
      await browserStorage.setTeamId(teamOrganization.id);

      const { data: tokens } = await login(data);
      return selfRef.current.authenticate(tokens!.accessToken);
    },
    [teamSignup, login]
  );

  const logout = React.useCallback(() => {
    return Promise.all([logoutRequest(), browserStorage.storage.clear()]);
  }, [logoutRequest]);

  const localLogout = React.useCallback(() => {
    return Promise.all([localLogoutRequest(), browserStorage.storage.clear()]);
  }, [localLogoutRequest]);

  const updateTeam = React.useCallback(
    async (teamFields: TeamOrganization, ...rest: any[]) => {
      await updateTeamRequest(teamFields, ...rest);
      await loadSelfAndTeam();
    },
    [updateTeamRequest, loadSelfAndTeam]
  );

  const updateTeamAndManager = React.useCallback(
    async (
      { teamOrganization, teamManager }: TeamAndManager,
      triggerEvent: string
    ) => {
      try {
        setTeamAndManagerProcessing(true);
        const teamId = currentTeam?.id;

        const result = await updateTeamAndManagerRequest(
          teamId,
          {
            teamOrganization: removeEmpty(teamOrganization),
            teamManager: removeEmpty(teamManager),
          },
          triggerEvent
        );

        await loadSelfAndTeam();
        return result;
      } catch (err) {
        logger.error('Error calling "updateTeamAndManager"', err);
        throw err;
      } finally {
        setTeamAndManagerProcessing(false);
      }
    },
    [updateTeamAndManagerRequest, loadSelfAndTeam, currentTeam?.id]
  );

  const sessionReady =
    // Authenticated
    (tokenReady && authenticated && selfReady) ||
    // Does not have token
    (tokenReady && !!tokenError);

  const loading =
    updateProcessing ||
    updateManagerProcessing ||
    selfLoading ||
    teamAndManagerProcessing;

  const context = {
    // data
    loading,
    ready: sessionReady,
    authenticated,
    currentUser,
    currentTeam,
    // actions
    signup,
    loadSelf,
    updateTeam,
    updateTeamAndManager,
    logout,
    localLogout,
  };

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default Context;
