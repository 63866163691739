import styled, { css } from "styled-components/macro";

import { Checkbox } from "components/Fields/Checkbox/styles";

import { fieldBorders } from "global-styles/lib/borders";
import { rgba } from "global-styles/lib/colors";

const borderColor = css`
  ${({ theme }) => theme.colors.fieldBorders}
`;

type IconCheckboxProps = {
  checked: boolean;
  row: boolean;
};

export const IconCheckbox = styled.div<IconCheckboxProps>`
  user-select: none;
  cursor: pointer;
  height: 13rem;
  width: 16rem;
  border: 2px solid ${borderColor};
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 1rem;
  outline: none;

  ${fieldBorders.focused}

  ${({ theme }) => theme.media.medium} {
    height: 11rem;
  }

  ${({ theme }) => theme.media.small} {
    height: 10rem;
  }

  ${({ checked, theme }) =>
    checked &&
    css`
      background: ${rgba(theme.colors.blueFaint, 0.5)};
    `}

  ${({ row }) =>
    !!row &&
    css`
      width: 100%;

      & > ${Checkbox} {
        align-self: start;
      }
    `}
`;
