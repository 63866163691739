import React from "react";
import { useMediaQuery } from "react-responsive";

import Breadcrumbs from "components/Navigation/Breadcrumbs";
import Logo from "components/Svg/Logo";
import Footer from "components/Layout/Footer";

import SessionContext from "context/session";

import { breakpoints } from "global-styles/lib/media";

import { makeChildrenRenderer } from "lib/react";

import * as Styled from "./styles";

const { Desktop, Mobile } = Styled;

const Main = makeChildrenRenderer();
const Accent = makeChildrenRenderer();

export const Section = { Main, Accent };

type SectionType = typeof Main | typeof Accent;
type Child = React.ReactElement<{}, SectionType>;
type DefaultPageLayoutProps = {
  children: Array<Child>;
};

const CurrentSessionInfo = () => {
  const { authenticated, currentUser, currentTeam, logout } =
    React.useContext(SessionContext);
  const isDesktop = useMediaQuery({ minWidth: breakpoints.large });

  const [processing, setProcessing] = React.useState(() => false);

  const onLogout = React.useCallback(async () => {
    setProcessing(true);
    await logout();
    window.location.href = "/";
  }, [logout]);

  if (!authenticated) return null;

  const props = {
    email: currentUser!.email,
    name: currentTeam!.name,
    onClick: onLogout,
    disabled: processing,
  };

  if (isDesktop) {
    return <Desktop.CurrentSessionInfo {...props} />;
  }

  return <Mobile.CurrentSessionInfo {...props} />;
};

const DefaultPageLayout = ({ children }: DefaultPageLayoutProps) => {
  const isDesktop = useMediaQuery({ minWidth: breakpoints.large });

  const childArray = React.Children.map(children, (child) => child);
  const main = childArray.find((it) => it.type === Main);
  const accent = childArray.find((it) => it.type === Accent);

  if (isDesktop) {
    return (
      <Desktop.Page>
        {!!main && (
          <Desktop.Main>
            <Desktop.Header>
              <Desktop.LogoArea>
                <Desktop.Logo>
                  <Logo />
                </Desktop.Logo>
                <CurrentSessionInfo />
              </Desktop.LogoArea>

              <Desktop.Breadcrumbs>
                <Breadcrumbs />
              </Desktop.Breadcrumbs>
            </Desktop.Header>

            <Desktop.MainContent>{main}</Desktop.MainContent>

            <Desktop.Footer>
              <Footer />
            </Desktop.Footer>
          </Desktop.Main>
        )}

        {!!accent && <Desktop.Accent>{accent}</Desktop.Accent>}
      </Desktop.Page>
    );
  }

  return (
    <Mobile.Page>
      <Mobile.Header>
        <Mobile.LogoArea>
          <Mobile.Logo>
            <Logo />
          </Mobile.Logo>
          <CurrentSessionInfo />
        </Mobile.LogoArea>

        <Mobile.Breadcrumbs>
          <Breadcrumbs />
        </Mobile.Breadcrumbs>
      </Mobile.Header>

      {!!accent && <Mobile.Accent>{accent}</Mobile.Accent>}

      {!!main && <Mobile.Main>{main}</Mobile.Main>}

      <Mobile.Footer>
        <Footer />
      </Mobile.Footer>
    </Mobile.Page>
  );
};

export const Page = DefaultPageLayout;

export default DefaultPageLayout;
