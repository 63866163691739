import React from "react";

import FontStyles from "./Fonts";
import PageStyles from "./Page";
import HubspotStyles from "./vendor/Hubspot";

const GlobalStyles = () => {
  return (
    <>
      <FontStyles />
      <PageStyles />
      <HubspotStyles />
    </>
  );
};

export default GlobalStyles;
