import React from "react";

import { LoadingSpinner } from "components/ProgressIndicator";

import * as Styled from "./styles";

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  processing?: boolean;
  buttonClassName?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
};

const Button = ({
  processing,
  className,
  buttonClassName,
  leftIcon,
  rightIcon,
  ...btnProps
}: ButtonProps) => {
  const renderRightIcon = !!rightIcon || processing;
  return (
    <Styled.Placeholder className={className}>
      {!!leftIcon && <Styled.IconLeft>{leftIcon}</Styled.IconLeft>}

      <Styled.Button {...btnProps} className={buttonClassName} />

      {renderRightIcon && (
        <Styled.IconRight>
          {processing && <LoadingSpinner inline small />}

          {rightIcon && !processing && <>{rightIcon}</>}
        </Styled.IconRight>
      )}
    </Styled.Placeholder>
  );
};

export default Button;
