import React from "react";

import RootErrorBoundary from "components/ErrorHandling/RootErrorBoundary";
import NavigationProvider from "components/Navigation/Provider";
import StripeProvider from "components/Fields/Stripe/Provider";

import AnalyticsContainer from "components/Analytics";

import { SessionProvider } from "context/session";
import { CountryProvider } from "context/country";
import { CustomizationProvider } from "context/customization";
import { NotificationProvider } from "context/notification";
import { HubspotProvider } from "context/hubspot";

import { LoggerContainer } from "lib/log";

import { Compose } from "lib/react";

import { ThemeProvider } from "global-styles/Theme";

import App from "./App";

const AppContainer = () => {
  return (
    <Compose
      components={[
        RootErrorBoundary,
        ThemeProvider,
        NotificationProvider,
        StripeProvider,
        NavigationProvider,
        CountryProvider,
        CustomizationProvider,
        SessionProvider,
        LoggerContainer,
        HubspotProvider,
      ]}
    >
      <AnalyticsContainer>
        <App />
      </AnalyticsContainer>
    </Compose>
  );
};

export default AppContainer;
