import React from "react";
import { useTheme } from "styled-components/macro";

type Ref = React.ForwardedRef<SVGSVGElement> | null;

type DefaultProps = {
  width?: number | string;
  height?: number | string;
};

type Props = React.SVGProps<SVGSVGElement> & DefaultProps;
type ExclamationSVGProps = Props & {
  svgRef?: Ref;
  color?: string;
};

const defaultProps: DefaultProps = {
  width: 4,
  height: 12,
};

function ExclamationSVG({
  color,
  width,
  height,
  svgRef,
  ...props
}: ExclamationSVGProps) {
  const { colors } = useTheme();

  return (
    <svg
      width={width}
      height={height}
      fill="none"
      ref={svgRef}
      viewBox="0 0 2 7"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
      {...props}
    >
      <path
        d="M.879 4.586a.863.863 0 1 1 0 1.726.863.863 0 0 1 0-1.726Zm-.598-.36h1.178L1.737 0H0l.281 4.226Z"
        fill={color ?? colors.white}
      />
    </svg>
  );
}

ExclamationSVG.defaultProps = defaultProps;

const ForwardRef = React.forwardRef((props: Props, ref: Ref) => (
  <ExclamationSVG {...props} svgRef={ref} />
));

export default ForwardRef;
