import React from "react";
import * as Styled from "./styles";

import Arrow from "components/Svg/Arrow";

import { makeChildrenRenderer } from "lib/react";

import useEvent from "hooks/useEvent";
import useOutsideClick from "hooks/useOutsideClick";
import useEventListener from "hooks/useEventListener";

type OptionValue = string | number | undefined;
type OptionProps = React.OptionHTMLAttributes<HTMLOptionElement> & {
  value: OptionValue;
};

export const Option = makeChildrenRenderer<OptionProps>();
export type SelectOption = React.ReactElement<OptionProps, typeof Option>;

export type SelectProps = {
  children?: SelectOption[] | SelectOption;
  onChange: (event: React.ChangeEvent) => void;
  name: string;
  value?: OptionValue;
  initialValue?: OptionValue;
  className?: string;
  disabled?: boolean;
};

/*
 * TODO since this select is fully custom and don't use the <select>
 * tag we may eventually need to improve acessibility.
 */
const Select = React.forwardRef<HTMLInputElement, SelectProps>(
  (
    { children, onChange, name, value, initialValue, className, disabled },
    ref
  ) => {
    const wrapperRef = React.useRef<HTMLDivElement>(null);
    const panelRef = React.useRef<HTMLDivElement>(null);
    const currentOptionRef = React.useRef<HTMLDivElement>(null);

    const options = React.Children.map<SelectOption, SelectOption>(
      children ?? [],
      (child) => child
    ).filter((it) => it.type === Option);

    const [selected, setSelected] = React.useState<SelectOption>(() => {
      /*
       * Sets initial value according to this precedence criteria:
       *
       * - props.initialValue
       * - props.value
       * - options[0]
       */
      return (
        options.find(
          (it) => it.props.value === initialValue ?? it.props.value === value
        ) ?? options[0]
      );
    });

    const [focusedOption, setFocusedOption] = React.useState<number | null>(
      () => null
    );

    // When props.value is provided the value state should be controlled by the caller
    React.useEffect(() => {
      if (!!value) {
        const option = options.find(
          (it) => it.props.value === value
        ) as SelectOption;
        setSelected(option);
      }
    }, [value, options]);

    const [open, setOpen] = React.useState(() => false);

    const togglePanel = useEvent(() => {
      if (disabled) return;

      setOpen((open) => {
        const nextValue = !open;
        const selectField = currentOptionRef.current;

        selectField?.focus?.();

        return nextValue;
      });
    });

    const openPanel = useEvent(() => {
      if (disabled) return;

      setOpen(true);
      currentOptionRef.current?.focus?.();
    });

    const closePanel = useEvent(() => {
      setOpen(false);
      currentOptionRef.current?.focus?.();
    });

    // TODO improve typing here
    // This typing looks bad cause of this workaround we're doing to make
    // react-form-hooks onChange happy
    const selectOption = React.useCallback(
      (e: any, option: SelectOption) => {
        closePanel();
        setSelected(option);

        if (typeof onChange === "function") {
          const { value } = option.props;
          //
          // Keep it compatible to the native <select> onChange
          // to make react-hooks-form happy.
          e.target.value = value;
          e.target.name = name;

          onChange(e);
        }
      },
      [onChange, name, closePanel]
    );

    useOutsideClick<HTMLDivElement>(wrapperRef, () => {
      if (open) {
        closePanel();
      }
    });

    const optionCount = options.length;

    const handleOptionKeyNavigation = React.useCallback(
      (keyCode: number) => {
        const { childNodes: optionNodes } = panelRef.current!;

        let nextFocusedOption = focusedOption;

        // Arrow Down
        if (keyCode === 40) {
          nextFocusedOption =
            focusedOption === null
              ? 0
              : Math.min(focusedOption + 1, optionCount);
        }

        // Arrow Up
        if (keyCode === 38) {
          nextFocusedOption = Math.max(0, (focusedOption ?? 0) - 1);
        }

        // Enter - Select option, abort and close panel
        if (keyCode === 13 && nextFocusedOption !== null) {
          const option = options[nextFocusedOption];

          const fakeEvent = {
            target: {
              name,
              value: option.props.value,
            },
          };

          return selectOption(fakeEvent, option);
        }

        // Focust on the next element in the panel
        (optionNodes[nextFocusedOption as number] as HTMLElement)?.focus?.();

        setFocusedOption(nextFocusedOption);
      },
      [optionCount, focusedOption, selectOption, options, name]
    );

    useEventListener("keydown", (e: Event) => {
      const { keyCode } = e as KeyboardEvent;

      if (open) {
        const pressedCloserKey = [
          27, // Escape
          9, // Tab
        ].includes(keyCode);

        if (pressedCloserKey) {
          return closePanel();
        }

        handleOptionKeyNavigation(keyCode);
      } else {
        const selectHasFocus =
          document.activeElement === currentOptionRef.current;
        const pressedOpenerKey = [
          13, // Enter
          32, // Space
          40, // Arrow Down
        ].includes(keyCode);

        if (selectHasFocus && pressedOpenerKey) {
          openPanel();
        }
      }
    });

    // Since the ref element is a div and not and input
    return (
      <Styled.Field
        ref={wrapperRef}
        className={className}
        disabled={!!disabled}
      >
        <Styled.SelectPlaceholder onClick={togglePanel} role="select">
          <input
            role="none"
            tabIndex={-1}
            type="hidden"
            ref={ref}
            name={name}
            value={selected.props.value}
          />

          <Styled.Select
            open={open}
            ref={currentOptionRef}
            tabIndex={disabled ? -1 : 0}
            aria-selected
            aria-haspopup
            aria-expanded={open}
          >
            {selected}
          </Styled.Select>

          {!disabled && (
            <Styled.SelectArrow>
              <Arrow />
            </Styled.SelectArrow>
          )}
        </Styled.SelectPlaceholder>

        {open && (
          <Styled.Panel ref={panelRef}>
            {options.map((option) => (
              <Styled.Option
                role="option"
                tabIndex={-1}
                aria-selected={false}
                key={option.key}
                onClick={(e) => selectOption(e, option)}
              >
                {option}
              </Styled.Option>
            ))}
          </Styled.Panel>
        )}
      </Styled.Field>
    );
  }
);

export default Select;
