import React from "react";
import axios, { AxiosResponse, AxiosError } from "axios";

import useMount from "hooks/useMount";
import useEvent from "hooks/useEvent";

import logger from "lib/log";

import { IRepository } from "repository/base";

export type Config<D = any> = {
  fireOnMount?: boolean;
  abortOnUnmount?: boolean;
  initialValue?: D;
};

type Meta = {
  status: number;
  statusText: string;
};

export type RequestState<D = any, E = any> = {
  ready: boolean;
  loading: boolean;
  data?: D;
  error?: E;
  meta?: Meta;
};

const defaultConfig: Config = {
  fireOnMount: true,
  abortOnUnmount: true,
};

export interface RequestMapper<R extends IRepository<any>, T> {
  (r: R["domain"]): (...args: any[]) => Promise<AxiosResponse<T>>;
}

export interface RequestCaller<T> {
  // TODO right now the typechecking is not working properly on the generic args
  // passed from here to the returned request function
  (...args: any[]): Promise<T>;
}

function useRepository<R extends IRepository<any> = any, D = any, E = any>(
  repository: R,
  requestMapper: RequestMapper<R, D | E>,
  config?: Config<D>
): [RequestState<D, E>, RequestCaller<RequestState<D, E>>] {
  const { fireOnMount, abortOnUnmount, initialValue } = {
    ...defaultConfig,
    ...config,
  };

  const requestCaller = useEvent(requestMapper(repository.domain));

  // Do stuff before request is fired
  // Or interrupt request if hook unmounts
  useMount(() => {
    if (abortOnUnmount) {
      let abortController: AbortController;
      const { interceptors } = repository!.client;

      const interceptor = interceptors.request.use((conf) => {
        abortController = new AbortController();
        return { ...conf, signal: abortController.signal };
      });

      // Dispose interceptor
      return () => {
        if (typeof abortController?.abort === "function") {
          abortController.abort();
        }

        interceptors.request.eject(interceptor);
      };
    }
  });

  // Network request state
  const [requestState, setRequestState] = React.useState<RequestState<D, E>>(
    () => ({
      meta: undefined,
      data: initialValue,
      error: undefined,
      loading: false,
      ready: !!initialValue,
    })
  );

  /*
   * Calls the RequestCaller and manages RequestState
   */
  const request = useEvent(async function (...args: any[]) {
    const defaultStateValues = {
      data: undefined,
      error: undefined,
      loading: false,
      ready: true,
    };

    const makeSetState =
      (newState = {}) =>
      (prevState: RequestState<D, E>) => ({
        ...prevState,
        ...defaultStateValues,
        ...newState,
      });

    try {
      setRequestState((prevState) => ({ ...prevState, loading: true }));

      const response = (await requestCaller!(...args)) as AxiosResponse<D>;
      const { data, status, statusText } = response;

      const newState = {
        data,
        meta: { status, statusText },
      };

      setRequestState(makeSetState(newState));

      return {
        ...defaultStateValues,
        data,
        meta: { status, statusText },
      };
    } catch (err) {
      const { response } = err as AxiosError;
      const status = response?.status;
      const error = response?.data;

      if (axios.isCancel(err)) {
        logger.info("Request cancelled in useRepository: ", requestCaller);
      } else {
        setRequestState(makeSetState({ error }));
        logger.error(
          `useRepository request failure with status ${status} `,
          `\nin: "${requestCaller}"`,
          "\nError: ",
          error
        );
      }

      throw error;
    }
  });

  /*
   * This is a "callAPI" wrapper used internally whenever we don't
   * want a promise to be returned and just want the state
   * machine to be updated instead.
   */
  const _request = useEvent(async (...args: any[]) => {
    try {
      await request(...args);
    } catch (err) {
      // Engulf the error on purpose
    }
  });

  useMount(() => {
    if (fireOnMount) {
      _request();
    }
  });

  return [requestState, request];
}

export default useRepository;
