import styled from "styled-components/macro";

import CloseIcon from "components/Svg/Close";
import ExclamationIcon from "components/Svg/Exclamation";

import { rgba } from "global-styles/lib/colors";
import { Theme } from "global-styles/Theme";

export const Notification = styled.div`
  ${({ theme }) => theme.fontStack.grotesqueMedium};

  padding: 1rem 1.5rem;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => rgba(theme.colors.text, 0.12)};
  box-shadow: 0 2px 5px 2px ${({ theme }) => rgba(theme.colors.text, 0.12)};
  background: ${({ theme }) => theme.colors.white};

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
`;

const closeAttrs = ({ theme }: { theme: Theme }) => ({
  color: rgba(theme.colors.text, 0.3),
});

export const Close = styled(CloseIcon).attrs(closeAttrs)`
  cursor: pointer;
  color: ${({ theme }) => rgba(theme.colors.text, 0.3)};
  width: 1.8em;
  height: 1.8rem;
`;

const Circle = styled.div`
  background: ${({ theme }) => theme.colors.red};
  border-radius: 100%;
  width: 1.8rem;
  height: 1.8rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Exclamation = () => {
  return (
    <Circle>
      <ExclamationIcon />
    </Circle>
  );
};
