import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements as StripeElementsProvider } from "@stripe/react-stripe-js";
import { useTheme } from "styled-components/macro";

import config from "lib/config";

const stripePromise = loadStripe(config.STRIPE_KEY);

const StripeProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const theme = useTheme();
  // TODO Stripe iframe is blocking http requests to localhost
  // so we are not able to load the fonts from CRA on dev mode
  const options = {
    fonts: [theme.fonts.grotesque],
  };

  return (
    <StripeElementsProvider stripe={stripePromise} options={options}>
      {children}
    </StripeElementsProvider>
  );
};

export default StripeProvider;
