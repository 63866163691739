import React from "react";
import localForage from "localforage";
import merge from "lodash.merge";
import clone from "lodash.clonedeep";

import { LoadingSpinner } from "components/ProgressIndicator";

import { ID } from "repository/deskpass/types/data";
import routes, { RouteKey } from "lib/routes";

const makeStorageKey = (s: string) => `deskpass_onboarding_${s}`;

const teamIdKey = makeStorageKey("team_id");
const formStateKey = makeStorageKey("form_state");

export const storage = localForage;

export const setTeamId = (id: ID) => localForage.setItem(teamIdKey, id);
export const getTeamId = () => localForage.getItem<ID>(teamIdKey);

export const useTeamIdState = () => {
  const [state, setState] = React.useState<ID | undefined>(
    () => initialStoreState.teamId
  );

  React.useEffect(() => {
    (async () => {
      const teamId = await getTeamId();

      if (!!teamId && state !== teamId) {
        setState(teamId);
      }
    })();
  }, [state]);

  return state;
};

type FormStateOptions = {
  [key in RouteKey]?: boolean;
};

type FormState = {
  submitted?: FormStateOptions;
};

type FormStateKey = keyof FormState;

const initialOptionsValues = Object.keys(routes).reduce(
  (acc, key) => ({ ...acc, [key]: false }),
  {}
);

const initialFormState = {
  submitted: initialOptionsValues,
};

export const setFormState = async (formState: FormState) => {
  const lastState = await getFormState();

  return localForage.setItem(
    formStateKey,
    merge({}, initialFormState, lastState, formState)
  );
};

export const getFormState = async (key?: FormStateKey) => {
  const formState = await localForage.getItem<FormState>(formStateKey);

  if (!formState) return clone(initialFormState);
  if (!key) return formState;

  return formState[key];
};

export const setFormSubmittedState = (step: RouteKey, value: boolean) =>
  setFormState({
    submitted: { [step]: value },
  });

export const getFormSubmittedState = async (step?: RouteKey) => {
  const submitted = (await getFormState("submitted")) as FormStateOptions;

  if (!step) return submitted;

  return submitted[step];
};

export const useFormSubmittedState = (step: RouteKey) => {
  const [ready, setReady] = React.useState<boolean>(() => false);
  const [state, setState] = React.useState<boolean | undefined>(() => false);

  React.useEffect(() => {
    (async () => {
      const submitted = (await getFormSubmittedState()) as FormStateOptions;
      const nextState = submitted[step];

      if (state !== nextState) {
        setState(nextState);
      }

      if (!ready) {
        setReady(true);
      }
    })();
  }, [step, state, ready]);

  return { ready, value: !!state };
};

interface WithFormSubmittedStateProps {
  submittedState: boolean;
}

export function withFormSubmittedState<T extends WithFormSubmittedStateProps>(
  step: RouteKey
) {
  return (WComponent: React.ComponentType<T>) => {
    const displayName =
      WComponent.displayName || WComponent.name || "Component";

    function Component(props: T) {
      const { ready, value } = useFormSubmittedState(step);

      if (!ready) {
        return <LoadingSpinner />;
      }

      return <WComponent {...props} submittedState={value} />;
    }

    Component.displayName = `With${displayName}`;

    return Component;
  };
}

// Initial browser store state

let initialStoreState = {
  teamId: undefined,
  formState: clone(initialFormState),
};

(async () => {
  const formState = await getFormState();
  const teamId = await getTeamId();

  merge(initialStoreState, {
    teamId,
    formState,
  });
})();
