import React from "react";

type Ref = React.ForwardedRef<SVGSVGElement> | null;

type DefaultProps = {
  width?: number | string;
  height?: number | string;
};

type Props = React.SVGProps<SVGSVGElement> & DefaultProps;
type MeetingRoomSVGProps = Props & {
  svgRef?: Ref;
};

const defaultProps: DefaultProps = {
  width: 81,
  height: 29,
};

function MeetingRoomSVG({
  width,
  height,
  svgRef,
  ...props
}: MeetingRoomSVGProps) {
  const color1 = "#c2c2cc";
  const color2 = "#140c40";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      width={width}
      height={height}
      xmlSpace="preserve"
      ref={svgRef}
      {...props}
    >
      <path
        fill={color1}
        d="M27.8 6.4c1.7 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3c0 1.6 1.4 3 3 3zM28 7.8h-.3c-2.8 0-5.1 2.3-5.1 5.1v3.5c0 1.9 1.1 3.6 2.7 4.4L24 28.2h7.7l-1.3-7.4c1.6-.9 2.7-2.5 2.7-4.5v-3.5c0-2.8-2.3-5-5.1-5zM15.4 6.4c1.7 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3c0 1.6 1.4 3 3 3zM15.6 7.8h-.3c-2.8 0-5.1 2.3-5.1 5.1v3.5c0 1.9 1.1 3.6 2.7 4.4l-1.3 7.4h7.7L18 20.8c1.6-.9 2.7-2.5 2.7-4.5v-3.5c0-2.8-2.3-5-5.1-5zM40.2 6.4c1.7 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3c0 1.6 1.3 3 3 3zM40.4 7.8h-.3c-2.8 0-5.1 2.3-5.1 5.1v3.5c0 1.9 1.1 3.6 2.7 4.4l-1.3 7.4h7.7l-1.3-7.4c1.6-.9 2.7-2.5 2.7-4.5v-3.5c-.1-2.8-2.3-5-5.1-5z"
      />
      <circle fill={color1} cx={64.9} cy={3.4} r={3} />
      <path
        fill={color1}
        d="M70.2 16.3v-3.5c0-2.8-2.3-5.1-5.1-5.1h-.3c-2.8 0-5.1 2.3-5.1 5.1v3.5c0 1.9 1.1 3.6 2.7 4.4l-1.3 7.4h7.7l-1.3-7.4c1.6-.8 2.7-2.5 2.7-4.4z"
      />
      <circle fill={color1} cx={52.6} cy={3.4} r={3} />
      <path
        fill={color1}
        d="M52.7 7.8h-.3c-2.8 0-5.1 2.3-5.1 5.1v3.5c0 1.9 1.1 3.6 2.7 4.4l-1.3 7.4h7.7l-1.3-7.4c1.6-.9 2.7-2.5 2.7-4.5v-3.5c0-2.8-2.3-5-5.1-5z"
      />
      <path
        fill={color2}
        d="M0 11.8h80.7v4.6H0zM3 18.2h4.6v10H3zM72.7 18.2h4.6v10h-4.6z"
      />
    </svg>
  );
}

MeetingRoomSVG.defaultProps = defaultProps;

const ForwardRef = React.forwardRef((props: Props, ref: Ref) => (
  <MeetingRoomSVG {...props} svgRef={ref} />
));
const Memo = React.memo(ForwardRef);

export default Memo;
