import styled, { css } from "styled-components/macro";

import Field from "components/Fields/Field";
import DiscreteValueSlider from "components/DiscreteValueSlider";

import { scaleWithVW } from "global-styles/lib/scaling";

export const Slider = styled(DiscreteValueSlider)`
  max-width: 50rem;

  ${({ theme }) => theme.media.small} {
    margin-left: 1.2rem;
    margin-right: 1.2rem;
  }
`;

export const GlobalLimitField = styled(Field)`
  max-width: 33%;
  min-width: 15rem;
`;

const gridDisposition = css`
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content max-content;
`;

export const IconRow = styled.div`
  display: flex;
  gap: ${scaleWithVW("1rem")};

  ${({ theme }) => theme.media.large} {
    gap: ${scaleWithVW("2rem")};
  }

  ${({ theme }) => theme.media.query({ from: "large", until: "1350px" })} {
    ${gridDisposition}
  }

  ${({ theme }) => theme.media.medium} {
    ${gridDisposition}
  }

  ${({ theme }) => theme.media.small} {
    display: flex;
    flex-direction: column;
  }
`;
