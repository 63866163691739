import { createGlobalStyle } from "styled-components/macro";

import media from "global-styles/lib/media";

const HubspotStyles = createGlobalStyle`
  html.hs-messages-mobile.hs-messages-widget-open body #hubspot-messages-iframe-container {
    height: calc(100% - env(safe-area-inset-top)) !important;
  }
  html:not(.hs-messages-mobile) body #hubspot-messages-iframe-container {
  ${media.query({ until: "large" })} {
      bottom: 5.3rem !important;
    }
  }
`;

export default HubspotStyles;
