import React from "react";
import { v4 as uuid } from "uuid";
import mergeRefs from "react-merge-refs";

import CheckboxSelected from "components/Svg/CheckboxSelected";

import useEventListener from "hooks/useEventListener";

import * as Styled from "./styles";

export type CheckboxProps = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  tabIndex?: number;
  checked?: boolean;
  id?: string;
  label?: string | React.ReactNode;
};

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, tabIndex, id, checked, onChange, ...inputProps }, ref) => {
    const boxRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const isChecked = !!checked;
    const [value, setValue] = React.useState<boolean>(() => isChecked);

    React.useEffect(() => {
      setValue(isChecked);
    }, [isChecked]);

    React.useEffect(() => {
      if (inputRef?.current?.checked) {
        setValue(true);
      }
    }, []);

    const checkboxId = React.useMemo(() => (!!id ? id : uuid()), [id]);

    const handleChange = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(!value);
        onChange(e);
      },
      [onChange, value]
    );

    useEventListener("keydown", (e: Event) => {
      const { keyCode } = e as KeyboardEvent;

      const focused = document.activeElement === boxRef.current;
      const validKeyPressed = [
        13, // Enter
        32, // Space
      ].includes(keyCode);

      // It toggles on space and enter press
      if (focused && validKeyPressed) {
        // Keep it compatible with react-form-hook
        inputRef.current!.checked = !value;
        const fakeEvent = {
          target: inputRef.current,
        } as React.ChangeEvent<HTMLInputElement>;

        handleChange(fakeEvent);
      }
    });

    const onWrapperClick = React.useCallback(
      (e: React.MouseEvent) => e.stopPropagation(),
      []
    );

    return (
      <Styled.Checkbox onClick={onWrapperClick}>
        <Styled.Box
          ref={boxRef}
          role="checkbox"
          tabIndex={tabIndex ?? 0}
          checked={value}
          aria-checked={value}
        >
          <Styled.Input
            {...inputProps}
            onChange={handleChange}
            type="checkbox"
            tabIndex={-1}
            role="none"
            id={checkboxId}
            ref={mergeRefs([inputRef, ref])}
          />

          {value && (
            <Styled.Check>
              <CheckboxSelected />
            </Styled.Check>
          )}
        </Styled.Box>

        <Styled.Label htmlFor={checkboxId}>{label}</Styled.Label>
      </Styled.Checkbox>
    );
  }
);

export default Checkbox;
