import styled from "styled-components/macro";

import SelectComponent from "components/Fields/Select";

export const Select = styled(SelectComponent)`
  width: 12rem;
`;

export const FlagOption = styled.div`
  display: flex;
  align-items: center;
  width: 12rem;

  & > * {
    margin-right: 0.5rem;
  }
`;
