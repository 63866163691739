import queryString from "query-string";

import { isEmpty, removeEmpty, pick } from "lib/utilities";
import * as patterns from "lib/patterns";

type EmailArgs = {
  cc?: string;
  bcc?: string;
  subject?: string;
  body?: string;
};

const isValidEmail = (str: string) => patterns.email.test(str);

const mailTo = (to: string, emailArgs: EmailArgs = {}) => {
  if (typeof to !== "string" || !isValidEmail(to)) {
    throw new Error("mailTo first arg must be a valid email address");
  }

  let mailUrl = `mailto:${to}`;
  let validEmailArgs = removeEmpty(
    pick(emailArgs, ["cc", "bcc", "subject", "body"])
  );

  if (!isEmpty(validEmailArgs)) {
    mailUrl += `?${queryString.stringify(validEmailArgs)}`;
  }

  return mailUrl;
};

export default mailTo;
