import React from "react";

import * as Styled from "./styles";

const LoadingSpinner = (props: Styled.StyledSpinnerProps) => {
  return <Styled.LoadingSpinner {...props} />;
};

LoadingSpinner.defaultProps = {
  inline: false,
  small: false,
};

export default LoadingSpinner;
