/**
 * Component for displaying a slider component with stop values.
 */
import React from "react";

import { setupDragging } from "./utils";
import * as Styled from "./styles";

type DiscreteValueSliderProps = {
  children?: DiscreteValueChildElement[] | DiscreteValueChildElement;
  onChange: (value: number) => void;
  onMouseDown: (event: React.MouseEvent) => void;
  initialValue?: number;
  faded?: boolean;
  className?: string;
};

const DiscreteValueSlider = ({
  className,
  children,
  onChange,
  onMouseDown,
  initialValue,
  faded,
}: DiscreteValueSliderProps) => {
  const [dragging, setDragging] = React.useState(() => false);
  const [offset, setOffset] = React.useState<number | null>(() => null);

  const parentNodeRef = React.useRef<HTMLDivElement>(null);
  const handleRef = React.useRef<HTMLDivElement>(null);

  // Pass props into a reference so we always have current props in handlers
  const selfRef = React.useRef<Record<string, any>>({});

  const values = React.useMemo(() => {
    return React.Children.map(children ?? [], (child) => child.props.value);
  }, [children]);

  React.useEffect(() => {
    selfRef.current.onChange = onChange;
    selfRef.current.initialValue = initialValue;
    selfRef.current.values = values;
    selfRef.current.isDragging = () => dragging;
  });

  // DiscreteValue (children) count
  const discreteValueCount = React.useMemo(
    () => React.Children.count(children),
    [children]
  );

  // Setup the Handle dragging logic
  React.useEffect(() => {
    const handleNode = handleRef.current;
    const parentNode = parentNodeRef.current;

    if (!!handleNode && !!parentNode) {
      const { onChange, initialValue, values, isDragging } = selfRef.current;

      const domNodes = {
        handleNode,
        parentNode,
      };

      const actions = {
        onChange,
        setOffset,
        setDragging,
        isDragging,
      };

      const { register, dispose, snapToNearestValue } = setupDragging(
        domNodes,
        actions,
        values,
        initialValue
      );

      selfRef.current.snapToNearestValue = snapToNearestValue;

      register();

      return () => {
        dispose();
      };
    }
  }, []);

  const onSliderMouseDown = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      if (typeof onMouseDown === "function") {
        onMouseDown(event);
      }

      const { snapToNearestValue, onChange, values, isDragging } =
        selfRef.current;

      if (isDragging()) {
        return;
      }

      const index = snapToNearestValue!(event.nativeEvent.clientX);

      onChange(values[index]);
    },
    [onMouseDown]
  );

  // Maps children injecting necessary props in them through cloning
  const childrenMapper = React.useCallback(
    (child: DiscreteValueChildElement, index: number) =>
      React.cloneElement<DiscreteValueProps>(child, {
        ...child.props,
        last: index === discreteValueCount - 1,
      }),
    [discreteValueCount]
  );

  // Keep only child elements that are of type SliderValue
  const discreteValues = React.Children.map(
    children ?? [],
    childrenMapper
  ).filter((it) => it.type === DiscreteValue);

  let style: Record<string, any> = {};

  if (!!offset) {
    style.left = offset;
  }

  return (
    <>
      <Styled.WindowCursor dragging={dragging} />

      <Styled.DiscreteValueSlider
        className={className}
        ref={parentNodeRef}
        onMouseDown={onSliderMouseDown}
        faded={faded}
      >
        <Styled.Slider>{discreteValues}</Styled.Slider>

        <Styled.Handle ref={handleRef} dragging={dragging} style={style} />
      </Styled.DiscreteValueSlider>
    </>
  );
};

export default DiscreteValueSlider;

type DiscreteValueProps = {
  value: number;
  label?: string;
  last?: boolean;
};

type DiscreteValueChildElement = React.ReactElement<
  DiscreteValueProps,
  React.FC<DiscreteValueProps>
>;

export const DiscreteValue = ({ label, last }: DiscreteValueProps) => {
  return (
    <Styled.DiscreteValue last={!!last}>
      <Styled.Step>
        <Styled.Label>{label}</Styled.Label>
      </Styled.Step>

      {!last && <Styled.Track />}
    </Styled.DiscreteValue>
  );
};
