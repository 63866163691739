import styled from "styled-components/macro";

export const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;

  ${({ theme }) => theme.media.medium} {
    flex-direction: column;
    gap: 1.5rem;
  }
`;
