import React from "react";
import { useHistory } from "react-router-dom";

const useOnRouteChange = (onChange?: (l: Location) => void) => {
  const { location: initialLocation, listen } = useHistory();

  const [location, setLocation] = React.useState(() => initialLocation);

  const selfRef = React.useRef<Record<string, any>>({});

  React.useEffect(() => {
    selfRef.current.onChange = onChange;
  });

  React.useEffect(() => {
    const unsubscribe = listen((location) => {
      setLocation(location);
      selfRef.current.onChange?.(location);
    });

    return () => {
      unsubscribe();
    };
  }, [listen]);

  return location;
};

export default useOnRouteChange;
