import styled, { css } from "styled-components/macro";

import { renderStyleFromProps } from "global-styles/lib/props";

type Props = {
  gap?: string;
  maxWidth?: string;
  columns?: Array<string>;
  justify?: string;
  align?: string;
  flow?: string;
};

const Grid = styled.div<Props>`
  width: 100%;
  display: grid;

  ${({ columns }) =>
    !!columns!.length &&
    css`
      grid-template-columns: ${columns!.join(" ")};
    `}

  ${renderStyleFromProps("flow", "grid-auto-flow")}
  ${renderStyleFromProps("gap")}
  ${renderStyleFromProps("maxWidth", "max-width")}
  ${renderStyleFromProps("justify", "justify-content")}
  ${renderStyleFromProps("align", "align-itens")}
`;

Grid.defaultProps = {
  columns: [],
  flow: "column",
};

export default Grid;
