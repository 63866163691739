import React from "react";

import { RequestState } from "hooks/useRepository";
import useDeskpassRepository from "hooks/useRepository/deskpass";
import { Country } from "repository/deskpass/types/data";

type CountryContextState = Omit<RequestState<Country>, "data"> & {
  countries: Array<Country>;
};

type Props = React.PropsWithChildren<{}>;

const Context = React.createContext({} as CountryContextState);

export const CountryProvider = ({ children }: Props) => {
  const [{ data, ...rest }] = useDeskpassRepository(
    (repository) => () => repository.country.findAll()
  );

  const context = React.useMemo(
    () => ({
      countries: data || [],
      ...rest,
    }),
    [data, rest]
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default Context;
