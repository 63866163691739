import React from "react";
import debounce from "lodash.debounce";

type Settings = { debounceTime?: number };
type Dimentions = { width: number; height: number };
type UseWindowResize = (s?: Settings) => Dimentions;

const defaultSettings = {
  debounceTime: 100,
};

const useWindowResize: UseWindowResize = (settings) => {
  const { debounceTime } = { ...defaultSettings, ...settings };

  const getDimensions = React.useCallback((target = window) => {
    const width = target.innerWidth || document.documentElement.clientWidth;
    const height = target.innerHeight || document.documentElement.clientHeight;

    return { width, height };
  }, []);

  const [dimensions, setDimensions] = React.useState(() => getDimensions());

  React.useEffect(() => {
    const handler = debounce(() => {
      setDimensions(getDimensions());
    }, debounceTime);

    window.addEventListener("resize", handler);
    return () => {
      window.removeEventListener("resize", handler);
      handler.cancel();
    };
  }, [debounceTime, getDimensions]);

  return dimensions;
};

export default useWindowResize;
