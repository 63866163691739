import React from "react";
import useRepository, { Config, RequestMapper } from "./";

import LocalRepository from "repository/local";

function useLocalRepository<D = any>(
  requestMapper: RequestMapper<LocalRepository, D>,
  config?: Config<D>
) {
  const repository = React.useMemo(() => new LocalRepository(), []);

  return useRepository<LocalRepository, D>(repository, requestMapper, config);
}

export default useLocalRepository;
