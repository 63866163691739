import React from "react";
import Helmet from "react-helmet";
import { useForm } from "react-hook-form";
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from "postcode-validator";

import {
  CardNumber,
  CardExpiry,
  CardCvc,
  useStripeFields,
} from "components/Fields/Stripe";
import Checkbox from "components/Fields/Checkbox";
import Field from "components/Fields/Field";
import Select, { Option } from "components/Fields/Select";
import Grid from "components/Layout/Grid";
import Button from "components/Button";
import DollarSignSvg from "components/Svg/DollarSign";
import ReceiptSvg from "components/Svg/Receipt";
import MonthSvg from "components/Svg/Month";
import SalesLink from "components/Button/SalesLink";

import useLocalRepository from "hooks/useRepository/local";

import SessionContext from "context/session";
import NotificationContext from "context/notification";

import * as patterns from "lib/patterns";
import logger from "lib/log";

import * as StyledPage from "pages/styles";
import * as Styled from "./styles";

type BillingPlan = keyof typeof billingPlanOptionsInfo;

type TeamBillingFields = {
  billingPlan: BillingPlan;
  zip: string;
  invoiceEmail: string;
};

type BillingPlanOptionProps = {
  value: BillingPlan;
};

const billingPlanOptionsInfo = {
  payAsYouGo: {
    header: "Pay as you go plan",
    content: "Reservations will be charged individually",
    Icon: DollarSignSvg,
    color: "#28AFB0",
  },

  invoicedExternally: {
    header: "Invoice Monthly",
    content: "Receive an invoice at the end of the month",
    Icon: ReceiptSvg,
    color: "#6930C3",
  },

  payMonthly: {
    header: "Pay at End of Month",
    content: "A single end-of-month charge for all reservations",
    Icon: MonthSvg,
    color: "#F05F5A",
  },
};

const BillingPlanOption = ({ value }: BillingPlanOptionProps) => {
  const { header, content, Icon, color } = billingPlanOptionsInfo[value];

  return (
    <Styled.BillingPlanOption>
      <Styled.BillingPlanOptionIcon>
        <Styled.BillingPlanOptionIconCircle color={color}>
          <Icon />
        </Styled.BillingPlanOptionIconCircle>
      </Styled.BillingPlanOptionIcon>
      <Styled.BillingPlanOptionContent>
        <Styled.BillingPlanOptionHeader>
          {header}
        </Styled.BillingPlanOptionHeader>
        <Styled.BillingPlanOptionDescription>
          {content}
        </Styled.BillingPlanOptionDescription>
      </Styled.BillingPlanOptionContent>
    </Styled.BillingPlanOption>
  );
};

const BillingPage = () => {
  const {
    updateTeam,
    currentUser,
    currentTeam,
    localLogout,
    loading: processing,
  } = React.useContext(SessionContext);
  const { displayError } = React.useContext(NotificationContext);

  const [{ loading: loadingDashOtp }, getDashOtp] = useLocalRepository(
    (repository) => () => repository.auth.getDashOtp(),
    { fireOnMount: false }
  );

  const { email: managerEmail, firstName: managerName } = currentUser!;
  const { country, name: teamName } = currentTeam!;
  const { code: countryCode } = country;

  const [sendToManagerEmailChecked, setSendToManagerEmailChecked] =
    React.useState(() => false);
  const [redirecting, setRedirecting] = React.useState(() => false);

  const toggleSendInvoiceToManagerEmail = React.useCallback(
    () => setSendToManagerEmailChecked(!sendToManagerEmailChecked),
    [sendToManagerEmailChecked]
  );

  const { register, handleSubmit, formState, watch } =
    useForm<TeamBillingFields>({
      mode: "all",
      shouldUnregister: true,
    });

  const { generateToken, onStripeFieldValid, stripeFieldsValid } =
    useStripeFields();

  const validateZipCode = React.useCallback(
    (zip: string) => {
      if (
        postcodeValidatorExistsForCountry(countryCode) &&
        !postcodeValidator(zip, countryCode)
      ) {
        return `Invalid ${countryCode} zip code`;
      }
    },
    [countryCode]
  );

  const zipFieldProps = register("zip", {
    required: "Zip code is required",
    validate: validateZipCode,
  });

  const billingPlanFieldProps = register("billingPlan");
  const billingPlan = watch("billingPlan", "payAsYouGo");

  const invoiceEmailFieldProps = register("invoiceEmail", {
    required: {
      value: !sendToManagerEmailChecked,
      message: "An email to send invoices to is required.",
    },
    pattern: {
      value: patterns.email,
      message: "A valid email address is required",
    },
  });

  const { errors, touchedFields, isValid: formFieldsValid } = formState;

  const onSubmit = React.useCallback(
    async ({ zip, invoiceEmail, billingPlan }: TeamBillingFields) => {
      try {
        const { token } = await generateToken();

        let extraBillingUpdateFields: { invoiceEmail?: string } = {};

        extraBillingUpdateFields.invoiceEmail = !sendToManagerEmailChecked
          ? invoiceEmail
          : managerEmail;

        let teamFields = {
          zip,
          stripeToken: token?.id,
          billingUpdate: {
            ...extraBillingUpdateFields,
            billingPlan,
          },
          // So the dash won't try to take the user into the setup again
          completeBilling: true,
        };

        await updateTeam(teamFields, "billing");
        const { data: dashAuthRedirectUrl } = await getDashOtp();

        if (!!dashAuthRedirectUrl) {
          setRedirecting(true);
          await localLogout();
          window.location.href = dashAuthRedirectUrl;
        }

        throw new Error("Could not get dash otp redirect URL.");
      } catch (err) {
        displayError(err);
        logger.error("Error submitting Billing page: ", err);
      }
    },
    [
      localLogout,
      updateTeam,
      generateToken,
      sendToManagerEmailChecked,
      managerEmail,
      getDashOtp,
      displayError,
    ]
  );

  const isFieldValid = React.useCallback(
    (fieldName: keyof TeamBillingFields) => {
      const isTouched = touchedFields[fieldName];
      const hasError = !!errors[fieldName]?.message;

      return isTouched && !hasError;
    },
    [touchedFields, errors]
  );

  const processingSubmit = processing || loadingDashOtp || redirecting;
  const disabled = !(formFieldsValid && stripeFieldsValid) || processingSubmit;

  return (
    <StyledPage.Container compact>
      <Helmet>
        <title>Deskpass - Teams Onboarding | Billing Settings</title>
        <meta
          name="description"
          content="Choose billing settings for the team"
        />
      </Helmet>

      <StyledPage.HeaderSection>
        <StyledPage.Header>Enter billing information</StyledPage.Header>

        <StyledPage.Subheader>
          This payment method will be used for all members of your team. They
          will not be able to see the payment information details.
        </StyledPage.Subheader>
      </StyledPage.HeaderSection>

      <StyledPage.Form onSubmit={handleSubmit(onSubmit)}>
        <Select {...billingPlanFieldProps} initialValue="payAsYouGo">
          <Option value="payAsYouGo">
            <BillingPlanOption value="payAsYouGo" />
          </Option>
          {/* For the moment we've decided to hide this plan because we want teams
          to contact us first before using it. */}
          {/* <Option value="invoicedExternally">
            <BillingPlanOption value="invoicedExternally" />
          </Option> */}
          <Option value="payMonthly">
            <BillingPlanOption value="payMonthly" />
          </Option>
        </Select>

        <Styled.TextInfo>
          If you are interested in other payments options such as monthly
          invoicing please <SalesLink name={managerName} teamName={teamName} />.
        </Styled.TextInfo>

        <CardNumber onValid={onStripeFieldValid} />

        <Styled.CardInfoRow>
          <CardCvc onValid={onStripeFieldValid} />
          <CardExpiry onValid={onStripeFieldValid} />

          <Field
            {...zipFieldProps}
            errorMessage={errors.zip?.message}
            valid={isFieldValid("zip")}
            placeholder="Zip code"
          />
        </Styled.CardInfoRow>

        <Grid gap="2rem">
          <Styled.InvoicedMailFields>
            <Checkbox
              onChange={toggleSendInvoiceToManagerEmail}
              checked={sendToManagerEmailChecked}
              label={
                <>
                  Send{" "}
                  {billingPlan === "invoicedExternally"
                    ? "invoices"
                    : "receipts"}
                  <>
                    {" "}
                    to <strong>{managerEmail}</strong>
                  </>
                </>
              }
            />

            {!sendToManagerEmailChecked && (
              <Field
                {...invoiceEmailFieldProps}
                placeholder={`Email to send ${
                  billingPlan === "invoicedExternally" ? "invoice" : "receipt"
                }`}
                errorMessage={errors.invoiceEmail?.message}
                valid={isFieldValid("invoiceEmail")}
                showValidIcon={!!touchedFields.invoiceEmail}
              />
            )}
          </Styled.InvoicedMailFields>
        </Grid>

        <Button type="submit" disabled={disabled} processing={processingSubmit}>
          Submit billing info
        </Button>
      </StyledPage.Form>
    </StyledPage.Container>
  );
};

export default BillingPage;
