import React from "react";
import { useInView } from "react-intersection-observer";

import { makeChildrenRenderer } from "lib/react";

import useEvent from "hooks/useEvent";

import * as Styled from "./styles";

// TODO We may review the typing used in here
export const Slide = makeChildrenRenderer();

export type SlideChild = React.ReactElement<{}, typeof Slide>;

export type CarouselProps = {
  children: SlideChild[] | SlideChild;
};

const transitionDuration = 800; //ms

export const Carousel = ({ children }: CarouselProps) => {
  const [activeIndex, setActiveIndex] = React.useState(() => 0);
  const [mounted, setMounted] = React.useState(() => false);

  const slideListRef = React.useRef<HTMLOListElement>(null);
  const viewportRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (slideListRef.current && viewportRef.current) {
      setMounted(true);
    }
  }, [slideListRef, viewportRef]);

  const handleBtnClick = useEvent((idx: number) => {
    if (slideListRef.current) {
      setActiveIndex(idx);
      const slides = Array.from(slideListRef.current.children);
      slides[idx].scrollIntoView(false);
    }
  });

  let slides = React.Children.map<SlideChild, SlideChild>(
    children,
    (child) => child
  );

  slides = slides.filter((it) => it.type === Slide);

  if (!slides.length) {
    return null;
  }

  return (
    <Styled.Carousel ref={viewportRef}>
      <Styled.SlideList ref={slideListRef}>
        {mounted &&
          slides.map((slideContent, idx) => (
            <CarouselSlide
              key={slideContent.key}
              transitionDuration={transitionDuration}
              viewport={viewportRef.current!}
              index={idx}
              onActive={setActiveIndex}
            >
              {slideContent}
            </CarouselSlide>
          ))}
      </Styled.SlideList>

      <Styled.Navigation>
        <Styled.NavigationList>
          {slides.map((it, idx) => (
            <Styled.NavigationItem key={it.key}>
              <Styled.NavigationButton
                active={idx === activeIndex}
                onClick={() => handleBtnClick(idx)}
              />
            </Styled.NavigationItem>
          ))}
        </Styled.NavigationList>
      </Styled.Navigation>
    </Styled.Carousel>
  );
};

type CarouselSlideProps = {
  transitionDuration: number;
  children: SlideChild;
  viewport: HTMLDivElement;
  index: number;
  onActive: (index: number) => void;
};

const CarouselSlide = React.memo(
  ({
    transitionDuration,
    children,
    viewport,
    index,
    onActive,
  }: CarouselSlideProps) => {
    const { ref } = useInView({
      root: viewport,
      threshold: 0.5,
      onChange: (inView) => {
        if (inView) {
          onActive(index);
        }
      },
    });

    return (
      <Styled.Slide tabIndex={0} ref={ref}>
        {children}
        <Styled.Snapper transitionDuration={transitionDuration} />
      </Styled.Slide>
    );
  }
);

export default Carousel;
