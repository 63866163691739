import styled from "styled-components/macro";

import Img from "components/Image";

export const FeatureBlocks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.blueFaint};

  ${({ theme }) => theme.media.large} {
    flex-direction: row;
    align-items: initial;
  }

  & > * {
    flex: 1;
  }
`;

export const Block = styled.div`
  display: flex;
  width: 100%;
  max-height: 20rem;
  max-width: 80%;

  ${({ theme }) => theme.media.smallMedium} {
    margin: 0 2rem 2rem;
    max-width: 100%;
  }

  ${({ theme }) => theme.media.xsmall} {
    margin: 0 0.4rem 1rem;
    max-width: 100%;
  }
`;

export const Image = styled(Img)`
  position: absolute;
  height: auto;
  width: 100%;
`;

export const ImagePlaceholder = styled.div`
  flex: 2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Content = styled.div`
  flex: 8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 6%;
  line-height: 1.6;

  ${({ theme }) => theme.media.smallMedium} {
    font-size: 1.1rem;
  }
`;

export const Header = styled.h2`
  ${({ theme }) => theme.fontStack.simula};

  margin-bottom: 1rem;
  font-size: 2.2rem;

  ${({ theme }) => theme.media.smallMedium} {
    margin-bottom: 0.4rem;
    font-size: 1.6rem;
  }
`;

export const Description = styled.p``;
