import createColor from "color";
import { Color } from "react-color";

export const rgba = (color: Color, alpha?: number) => {
  if (typeof color === "string") {
    if (!alpha) {
      return color;
    }

    return createColor(color).rgb().alpha(alpha).string();
  }

  const { a, ...rgb } = color;

  return createColor(rgb)
    .rgb()
    .alpha(alpha ?? a!)
    .string();
};
