import React from "react";
import { useMediaQuery } from "react-responsive";

import { breakpoints } from "global-styles/lib/media";

import useDeskpassRepository from "hooks/useRepository/deskpass";

import config from "lib/config";
import hubspot from "lib/hubspot";
import logger from "lib/log";

import SessionContext from "context/session";

type HubspotContextState = {
  open: Function;
  ready: boolean;
};

const Context = React.createContext({} as HubspotContextState);

export const HubspotProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const {
    ready: sessionReady,
    authenticated,
    currentUser,
  } = React.useContext(SessionContext);
  const currentUserEmail = currentUser?.email;

  const [, getHubspotContactToken] = useDeskpassRepository(
    (repository) => () => repository.hubspot.getContactToken(),
    { fireOnMount: false }
  );

  const isMobile = useMediaQuery({ maxWidth: breakpoints.large });

  const instance = React.useRef<Record<string, any>>({});

  const [ready, setReady] = React.useState(() => false);

  React.useEffect(() => {
    instance.current.initialized = false;
  }, []);

  React.useEffect(() => {
    if (config.ACTIVE_CRM !== "hubspot") return;

    (async () => {
      if (sessionReady) {
        let token;

        if (authenticated && !hubspot.getSettings().identificationToken) {
          try {
            token = (await getHubspotContactToken()).data;
          } catch (err) {
            logger.error("Unable to retrieve Hubspot Conversation token:", err);
          }
        }

        hubspot.setSettings({
          loadImmediately: !isMobile,
          identificationEmail: currentUserEmail,
          identificationToken: token,
        });

        if (!instance.current.initialized) {
          instance.current.initialized = true;
          await hubspot.loadScript();
          setReady(true);
        } else {
          hubspot.widget.reload();
        }
      }
    })();
  }, [
    sessionReady,
    authenticated,
    getHubspotContactToken,
    isMobile,
    currentUserEmail,
  ]);

  const context = React.useMemo(
    () => ({
      ready,
      open: hubspot.widget.open,
    }),
    [ready]
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default Context;
