import React from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { breakpoints } from "global-styles/lib/media";

import { Tabs, Tab } from "components/Tabs";

import useOnRouteChange from "hooks/useOnRouteChange";

import SessionContext from "context/session";

import routes, { getRouteKeyByPath, RouteKey, rulesByRoute } from "lib/routes";

type Crumb = {
  index: number;
  name: string;
  simplifiedName?: string;
};

type Crumbs = {
  [crumb in RouteKey]: Crumb;
};

const makeCrumb = ({ name, simplifiedName, ...rest }: Crumb): Crumb => ({
  ...rest,
  name,
  simplifiedName: simplifiedName ?? name,
});

const crumbs: Crumbs = {
  setup: makeCrumb({
    index: 1,
    name: "Setup",
  }),
  reservation: makeCrumb({
    index: 2,
    name: "Reservation Settings",
    simplifiedName: "Reservation",
  }),
  customization: makeCrumb({
    index: 3,
    name: "Customization",
  }),
  team: makeCrumb({
    index: 4,
    name: "Team",
  }),
  billing: makeCrumb({
    index: 5,
    name: "Billing Info",
    simplifiedName: "Billing",
  }),
};

const Breadcrumbs = () => {
  const { push: pushRoute, location } = useHistory();
  const isDesktop = useMediaQuery({ minWidth: breakpoints.xlarge });

  const { authenticated } = React.useContext(SessionContext);

  let initialRouteKey: RouteKey =
    getRouteKeyByPath(location.pathname) ?? "setup";
  const initialRouteRules = rulesByRoute[initialRouteKey];

  // If initial route is not acessible make sure to default to it's fallback
  if (!initialRouteRules.isAccessible(authenticated)) {
    initialRouteKey = getRouteKeyByPath(initialRouteRules.fallback)!;
  }

  const [selectedTabName, setSelectedTabName] = React.useState<RouteKey>(
    () => initialRouteKey
  );

  useOnRouteChange((location) => {
    const routeKey: RouteKey | undefined = getRouteKeyByPath(location.pathname);

    if (!!routeKey) {
      setSelectedTabName(routeKey);
    }
  });

  const onChange = React.useCallback(
    (routeKey: RouteKey) => {
      const routeRules = rulesByRoute[routeKey];

      // Only allow navigation after filling the first step
      // and becoming authenticated
      if (routeRules.isAccessible(authenticated)) {
        setSelectedTabName(routeKey);
        pushRoute(routes[routeKey]);
      }
    },
    [setSelectedTabName, pushRoute, authenticated]
  );

  const routeKeys = (Object.keys(routes) as RouteKey[]).sort(
    (x, y) => crumbs[x].index - crumbs[y].index
  );

  return (
    <Tabs selected={selectedTabName} onChange={onChange}>
      {routeKeys.map((routeKey) => {
        const crumb = crumbs[routeKey];
        const routeRules = rulesByRoute[routeKey];
        const clickable = routeRules.isAccessible(authenticated);

        return (
          <Tab name={routeKey} key={routeKey} clickable={clickable}>
            {isDesktop ? crumb.name : crumb.simplifiedName}
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default Breadcrumbs;
