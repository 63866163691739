import { AxiosInstance } from "axios";

import { DeskpassResponse } from "repository/deskpass/types/meta";
import { TeamManager } from "repository/deskpass/types/data";

const createManagerRepository = (client: AxiosInstance) => {
  return {
    loadSelf: () => {
      return client.get<DeskpassResponse<TeamManager>>(
        "/admin/me?include=country&include=customization"
      );
    },
  };
};

export default createManagerRepository;
