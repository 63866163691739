import React from "react";

import useOnRouteChange from "hooks/useOnRouteChange";

type Props = React.PropsWithChildren<{}>;

declare global {
  interface Window {
    ga: Function;
  }
}

const ga = window.ga;

const AnalyticsContainer = ({ children }: Props) => {
  useOnRouteChange((location) => {
    if (typeof ga !== "function") return;

    ga("set", "page", location.pathname);
    ga("send", "pageview");
  });

  return <>{children}</>;
};

export default AnalyticsContainer;
