import React from "react";
import Helmet from "react-helmet";
import { useTheme } from "styled-components/macro";
import { useHistory } from "react-router-dom";

import Button from "components/Button";
import SkipButton from "components/Button/Skip";
import FileField from "components/Fields/File";

import { Customization } from "repository/deskpass/types/data";

import useEvent from "hooks/useEvent";

import SessionContext from "context/session";
import CustomizationContext from "context/customization";
import NotificationContext from "context/notification";

import * as browserStorage from "lib/browserStorage";
import routes from "lib/routes";
import logger from "lib/log";

import * as StyledPage from "pages/styles";
import * as Styled from "./styles";

const CustomizationPage = browserStorage.withFormSubmittedState(
  "customization"
)(({ submittedState: editMode }) => {
  const { push: pushRoute } = useHistory();
  const { colors } = useTheme();

  const { displayError } = React.useContext(NotificationContext);

  const {
    updateTeam,
    loading: processing,
    currentTeam,
  } = React.useContext(SessionContext);

  const defaultCustomization = currentTeam?.customization ?? {
    primaryColor: colors.primary,
    secondaryColor: colors.accent,
  };

  const [dirty, setDirty] = React.useState<boolean>(() => false);

  const { customization, updateCustomization } =
    React.useContext(CustomizationContext);

  const setCustomizationValue = useEvent(
    (propName: keyof Customization, newValue?: string) => {
      const nextState = { [propName]: newValue };

      if (editMode) {
        setDirty(defaultCustomization[propName] !== newValue);
      }

      updateCustomization(nextState);
    }
  );

  const updateCompanyLogo = useEvent((value: string) =>
    setCustomizationValue("companyLogo", value)
  );

  const updateSimplifiedLogo = useEvent((value: string) =>
    setCustomizationValue("simplifiedLogo", value)
  );

  const updatePrimaryColor = useEvent((value: string) =>
    setCustomizationValue("primaryColor", value)
  );

  const updateSecondaryColor = useEvent((value: string) =>
    setCustomizationValue("secondaryColor", value)
  );

  const onPrimaryColorReset = useEvent(() =>
    setCustomizationValue("primaryColor", defaultCustomization.primaryColor)
  );

  const onSecondaryColorReset = useEvent(() =>
    setCustomizationValue("secondaryColor", defaultCustomization.secondaryColor)
  );

  const onSubmit = useEvent(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await updateTeam(
        { customization },
        editMode ? "updateCustomization" : "customization"
      );

      await browserStorage.setFormSubmittedState("customization", true);

      pushRoute(routes.team);
    } catch (err) {
      displayError(err);
      logger.error("Error submitting Customization page: ", err);
    }
  });

  const disabled = processing || (editMode && !dirty);

  return (
    <StyledPage.Container>
      <Helmet>
        <title>Deskpass - Teams Onboarding | Customization Settings</title>
        <meta
          name="description"
          content="Choose customization settings for the team account"
        />
      </Helmet>

      <StyledPage.HeaderSection>
        <StyledPage.Header>Now let’s make this house a home</StyledPage.Header>

        <StyledPage.Subheader>
          Customize your account dashboard:
        </StyledPage.Subheader>
      </StyledPage.HeaderSection>

      <StyledPage.Form onSubmit={onSubmit}>
        <Styled.LogoRow>
          <Styled.LogoField>
            <Styled.LogoLabel
              title="Header Logo"
              dimensions="150x50px"
              description="To be used in the header of the dashboard and mobile app."
            />

            <FileField
              width="120px"
              src={customization.companyLogo}
              onUpload={updateCompanyLogo}
            />
          </Styled.LogoField>

          <Styled.LogoField>
            <Styled.LogoLabel
              title="Simplified Logo"
              dimensions="50x50px"
              description="To be used as your team avatar in menus and map interfaces."
            />

            <FileField
              src={customization.simplifiedLogo}
              onUpload={updateSimplifiedLogo}
            />
          </Styled.LogoField>
        </Styled.LogoRow>

        <StyledPage.Subheader>Now choose theme colors</StyledPage.Subheader>

        <Styled.ColorField
          defaultColor={defaultCustomization.primaryColor}
          color={customization.primaryColor}
          onColorPick={updatePrimaryColor}
        >
          <Styled.ColorFieldLabel
            type="Primary"
            description="Buttons and links"
            onReset={onPrimaryColorReset}
          />
        </Styled.ColorField>

        <Styled.ColorField
          defaultColor={colors.accent}
          color={customization.secondaryColor}
          onColorPick={updateSecondaryColor}
        >
          <Styled.ColorFieldLabel
            type="Secondary"
            description="Background shades"
            onReset={onSecondaryColorReset}
          />
        </Styled.ColorField>

        <StyledPage.FooterSection padded="6rem">
          <Button processing={processing} disabled={disabled} type="submit">
            {editMode ? "Update" : "Save"} team customization
          </Button>

          <SkipButton to={routes.team} />
        </StyledPage.FooterSection>
      </StyledPage.Form>
    </StyledPage.Container>
  );
});

export default CustomizationPage;
