import React from "react";
import { useDropzone } from "react-dropzone";

import { LoadingSpinner } from "components/ProgressIndicator";
import TextButton from "components/Button/Text";

import useDeskpassRepository from "hooks/useRepository/deskpass";
import useEvent from "hooks/useEvent";

import SessionContext from "context/session";

import * as Styled from "./styles";

// Accepted file settings
const MAX_SIZE = 4 * 1024 * 1024; // 4MB
const VALID_IMAGE_TYPES = ["image/jpeg", "image/png", "image/svg+xml"].join(
  ","
);

type FileFieldProps = {
  width?: string;
  height?: string;
  alt?: string;
  onUpload?: (imageUrl: string) => void;
  src?: string;
};

const FileField = ({ src, alt, width, height, onUpload }: FileFieldProps) => {
  const { currentTeam } = React.useContext(SessionContext);

  const [{ ready, loading, data: imageUrl }, uploadLogo] =
    useDeskpassRepository(
      (repository) => (id, slug, file) =>
        repository.team.uploadLogo(id, slug, file),
      {
        fireOnMount: false,
        initialValue: src,
      }
    );

  const { id: teamId, slug: teamSlug } = currentTeam!;

  const onDropAccepted = useEvent(async ([file]: Array<File>) => {
    const { data: imageUrl } = await uploadLogo(teamId, teamSlug, file);
    onUpload!(imageUrl);
  });

  const onDropRejected = useEvent(() => {
    // TODO handle rejection
  });

  const { getRootProps, getInputProps, open } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: VALID_IMAGE_TYPES,
    maxFiles: 1,
    maxSize: MAX_SIZE,
  });

  const renderInner = (ready && !loading) || loading;

  return (
    <Styled.FileField>
      <Styled.DropArea {...getRootProps()} width={width} height={height}>
        <Styled.Borders>
          {!ready && <Styled.CrossedBackground blurred={loading} />}

          <input {...getInputProps()} />
        </Styled.Borders>

        {renderInner && (
          <Styled.Inner>
            {loading && <LoadingSpinner inline small />}

            {ready && !loading && <Styled.Image src={imageUrl} alt={alt} />}
          </Styled.Inner>
        )}
      </Styled.DropArea>

      <TextButton tabIndex={-1} onClick={open}>
        Upload...
      </TextButton>
    </Styled.FileField>
  );
};

FileField.defaultProps = {
  width: "60px",
  height: "60px",
  alt: "Uloaded image",
  onUpload: () => null,
};

export default FileField;
