import React from "react";
import styled from "styled-components/macro";
import { useMediaQuery } from "react-responsive";

import SupportLink from "components/Button/SupportLink";

import { breakpoints } from "global-styles/lib/media";

const year = new Date().getFullYear();

const FooterText = styled.div`
  margin-bottom: 0.3rem;
`;

const Footer = () => {
  const isSmall = useMediaQuery({ maxWidth: breakpoints.small });

  return (
    <>
      <FooterText>
        © {year} Deskpass Corporation. {isSmall && <br />}
        All Rights Reserved.
      </FooterText>

      <SupportLink />
    </>
  );
};

export default Footer;
