import debounce from "lodash.debounce";

type DomNodes = {
  handleNode: HTMLDivElement;
  parentNode: HTMLDivElement;
};

type Actions = {
  onChange: (value: number) => void;
  setOffset: (offset: number) => void;
  setDragging: (dragging: boolean) => void;
};

export function setupDragging(
  domNodes: DomNodes,
  actions: Actions,
  values: Array<number>,
  initialValue?: number
) {
  const { handleNode, parentNode } = domNodes;
  const { onChange, setOffset, setDragging } = actions;

  const getHandleWidth = () => handleNode.offsetWidth;
  const getHandleMiddle = () => getHandleWidth() / 2;
  const getHandleOffset = () => handleNode.offsetLeft;
  const getParentOffset = () => parentNode.offsetLeft;
  const getParentWidth = () => parentNode.offsetWidth;

  const getMinOffset = () => (handleNode.offsetWidth / 2) * -1;
  const getMaxOffset = () => parentNode.offsetWidth + handleNode.offsetWidth;

  const getOffsetForX = (x: number) =>
    x - getParentOffset() - getHandleMiddle();

  const getClosestIndex = (currentX?: number) => {
    const offset = currentX ? getOffsetForX(currentX) : getHandleOffset();

    const offsetPercentage =
      ((offset + getHandleWidth()) / getMaxOffset()) * 100;

    return Math.round(offsetPercentage / (100 / (values.length - 1)));
  };

  const goToIndex = (index: number) => {
    const offset =
      (getParentWidth() / (values.length - 1)) * index - getHandleMiddle();

    setOffset(offset);
  };

  const goToValue = (value?: number) => {
    goToIndex(values.indexOf(value ?? 0));
  };

  const snapToNearestValue = (currentX?: number) => {
    let index = getClosestIndex(currentX);

    goToIndex(index);

    return index;
  };

  // Sets initial position
  goToValue(initialValue);

  const goToX = debounce((currentX: number) => {
    const offset = getOffsetForX(currentX);

    if (offset >= getMinOffset() && offset <= getParentWidth()) {
      setOffset(offset);
    }
  }, 5);

  const onTounchMove = (event: TouchEvent) => {
    goToX(event.touches[0].clientX);
  };

  const onMouseMove = (event: MouseEvent) => {
    goToX(event.clientX);
  };

  const onUp = (event: Event) => {
    _stopPropagation(event);

    const index = snapToNearestValue();
    onChange(values[index]);

    setDragging(false);

    window.removeEventListener("mousemove", onMouseMove);
    window.removeEventListener("touchmove", onTounchMove);
    window.removeEventListener("mouseup", onUp);
    window.removeEventListener("touchend", onUp);
  };

  const onDown = () => {
    setDragging(true);

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("touchmove", onTounchMove);
    window.addEventListener("mouseup", onUp);
    window.addEventListener("touchend", onUp);
  };

  const register = () => {
    handleNode.addEventListener("mousedown", onDown);
    handleNode.addEventListener("touchstart", onDown);
  };

  const dispose = () => {
    handleNode.removeEventListener("mousedown", onDown);
    handleNode.removeEventListener("touchstart", onDown);
  };

  return {
    register,
    dispose,
    snapToNearestValue,
    goToIndex,
    goToValue,
    goToX,
  };
}

function _stopPropagation(e: Event) {
  e.stopPropagation();
}
