import styled, { css } from "styled-components/macro";

import { scaleWithVW } from "global-styles/lib/scaling";
import { rgba } from "global-styles/lib/colors";

import { LoadingSpinner } from "components/ProgressIndicator";
import TextButton from "components/Button/Text";

export const LogoutButton = styled(TextButton)`
  ${({ theme, disabled }) =>
    !disabled &&
    css`
      color: ${rgba(theme.colors.text, 0.5)};
    `}
`;

const DesktopPage = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const DesktopMainSection = styled.main`
  overflow-y: auto;
  flex: 5;
  padding: 0 5%;
  display: grid;
  grid-template-rows: [header] max-content [main] auto [footer] max-content;
  grid-template-areas:
    "header"
    "main"
    "footer";
`;

const DesktopMainContent = styled.main``;

const DesktopFooter = styled.footer`
  margin: 1rem 0;
  align-self: self-end;
  line-height: 1.5;
  color: ${({ theme }) => rgba(theme.colors.text, 0.5)};
`;

const DesktopHeader = styled.header`
  align-self: self-start;
  margin: 1rem 0;
`;

const DesktopAccentSection = styled.aside`
  flex: 4;
`;

const DesktopBreadcrumbs = styled.nav`
  margin-bottom: 3rem;
`;

const DesktopLogo = styled.div`
  height: 4rem;
  width: 17rem;
`;

const DesktopLogoArea = styled.div`
  display: flex;
  align-items: end;
  margin-bottom: 2rem;
  padding: 2rem 0;
`;

const StyledDesktopCurrentSessionInfo = styled.div`
  color: ${({ theme }) => rgba(theme.colors.text, 0.5)};
  margin-bottom: 0.5rem;
  display: flex;
  gap: 0.6rem;
  align-items: center;
  white-space: nowrap;
`;

const Spinner = styled(LoadingSpinner)`
  margin: 0 1rem;
`;

type CurrentSessionInfoProps = {
  email: string;
  name: string;
  onClick: () => void;
  disabled: boolean;
};

const DesktopCurrentSessionInfo = ({
  email,
  name,
  onClick,
  disabled,
}: CurrentSessionInfoProps) => {
  return (
    <StyledDesktopCurrentSessionInfo>
      <span>
        {email} ({name}){" "}
      </span>

      <LogoutButton onClick={onClick} disabled={disabled}>
        Logout
      </LogoutButton>

      {disabled && <Spinner inline small />}
    </StyledDesktopCurrentSessionInfo>
  );
};

export const Desktop = {
  Page: DesktopPage,
  Main: DesktopMainSection,
  Accent: DesktopAccentSection,
  Header: DesktopHeader,
  MainContent: DesktopMainContent,
  Footer: DesktopFooter,
  Breadcrumbs: DesktopBreadcrumbs,
  Logo: DesktopLogo,
  LogoArea: DesktopLogoArea,
  CurrentSessionInfo: DesktopCurrentSessionInfo,
};

const MobilePage = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  display: grid;
  grid-template-areas:
    "header"
    "accent"
    "main"
    "footer";
  grid-template-rows:
    [header] max-content
    [accent] minmax(25rem, 20%)
    [main] auto
    [footer] max-content;

  ${({ theme }) => theme.media.smallMedium} {
    grid-template-rows:
      [header] max-content
      [accent] minmax(20rem, 20%)
      [main] auto
      [footer] max-content;
  }
`;

const MobileHeader = styled.header`
  display: flex;
  flex-direction: column;
`;

const MobileAccentSection = styled.aside`
  align-self: stretch;
  overflow: hidden;
`;

const horizontalSpacing = css`
  margin-left: 5%;
  margin-right: 5%;

  ${({ theme }) => theme.media.small} {
    margin-left: ${scaleWithVW("2rem")};
    margin-right: ${scaleWithVW("2rem")};
  }
`;

const MobileMainSction = styled.main`
  ${horizontalSpacing}
`;

const MobileFooter = styled(DesktopFooter)`
  ${horizontalSpacing}

  font-size: 1.3rem;
  white-space: nowrap;

  ${({ theme }) => theme.media.small} {
    white-space: break-spaces;
  }
`;

const MobileBreadcrumbs = styled.nav`
  display: flex;
  justify-content: center;
`;

const MobileLogo = styled.div`
  height: 3rem;
  width: 12rem;
  margin-bottom: 2rem;
`;

const MobileLogoArea = styled.div`
  display: flex;
  align-items: start;
  padding: 2rem 2.4rem;
  flex-direction: column;
`;

const StyledMobileCurrentSessionInfo = styled.div`
  color: ${({ theme }) => rgba(theme.colors.text, 0.5)};
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${({ theme }) => theme.media.small} {
    font-size: 0.9em;
    gap: 0.7rem;
  }
`;

const SessionInfoAreaFirstRow = styled.div`
  display: flex;
  gap: 1rem;
  white-space: nowrap;
`;

const MobileCurrentSessionInfo = ({
  email,
  name,
  onClick,
  disabled,
}: CurrentSessionInfoProps) => {
  return (
    <StyledMobileCurrentSessionInfo>
      <SessionInfoAreaFirstRow>
        <span>({name})</span>

        <LogoutButton onClick={onClick} disabled={disabled}>
          Logout
        </LogoutButton>

        {disabled && <Spinner inline small />}
      </SessionInfoAreaFirstRow>

      <span>{email}</span>
    </StyledMobileCurrentSessionInfo>
  );
};

export const Mobile = {
  Page: MobilePage,
  Main: MobileMainSction,
  Accent: MobileAccentSection,
  Header: MobileHeader,
  Footer: MobileFooter,
  Breadcrumbs: MobileBreadcrumbs,
  Logo: MobileLogo,
  LogoArea: MobileLogoArea,
  CurrentSessionInfo: MobileCurrentSessionInfo,
};

export const FooterText = styled.div`
  margin-bottom: 0.3rem;
`;
