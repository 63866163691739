import React from "react";
import { Transition } from "react-transition-group";

import NotificationContext from "context/notification";

import Notification from "components/Notifications/Notification";

import * as Styled from "./styles";

const animationDuration = 500;

const NotificationCentral = () => {
  const { notifications } = React.useContext(NotificationContext);

  return (
    <Styled.NotificationTransitionGroup>
      {notifications.map((notification) => {
        // Temporary fix for react-transition-group warning about findDomNode usage:
        // https://github.com/reactjs/react-transition-group/issues/668#issuecomment-695162879
        const ref = React.createRef<HTMLDivElement>();
        return (
          <Transition
            nodeRef={ref}
            key={notification.id}
            timeout={animationDuration}
          >
            {(state) => (
              <Styled.Animation state={state} duration={animationDuration}>
                <Notification {...notification} />
              </Styled.Animation>
            )}
          </Transition>
        );
      })}
    </Styled.NotificationTransitionGroup>
  );
};

export default NotificationCentral;
