import React from "react";
import styled, { useTheme } from "styled-components/macro";
import { TransitionGroup } from "react-transition-group";

import { rgba } from "global-styles/lib/colors";

import Button from "components/Button";
import Close from "components/Svg/Close";

export const AddSection = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: start;
  gap: 2rem;

  ${({ theme }) => theme.media.medium} {
    margin-top: 1rem;
  }
`;

type AnimationProps = {
  state: string;
};

export const InviteMemberTransitionGroup = styled(TransitionGroup)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InviteFieldGroup = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 3fr 3fr 5fr 6rem;

  ${({ theme }) => theme.media.medium} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "firstName lastName"
      "email email";

    & > *:nth-child(1) {
      grid-area: firstName;
    }
    & > *:nth-child(2) {
      grid-area: lastName;
    }
    & > *:nth-child(3) {
      grid-area: email;
    }
  }
`;

export const FadeContainer = styled.div<AnimationProps>`
  transition: opacity 300ms ease-in-out;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
`;

export const InviteFieldGroupContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const RoundButton = styled(Button)`
  border-radius: 100%;
  width: 5rem;

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: ${({ theme }) => theme.colors.rule};
    outline-color: ${({ theme }) => rgba(theme.colors.text, 0.2)};

    &:focus-within {
      transform: translateY(-2px);
    }

    &:focus-within,
    &:hover {
      background: ${({ theme }) => rgba(theme.colors.rule, 0.15)};
    }
  }

  ${({ theme }) => theme.media.medium} {
    position: absolute;
    right: 0;
    height: 3rem;
    width: 3rem;
    top: 1rem;

    & > button {
      height: 3rem;
      width: 3rem;
    }
  }
`;

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {};

export const CloseButton = (props: ButtonProps) => {
  const { colors } = useTheme();

  return (
    <RoundButton type="button" {...props}>
      <Close color={colors.text} />
    </RoundButton>
  );
};

export const Note = styled.p`
  color: ${({ theme }) => rgba(theme.colors.text, 0.6)};
  margin-top: 1rem;
  line-height: 1.4;
  max-width: 42rem;
`;

export const InviteHeader = styled.h3`
  margin-top: 2rem;
  font-weight: bold;
  font-size: 1.6rem;
`;

export const MaxInvites = styled.span`
  color: ${({ theme }) => rgba(theme.colors.red, 0.8)};
`;
