import { EffectCallback, useEffect, useRef } from "react";

const useMount = (effect: EffectCallback) => {
  const intance = useRef({
    mounted: false,
    effect,
  });

  useEffect(() => {
    if (!intance.current.mounted) {
      intance.current.mounted = true;

      return intance.current.effect();
    }
  }, [intance]);
};

export default useMount;
