import React from "react";
import { SketchPicker as Picker, ColorResult, Color } from "react-color";
import { useTheme } from "styled-components/macro";
import { v4 as uuid } from "uuid";

import { rgba } from "global-styles/lib/colors";

import useOutsideClick from "hooks/useOutsideClick";
import useEventListener from "hooks/useEventListener";
import useEvent from "hooks/useEvent";

import * as Styled from "./styles";

type Props = React.PropsWithChildren<{
  id?: string;
  color?: string;
  defaultColor?: string;
  className?: string;
  onColorPick?: (color: string) => void;
}>;

const ColorPickerField = ({
  children,
  id,
  color,
  defaultColor,
  onColorPick,
  className,
}: Props) => {
  const { colors } = useTheme();

  const popoverRef = React.useRef<HTMLDivElement>(null);

  const [popoverOpen, setPopoverOpen] = React.useState<boolean>(() => false);
  const [currentColor, setCurrentColor] = React.useState<Color>(
    () => defaultColor ?? color ?? colors.white
  );

  const getDefaultColor = useEvent(() => defaultColor!);

  React.useEffect(() => {
    setCurrentColor(!!color ? color : getDefaultColor());
  }, [getDefaultColor, color]);

  const onColorChange = useEvent(({ rgb }: ColorResult) =>
    setCurrentColor(rgb)
  );

  const onPopoverClose = useEvent(() => {
    if (popoverOpen) {
      onColorPick?.(rgba(currentColor));
      setPopoverOpen(false);
    }
  });

  const onPopoverOpen = useEvent(() => {
    if (!popoverOpen) {
      setPopoverOpen(true);
    }
  });

  const pickerId = React.useMemo(() => (!!id ? id : uuid()), [id]);

  useOutsideClick<HTMLDivElement>(popoverRef, () => {
    if (popoverOpen) {
      onPopoverClose();
    }
  });

  useEventListener("keydown", (e: Event) => {
    if (popoverOpen) {
      const { keyCode } = e as KeyboardEvent;

      const pressedCloserKey = [
        27, // Escape
        13, // Enter
      ].includes(keyCode);

      if (pressedCloserKey) {
        onPopoverClose();
      }
    }
  });

  return (
    <Styled.ColorPicker className={className}>
      <Styled.Button
        id={pickerId}
        onClick={onPopoverOpen}
        style={{ background: rgba(currentColor) }}
      />
      <Styled.Label htmlFor={pickerId}>{children}</Styled.Label>

      {popoverOpen && (
        <Styled.Popover ref={popoverRef}>
          <Picker color={currentColor} onChange={onColorChange} />
        </Styled.Popover>
      )}
    </Styled.ColorPicker>
  );
};

ColorPickerField.defaultProps = {
  defaultColor: "fff",
};

export default ColorPickerField;
