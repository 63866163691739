import React from "react";
// import * as logRocket from "logrocket";
import Tracker from "@openreplay/tracker";

import config from "lib/config";

import SessionContext from "context/session";

declare global {
  interface Window {
    CLIENT_IP: string;
    __OPENREPLAY__?: Tracker;
    sessionReplayKey: string;
  }
}

let sessionTracker: Tracker | null = null;

(async () => {
  if (!config.OPENREPLAY_PROJECT_KEY) {
    console.warn(
      "Not setting up session replay: required config var is not set"
    );

    return;
  }
  // Don't bother setting up OpenReplay in headless browsers (these will come up
  // a lot because of our own Rendertron server)
  if (window.navigator.userAgent.includes("HeadlessChrome")) {
    console.debug("Skipping session replay setup in headless browser");

    return;
  }

  if (window.__OPENREPLAY__) {
    // This is where OR stores the tracker object if it's already been
    // initialized
    // In theory this scenario should only ever come up in dev due to
    // hot reloading
    console.debug("OpenReplay already initialized, skipping setup...");

    sessionTracker = window.__OPENREPLAY__;
  } else if (!sessionTracker) {
    // Set up fresh capture session if it hasn't been established yet
    console.debug("Setting up session capture...");

    sessionTracker = new Tracker({
      projectKey: config.OPENREPLAY_PROJECT_KEY,
      ingestPoint: "https://sr.deskpass.com/ingest",
      __DISABLE_SECURE_MODE:
        config.ENVIRONMENT === "development" ||
        ["localhost", "deskpass-team-onboarding"].includes(
          window.location.hostname
        ),
      // @ts-expect-error - The typing is wrong here, defaults for every
      // network option are not necessary
      network: {
        capturePayload: true,
        sanitizer: (data) => {
          // Delete CSP header because it's super long and we don't really
          // need to see it
          delete data.response.headers["content-security-policy"];

          // If request body is a string attempt to parse it as JSON so we
          // can sanitize it as needed
          if (data.request.body) {
            try {
              const requestBody = JSON.parse(data.request.body);

              if (requestBody && typeof requestBody === "object") {
                // Delete any sensitive data from the request body
                delete requestBody.email;
                delete requestBody.username;
                delete requestBody.password;
                delete requestBody.currentPassword;
                delete requestBody.newPassword;

                data.request.body = JSON.stringify(requestBody);
              }
            } catch (err) {
              // Do nothing
            }
          }

          // Same story with the response body except we don't need to parse
          // it since it will already be an object
          if (data.response.body && typeof data.response.body === "object") {
            // Delete any sensitive data from the response body
            delete data.response.body.email;
            delete data.response.body.username;
            delete data.response.body.password;
            delete data.response.body.currentPassword;
            delete data.response.body.newPassword;
          }

          return data;
        },
      },
    });

    try {
      await sessionTracker.start({
        metadata: {
          ip: window.CLIENT_IP,
        },
      });

      console.info("Successfully created new capture session");
    } catch (err) {
      console.error("Error starting session capture:", err);
    }
  }
})();

let logger = console;

// type LogRocket = typeof logRocket;

// let logger: Console | LogRocket = console;
// let logrocketActive = false;

// if (!logRocket || config.ENVIRONMENT === "development") {
//   logger = console;
// } else {
//   logger = logRocket;
//   logrocketActive = true;
//   logger.init("z1ihio/team-onboarding");
// }

export const LoggerContainer = ({ children }: React.PropsWithChildren<{}>) => {
  const { authenticated, currentUser } = React.useContext(SessionContext);
  const id = currentUser?.id as string;
  const email = currentUser?.email as string;

  React.useEffect(() => {
    if (sessionTracker && email) {
      console.info("Setting user ID for session capture:", email);

      sessionTracker.setUserID(email);
      sessionTracker.setMetadata("ip", window.CLIENT_IP);
      sessionTracker.setMetadata("id", String(id));
      sessionTracker.setMetadata("userType", "teamManager");
    }
    // if (logrocketActive && authenticated) {
    //   (logger as LogRocket).identify(id, { email });
    // }
  }, [authenticated, id, email]);

  return <>{children}</>;
};

export default logger;
