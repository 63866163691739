import React from "react";
import styled, { css } from "styled-components/macro";
import config from "lib/config";

import { rgba } from "global-styles/lib/colors";

import Img from "components/Image";

import CustomizationContext from "context/customization";

const flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Gradient = styled.div<{ primary: string; accent: string }>`
  ${flexCenter}

  width: 100%;
  height: 100%;

  ${({ primary, accent }) => css`
    background-image: linear-gradient(${primary}, ${accent});

    ${({ theme }) => theme.media.large} {
      background-image: linear-gradient(to right, ${primary}, ${accent});
    }
  `}
`;

const LogoPlaceholder = styled.div`
  ${flexCenter}

  box-shadow: 0 2px 5px ${({ theme }) => rgba(theme.colors.text, 0.12)};
  overflow: hidden;
  background: white;
  border-radius: 50%;
  padding: 1.5rem;
  height: 15rem;
  width: 15rem;

  ${({ theme }) => theme.media.large} {
    height: 13rem;
    width: 13rem;
  }

  ${({ theme }) => theme.media.smallMedium} {
    height: 10rem;
    width: 10rem;
  }
`;

const Image = styled(Img)`
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: auto;
`;

const fallbackLogo = `https://imagedelivery.net/${config.CLOUDFLARE_IMAGES_INSTANCE_ID}/deskpass-logo/default`;

const CustomizationGradient = () => {
  const { customization } = React.useContext(CustomizationContext);
  const src = customization.companyLogo ?? fallbackLogo;

  return (
    <Gradient
      primary={customization.primaryColor!}
      accent={customization.secondaryColor!}
    >
      <LogoPlaceholder>
        <Image alt="Company Logo" src={src} />
      </LogoPlaceholder>
    </Gradient>
  );
};

export default CustomizationGradient;
