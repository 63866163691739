import React from "react";
import mergeRefs from "react-merge-refs";

import Checkbox from "components/Fields/Checkbox";

import useEventListener from "hooks/useEventListener";

import * as Styled from "./styles";

type IconCheckboxProps = {
  icon: React.ReactNode;
  label: string;
  checked?: boolean;
  row?: boolean;
  name: string;
  onChange: (event: React.ChangeEvent) => void;
  defaultChecked?: boolean;
};

const IconCheckbox = React.forwardRef<HTMLInputElement, IconCheckboxProps>(
  ({ label, icon, checked, onChange, row, ...props }, ref) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const wrapperRef = React.useRef<HTMLDivElement>(null);

    const isChecked = !!checked;
    const [value, setValue] = React.useState<boolean>(() => isChecked);

    React.useEffect(() => {
      setValue(isChecked);
    }, [isChecked]);

    React.useEffect(() => {
      if (inputRef?.current?.checked) {
        setValue(true);
      }
    }, []);

    const checkItem = React.useCallback(() => {
      const nextValue = !value;

      setValue(nextValue);

      // Keep it compatible with react-form-hook
      inputRef.current!.checked = nextValue;
      const fakeEvent = {
        target: inputRef.current,
      } as React.ChangeEvent<HTMLInputElement>;

      onChange(fakeEvent);
    }, [value, onChange]);

    const handleChange = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(!value);
        onChange(e);
      },
      [onChange, value]
    );

    useEventListener("keydown", (e: Event) => {
      const { keyCode } = e as KeyboardEvent;

      const focused = document.activeElement === wrapperRef.current;
      const validKeyPressed = [
        13, // Enter
        32, // Space
      ].includes(keyCode);

      // It toggles on space and enter press
      if (focused && validKeyPressed) {
        checkItem();
      }
    });

    return (
      <Styled.IconCheckbox
        ref={wrapperRef}
        onClick={checkItem}
        tabIndex={0}
        checked={value}
        row={!!row}
      >
        {icon}

        <Checkbox
          {...props}
          tabIndex={-1}
          ref={mergeRefs([inputRef, ref])}
          onChange={handleChange}
          checked={value}
          label={label}
        />
      </Styled.IconCheckbox>
    );
  }
);

IconCheckbox.defaultProps = {
  row: false,
};

export default IconCheckbox;
