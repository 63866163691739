import React from "react";

interface ChildRenderer<T = {}> extends React.FC<React.PropsWithChildren<T>> {}
type MakeChildRenderer = <T>() => ChildRenderer<T>;

export const makeChildrenRenderer: MakeChildRenderer =
  () =>
  ({ children }) =>
    <>{children}</>;

type ComposeProps = React.PropsWithChildren<{
  components: Array<React.ComponentType<any>>;
}>;

/*
 * Renders list of components passed to
 * the "components" prop always rendering
 * the next component as the "children" of
 * the previous one and lastly rendering the
 * prop "children" as the last child of them all.
 */
export const Compose = ({ components = [], children }: ComposeProps) => {
  return (
    <>
      {components.reduceRight(
        (currentChild, NextComponent) => (
          <NextComponent>{currentChild}</NextComponent>
        ),
        children
      )}
    </>
  );
};
