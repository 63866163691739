import React from "react";
import useRepository, {
  Config,
  RequestMapper,
  RequestState,
  RequestCaller,
} from "./";

import DeskpassRepository from "repository/deskpass";
import {
  DeskpassResponse,
  DeskpassError,
} from "repository/deskpass/types/meta";

type DataMapper<D> = (response: any) => DeskpassResponse<D>["result"];
type ErrorMapper<E> = (response: any) => DeskpassResponse<E>["error"];

type DeskpassResponseData<D, E> =
  | DeskpassResponse<D>["result"]
  | DeskpassResponse<E>["error"];
type DeskpassRequestState<D, E> = RequestState<
  DeskpassResponse<D>["result"],
  DeskpassResponse<E>["error"]
>;

type DeskpassRequestMapper<D, E> = RequestMapper<
  DeskpassRepository,
  DeskpassResponseData<D, E>
>;

function useDeskpassRepository<D = any, E = any>(
  requestMapper: DeskpassRequestMapper<D, E>,
  _config?: Config
): [DeskpassRequestState<D, E>, RequestCaller<DeskpassRequestState<D, E>>] {
  const dataMapper: DataMapper<D> = React.useCallback(
    ({ result } = {}) => result,
    []
  );
  const errorMapper: ErrorMapper<DeskpassError> = React.useCallback(
    (error) => error,
    []
  );

  const repository = React.useMemo(() => new DeskpassRepository(), []);

  const config = !_config?.initialValue
    ? _config
    : {
        ..._config,
        initialValue: {
          result: _config.initialValue,
        },
      };

  const [requestState, requestCaller] = useRepository<
    DeskpassRepository,
    DeskpassResponse<D>["result"],
    DeskpassResponse<E>["error"]
  >(repository, requestMapper, config);

  const makeRequestState = React.useCallback(
    (newState: DeskpassRequestState<D, E>) => {
      return {
        ...(newState ?? {}),
        data: dataMapper(newState.data),
        error: errorMapper(newState.error),
      };
    },
    [dataMapper, errorMapper]
  );

  const state = React.useMemo(
    () => makeRequestState(requestState),
    [requestState, makeRequestState]
  );

  const request = React.useCallback(
    async (...args: any[]) => {
      try {
        const response = await requestCaller(...args);
        return makeRequestState(response);
      } catch (error) {
        throw errorMapper(error);
      }
    },
    [requestCaller, makeRequestState, errorMapper]
  );

  return [state, request];
}

export default useDeskpassRepository;
