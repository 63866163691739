import React from "react";
import { useTheme } from "styled-components/macro";
import {
  CardNumberElement,
  useElements,
  useStripe,
  // Types
  CardNumberElementProps,
  CardExpiryElementProps,
  CardCvcElementProps,
} from "@stripe/react-stripe-js";
import {
  StripeCardNumberElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardCvcElementChangeEvent,
} from "@stripe/stripe-js";

import { fontFamilyFallbacks } from "global-styles/Fonts";
import { rgba } from "global-styles/lib/colors";

import useEvent from "hooks/useEvent";

import * as Styled from "./styles";

const useElementDefaultOptions = () => {
  const theme = useTheme();
  // TODO Stripe iframe is blocking http requests to localhost
  // so we are not able to load the fonts from CRA on dev mode
  const fontFamily = fontFamilyFallbacks(theme.fonts.grotesque.family);

  return {
    style: {
      base: {
        fontFamily,
        fontSize: "15px",
        color: theme.colors.text,
        letterSpacing: "0.025em",
        "::placeholder": {
          color: rgba(theme.colors.text, 0.3),
        },
      },
      invalid: {
        color: theme.colors.red,
      },
    },
  };
};

type StripeCardElements = (
  | CardNumberElementProps
  | CardExpiryElementProps
  | CardCvcElementProps
) & { visible?: boolean };

type StripeChildElement = React.ReactElement<
  StripeCardElements,
  React.FC<StripeCardElements>
>;

type StripeCardEvents =
  | StripeCardNumberElementChangeEvent
  | StripeCardExpiryElementChangeEvent
  | StripeCardCvcElementChangeEvent;

type StripeFieldProps = {
  children: StripeChildElement;
  leftSpacing?: boolean;
  onValid?: (value: boolean, name: string) => void;
  name: string;
};

type HandlerName = "onBlur" | "onFocus" | "onChange";

const StripeField = ({ children, onValid, name }: StripeFieldProps) => {
  const [error, setError] = React.useState<string | null>(() => null);
  const [focused, setFocused] = React.useState<boolean>(() => false);
  const [ready, setReady] = React.useState<boolean>(() => false);

  const makeEventHandler = (name: HandlerName, cb: Function = () => null) => ({
    [name]: (arg: any) => {
      const handler = children.props[name];

      if (typeof handler === "function") {
        handler(arg);
      }

      cb(arg);
    },
  });

  const onReady = useEvent(() => setReady(true));

  const stripeChild = React.cloneElement(children, {
    visible: ready,
    onReady,
    ...makeEventHandler("onFocus", () => {
      setFocused(true);
    }),

    ...makeEventHandler("onBlur", () => {
      setFocused(false);
    }),

    ...makeEventHandler("onChange", (event: StripeCardEvents) => {
      if (typeof onValid === "function") {
        onValid(!event.empty && !event.error, name);
      }

      setError(!!event.error ? event.error.message : null);
    }),
  });

  return (
    <Styled.Field>
      <Styled.StripeFieldWrapper focused={focused} hasError={!!error}>
        {stripeChild}
        {!ready && <Styled.SkelletonLoader />}
      </Styled.StripeFieldWrapper>

      {!!error && <Styled.ErrorMessage>{error}</Styled.ErrorMessage>}
    </Styled.Field>
  );
};

type StripeElementProps<T> = T & {
  onValid?: (value: boolean, name: string) => void;
};

export const CardNumber = (
  props: StripeElementProps<CardNumberElementProps>
) => {
  const placeholder = "0000 0000 0000 0000";
  const defaultOptions = useElementDefaultOptions();

  return (
    <StripeField name="cardNumber" onValid={props.onValid} leftSpacing>
      <Styled.CardNumber
        {...props}
        options={{
          ...defaultOptions,
          placeholder,
          showIcon: true,
          ...(props.options || {}),
        }}
      />
    </StripeField>
  );
};

export const CardExpiry = (
  props: StripeElementProps<CardExpiryElementProps>
) => {
  const placeholder = "Exp. date (MM/YY)";
  const defaultOptions = useElementDefaultOptions();
  return (
    <StripeField name="cardExpiry" onValid={props.onValid}>
      <Styled.CardExpiry
        {...props}
        options={{
          ...defaultOptions,
          placeholder,
          ...(props.options || {}),
        }}
      />
    </StripeField>
  );
};

export const CardCvc = (props: StripeElementProps<CardCvcElementProps>) => {
  const placeholder = "CVV";
  const defaultOptions = useElementDefaultOptions();
  return (
    <StripeField name="cardCvc" onValid={props.onValid}>
      <Styled.CardCvc
        {...props}
        options={{
          ...defaultOptions,
          placeholder,
          ...(props.options || {}),
        }}
      />
    </StripeField>
  );
};

type StripeFieldValidity = {
  cardNumber: boolean;
  cardExpiry: boolean;
  cardCvc: boolean;
};
type StripeFieldNames = keyof StripeFieldValidity;

export const useStripeFields = () => {
  const elements = useElements();
  const stripe = useStripe();

  const [stripeFieldValidity, setStripeFieldValidity] =
    React.useState<StripeFieldValidity>(() => ({
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
    }));

  const onStripeFieldValid = React.useCallback(
    (valid: boolean, name: string) => {
      setStripeFieldValidity((prevState) => ({
        ...prevState,
        [name]: valid,
      }));
    },
    []
  );

  const generateToken = React.useCallback(async () => {
    if (!elements) {
      throw new Error("Could not get the stripe elements object");
    }

    const card = elements.getElement(CardNumberElement);

    if (!card) {
      throw new Error("Could not get the stripe card element");
    }

    if (!stripe) {
      throw new Error("Could not get the stripe instance object");
    }

    return stripe.createToken(card);
  }, [elements, stripe]);

  const stripeFieldsValid = React.useMemo(() => {
    const fieldNames = Object.keys(stripeFieldValidity) as StripeFieldNames[];
    return !fieldNames.some(
      (key: StripeFieldNames) => !stripeFieldValidity[key]
    );
  }, [stripeFieldValidity]);

  return {
    generateToken,
    onStripeFieldValid,
    stripeFieldsValid,
  };
};
