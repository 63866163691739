import React from "react";

import MainContentRouter from "components/Navigation/Router/MainContent";
import AccentContentRouter from "components/Navigation/Router/AccentContent";
import { Page, Section } from "components/Layout/Pages/Default";
import { LoadingSpinner } from "components/ProgressIndicator";
import NotificationCentral from "components/Notifications/Central";

import useCleanUrl from "hooks/useCleanUrl";

import SessionContext from "context/session";

const App = () => {
  useCleanUrl();

  const { ready } = React.useContext(SessionContext);

  if (!ready) {
    return <LoadingSpinner />;
  }

  // TODO We need to handle seesion errors here or the state will change to ready
  // but currentUser and currentTeam won't be available breaking the component tree
  // in case of logged users

  return (
    <>
      <NotificationCentral />

      <Page>
        <Section.Main>
          <MainContentRouter />
        </Section.Main>

        <Section.Accent>
          <AccentContentRouter />
        </Section.Accent>
      </Page>
    </>
  );
};

export default App;
