import React from "react";
import { createRoot } from "react-dom/client";

import GlobalStyles from "global-styles";
import App from "components/App";

import reportWebVitals from "lib/reportWebVitals";

import "lib/polyfills";

const container = document.getElementById("root");
const root = createRoot(container!);

const Root = ({ children }: React.PropsWithChildren) => {
  // As of now React 18 has made a lot of new changes in StrictMode
  // one of which makes it runs every lyfecicle method and effects twice
  // which I get why they did it but it also breaks a lot of things and or
  // creates unexptected behavior.
  //
  // This is still very new and I still want to see where are they goin
  // with this. The discussion can be seen here:
  //
  // https://github.com/facebook/react/issues/24455
  //
  // And the changes in behavior are here:
  //
  // https://reactjs.org/blog/2022/03/29/react-v18.html#new-strict-mode-behaviors
  //
  // For now I'm making strict mode opt in and beware that the effects and life cycles
  // will log twice as often, requests may eventually be called more than expected,
  // events registered more than expected and so on.
  if (process.env.REACT_APP_STRICT_MODE === "true") {
    return <React.StrictMode>{children}</React.StrictMode>;
  }

  return <>{children}</>;
};

root.render(
  <Root>
    <GlobalStyles />
    <App />
  </Root>
);

if (process.env.REACT_APP_WEBVITALS === "true") {
  reportWebVitals(console.log);
}
