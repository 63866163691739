import styled from "styled-components/macro";

export const TextButton = styled.button`
  /* Removes default button styles */
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;

  display: inline-flex;
  gap: 0.2rem;

  text-decoration: underline;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: ${({ theme: { colors }, disabled }) =>
    disabled ? colors.disabled : colors.primary};

  &:focus-within {
    filter: brightness(1.4);
  }
`;
