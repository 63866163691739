import React from "react";

import Flag from "components/CountryFlag";
import { Option, SelectProps } from "components/Fields/Select";
import { LoadingSpinner } from "components/ProgressIndicator";

import CountryContext from "context/country";

import * as Styled from "./styles";

type CountrySelectProps = Omit<SelectProps, "children"> & {};

const CountrySelect = (
  selectProps: CountrySelectProps,
  ref: React.Ref<HTMLInputElement>
) => {
  const { countries, ready } = React.useContext(CountryContext);

  if (!ready) {
    return <LoadingSpinner inline small />;
  }

  return (
    <Styled.Select {...selectProps} ref={ref}>
      {countries!.map((country) => {
        const code = country.code.toLowerCase();

        return (
          <Option value={code} key={code}>
            <Styled.FlagOption>
              <Flag country={code} /> {country.code}
            </Styled.FlagOption>
          </Option>
        );
      })}
    </Styled.Select>
  );
};

const ForwardRef = React.forwardRef<HTMLInputElement, CountrySelectProps>(
  CountrySelect
);

ForwardRef.defaultProps = {
  initialValue: "us",
};

export default ForwardRef;
