import { createGlobalStyle, css } from "styled-components/macro";

import * as grotesqueOtf from "fonts/BasisGrotesque/otf";
import * as grotesqueWoff2 from "fonts/BasisGrotesque/woff2";

import * as simulaOtf from "fonts/Simula/otf";
import * as simulaWoff2 from "fonts/Simula/woff2";

type Font = {
  src?: string;
  family: string;
  style?: string;
  weight?: string | number;
};

const fontFeatures = css`
  letter-spacing: -0.0075em;
  font-feature-settings: "salt";
  font-variant-numeric: tabular-nums;
  -webkit-font-smoothing: antialiased;
`;

const createFontStack = ({
  family,
  style = "normal",
  weight = "normal",
}: Font) => {
  return css`
    ${fontFeatures}

    font-family: ${fontFamilyFallbacks(family)};
    font-weight: ${weight};
    font-style: ${style};
  `;
};

const createFontFace = ({
  src,
  family,
  style = "normal",
  weight = "normal",
}: Font) => {
  return css`
    ${fontFeatures}

    font-family: ${family};
    src: ${src};
    font-weight: ${weight};
    font-style: ${style};
  `;
};

export const fontFamilyFallbacks = (family: string) =>
  `${family}, Arial, sans-serif`;

export const fonts = {
  // Basis Grotesque
  grotesque: {
    family: "Basis Grotesque",
    src: `url(${grotesqueWoff2.regular}) format('woff2'), url(${grotesqueOtf.regular}) format('opentype')`,
  },
  grotesqueBold: {
    family: "Basis Grotesque",
    weight: "bold",
    src: `url(${grotesqueWoff2.bold}) format('woff2'), url(${grotesqueOtf.bold}) format('opentype')`,
  },
  grotesqueItalic: {
    family: "Basis Grotesque",
    style: "italic",
    src: `url(${grotesqueWoff2.italic}) format('woff2'), url(${grotesqueOtf.italic}) format('opentype')`,
  },
  grotesqueMedium: {
    family: "Basis Grotesque",
    weight: 600,
    src: `url(${grotesqueWoff2.medium}) format('woff2'), url(${grotesqueOtf.medium}) format('opentype')`,
  },

  // Simula
  simula: {
    family: "Simula",
    src: `url(${simulaWoff2.regular}) format('woff2'), url(${simulaOtf.regular}) format('opentype')`,
  },
  simulaItalic: {
    family: "Simula",
    style: "italic",
    src: `url(${simulaWoff2.italic}) format('woff2'), url(${simulaOtf.italic}) format('opentype')`,
  },
};

export const fontStack = {
  // Basis Grotesque
  grotesque: createFontStack(fonts.grotesque),
  grotesqueBold: createFontStack(fonts.grotesqueBold),
  grotesqueItalic: createFontStack(fonts.grotesqueItalic),
  grotesqueMedium: createFontStack(fonts.grotesqueMedium),

  // Simula
  simula: createFontStack(fonts.simula),
  simulaItalic: createFontStack(fonts.simulaItalic),
};

export const fontFace = {
  // Basis Grotesque
  grotesque: createFontFace(fonts.grotesque),
  grotesqueBold: createFontFace(fonts.grotesqueBold),
  grotesqueItalic: createFontFace(fonts.grotesqueItalic),
  grotesqueMedium: createFontFace(fonts.grotesqueMedium),

  // Simula
  simula: createFontFace(fonts.simula),
  simulaItalic: createFontFace(fonts.simulaItalic),
};

const FontStyles = createGlobalStyle`
  /* Basis Grotesque  */

  @font-face {
    ${fontFace.grotesque}
  }

  @font-face {
    ${fontFace.grotesqueBold}
  }

  @font-face {
    ${fontFace.grotesqueItalic}
  }

  @font-face {
    ${fontFace.grotesqueMedium}
  }

  /* Simula  */

  @font-face {
    ${fontFace.simula}
  }

  @font-face {
    ${fontFace.simulaItalic}
  }
`;

export default FontStyles;
