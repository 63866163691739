import React from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

import { isEmpty } from "lib/utilities";
import VALID_QUERY_PARAMS from "lib/validQueryParams";

const useCleanUrl = () => {
  const { search, pathname } = useLocation();
  const { replace } = useHistory();

  // Wipe out unwanted query params
  React.useEffect(() => {
    const queryParams = queryString.parse(search);
    const hasQueryParams = !isEmpty(queryParams);

    if (hasQueryParams) {
      const paramKeys = Object.keys(queryParams);

      // Has invalid key in the URL => Filter them out
      if (paramKeys.some((key) => !VALID_QUERY_PARAMS.includes(key))) {
        const validQueryParams = paramKeys
          .filter((paramKey) => VALID_QUERY_PARAMS.includes(paramKey))
          .reduce(
            (validQueryParams, paramKey) => ({
              [paramKey]: queryParams[paramKey],
            }),
            {}
          );

        replace({
          pathname,
          search: queryString.stringify(validQueryParams),
        });
      }
    }
  }, [search, replace, pathname]);
};

export default useCleanUrl;
