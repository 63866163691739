import styled, { css } from "styled-components/macro";

import { rgba } from "global-styles/lib/colors";

const flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InvoicedMailFields = styled.div`
  display: grid;
  row-gap: 1.5rem;

  & > * + * {
    padding-left: 3rem;
  }
`;

export const TextInfo = styled.p`
  color: ${({ theme }) => rgba(theme.colors.text, 0.6)};
  line-height: 1.5;
  margin-top: -1.8rem;

  ${({ theme }) => theme.media.medium} {
    margin-top: -1rem;
  }
`;

export const BillingPlanOption = styled.div`
  display: flex;
  gap: 1.2rem;
`;

export const CardInfoRow = styled.div`
  display: flex;
  gap: 2rem;

  ${({ theme }) => theme.media.smallMedium} {
    display: grid;
    grid-template-areas:
      "cvc expiry"
      "zip zip";

    & > *:nth-child(1) {
      grid-area: cvc;
    }
    & > *:nth-child(2) {
      grid-area: expiry;
    }
    & > *:nth-child(3) {
      grid-area: zip;
    }
  }
`;

export const BillingPlanOptionIcon = styled.div`
  ${flexCenter}
`;

type CircleProps = {
  color?: string;
};

export const BillingPlanOptionIconCircle = styled.div<CircleProps>`
  ${flexCenter}
  width: 38px;
  height: 38px;
  padding: 0.8rem;
  border-radius: 100%;
  background: ${({ theme, color }) => (!!color ? color : theme.colors.primary)};
`;

export const BillingPlanOptionContent = styled.div`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 1rem;
  }
`;

export const BillingPlanOptionHeader = styled.h3`
  font-size: 1.2em;
  font-weight: bold;

  ${({ theme }) => theme.media.smallMedium} {
    font-size: 1em;
  }
`;

export const BillingPlanOptionDescription = styled.p`
  color: ${({ theme }) => rgba(theme.colors.text, 0.5)}};

  ${({ theme }) => theme.media.smallMedium} {
    font-size: .8em;
  }
`;
