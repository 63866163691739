export function renderStyleFromProps(propName: string, cssPropName?: string) {
  return (props: any) => {
    const value = props[propName];

    if (!!value) {
      return `
        ${cssPropName ?? propName}: ${value};
      `;
    }
  };
}
