export const isEmpty = (obj: Object | undefined) => {
  return !Object.keys(obj ?? {}).length;
};

export const removeEmpty = (obj?: Record<string, any>) => {
  const newObj = obj ?? {};
  return Object.keys(newObj).reduce((acc, it) => {
    if (newObj[it] === undefined) {
      return acc;
    }

    return { ...acc, [it]: newObj[it] };
  }, {});
};

export const pick = (
  obj: Record<string, unknown>,
  keys: Array<string | number | symbol>
) => {
  return Object.keys(obj).reduce((acc, it) => {
    if (!keys.includes(it)) {
      return acc;
    }

    return { ...acc, [it]: obj[it] };
  }, {});
};

export const sanitizeUrl = (path: string) => {
  return path.replace(/([^:]\/)\/+/g, "$1");
};
