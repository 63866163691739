import React from "react";
import { useMediaQuery } from "react-responsive";

import { Carousel, Slide, SlideChild } from "components/Carousel";

import { breakpoints } from "global-styles/lib/media";

import * as Styled from "./styles";

type BlocksProps = {
  children: React.ReactNode | React.ReactNode[];
};

const Blocks = ({ children }: BlocksProps) => {
  const isDesktop = useMediaQuery({ minWidth: breakpoints.large });

  if (isDesktop) {
    return <>{children}</>;
  }

  // TODO improve typings here
  return (
    <Carousel>
      {React.Children.map<SlideChild, any>(children, (block) => (
        <Slide>{block}</Slide>
      ))}
    </Carousel>
  );
};

const FeatureBlocks = () => {
  return (
    <Styled.FeatureBlocks>
      <Blocks>
        <Styled.Block>
          <Styled.ImagePlaceholder>
            <Styled.Image
              alt="Reduce Overhead Graphic"
              imageName="overhead"
              width={200}
            />
          </Styled.ImagePlaceholder>

          <Styled.Content>
            <Styled.Header>Reduce Overhead</Styled.Header>

            <Styled.Description>
              Forego long-term leases forever. Pay only for the space you use,
              on an as-needed basis.
            </Styled.Description>
          </Styled.Content>
        </Styled.Block>

        <Styled.Block>
          <Styled.ImagePlaceholder>
            <Styled.Image
              alt="Reduce Toptalent Graphic"
              imageName="toptalent"
              width={200}
            />
          </Styled.ImagePlaceholder>

          <Styled.Content>
            <Styled.Header>Attract top talent</Styled.Header>

            <Styled.Description>
              Attract, recruit, and retain talent by offering office solutions
              in every major market.
            </Styled.Description>
          </Styled.Content>
        </Styled.Block>

        <Styled.Block>
          <Styled.ImagePlaceholder>
            <Styled.Image
              alt="Reduce Tracking Graphic"
              imageName="tracking"
              width={200}
            />
          </Styled.ImagePlaceholder>

          <Styled.Content>
            <Styled.Header>Usage Tracking and Reporting</Styled.Header>

            <Styled.Description>
              Quickly and easily view and manage expenditures, accounting, and
              expense tracking data related to workspaces, teams, and
              individuals.
            </Styled.Description>
          </Styled.Content>
        </Styled.Block>
      </Blocks>
    </Styled.FeatureBlocks>
  );
};

export default FeatureBlocks;
