import { AxiosInstance } from "axios";

const path = "/auth";

type SessionTokens = {
  accessToken: string;
};

const createAuthRepository = (client: AxiosInstance) => {
  return {
    login: (email: string, password: string) => {
      return client.post<SessionTokens>(`${path}/login`, {
        email,
        password,
      });
    },

    // Kills session both locally and on the API
    logout: () => {
      return client.post(`${path}/logout`);
    },

    // Kills local session
    localLogout: () => {
      return client.post(`${path}/local-logout`);
    },

    getToken: () => {
      return client.get<SessionTokens>(`${path}/token`);
    },

    getDashOtp: () => {
      return client.get<string>(`${path}/dash-otp`);
    },
  };
};

export default createAuthRepository;
