import { AxiosInstance } from "axios";
import queryString from "query-string";

import { DeskpassResponse } from "repository/deskpass/types/meta";
import { TeamSetupFields } from "pages/Setup";
import {
  ID,
  TeamOrganization,
  TeamManager,
  NewMember,
  InvitedMember,
  TeamAndManager,
} from "repository/deskpass/types/data";

import { isEmpty, removeEmpty } from "lib/utilities";

type SignupResponse = {
  teamOrganization: TeamOrganization;
  teamManager: TeamManager;
};

const createTeamRepository = (client: AxiosInstance) => {
  return {
    existsWithName: (name: string) => {
      return client.get<DeskpassResponse<boolean>>(
        `/api/teams/onboarding/${name}/exists`
      );
    },

    signup: (payload: TeamSetupFields) => {
      return client.post<DeskpassResponse<SignupResponse>>(
        `/api/teams/onboarding/signup`,
        payload
      );
    },

    update: (
      teamId: ID,
      teamFields: TeamOrganization,
      triggerEvent?: string
    ) => {
      if (!isEmpty(teamFields.customization)) {
        teamFields.customization = removeEmpty(teamFields.customization);
      }

      let qs = "";

      if (!!triggerEvent) {
        qs += queryString.stringify({ triggerEvent });
      }

      return client.post<DeskpassResponse<SignupResponse>>(
        `/api/teams/onboarding/update-team/${teamId}?${qs}`,
        teamFields
      );
    },

    updateTeamAndManager: (
      teamId: ID,
      data: TeamAndManager,
      triggerEvent?: string
    ) => {
      if (!isEmpty(data.teamOrganization.customization)) {
        data.teamOrganization.customization = removeEmpty(
          data.teamOrganization.customization
        );
      }

      let qs = "";

      if (!!triggerEvent) {
        qs += queryString.stringify({ triggerEvent });
      }

      return client.post<DeskpassResponse<TeamAndManager>>(
        `/api/teams/onboarding/update/${teamId}?${qs}`,
        data
      );
    },

    invite: (teamOrganizationId: ID, inviteList: Array<NewMember>) => {
      return client.post<DeskpassResponse<Array<InvitedMember>>>(
        `/api/teams/onboarding/team-members/invite`,
        {
          teamOrganizationId,
          inviteList,
        }
      );
    },

    uploadLogo: (teamId: ID, teamSlug: string, file: File) => {
      const formData = new FormData();

      formData.append("folder", "teams");
      formData.append("subfolder", teamSlug);
      formData.append("teamOrganizationId", String(teamId));
      formData.append("upload", file);

      return client.put<DeskpassResponse<string>>("/admin/files", formData);
    },
  };
};

export default createTeamRepository;
