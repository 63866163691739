import React from "react";

import useWindowResize from "hooks/useWindowResize";

import * as Styled from "./styles";

type TabsProps = {
  children: TabChildElement | TabChildElement[];
  selected: string;
  onChange: Function;
};

export const Tabs = ({ children, selected, onChange }: TabsProps) => {
  const childrenMapper = React.useCallback(
    (child: TabChildElement) =>
      React.cloneElement<TabProps>(child, {
        ...child.props,
        active: child.props.name === selected,
        onClick: () => onChange(child.props.name),
      }),
    [onChange, selected]
  );

  return (
    <Styled.Tabs>
      <Styled.TabList>
        {React.Children.map<TabChildElement, TabChildElement>(
          children,
          childrenMapper
        )}
      </Styled.TabList>
    </Styled.Tabs>
  );
};

type TabProps = React.PropsWithChildren<{
  name: string;
  clickable?: boolean;
  active?: boolean;
  onActive?: Function;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}>;

type TabChildElement = React.ReactElement<TabProps, React.FC<TabProps>>;

export const Tab = ({
  active,
  clickable,
  children,
  onActive = () => null,
  onClick = () => null,
}: TabProps) => {
  const currentWindowSize = useWindowResize();

  const tabRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (active) {
      onActive?.(tabRef.current);
    }
  }, [active, onActive, currentWindowSize.width, currentWindowSize.height]);

  return (
    <Styled.Tab
      clickable={!!clickable}
      onClick={onClick}
      active={!!active}
      ref={tabRef}
    >
      {children}
    </Styled.Tab>
  );
};

export default Tabs;
