import { keyframes } from "styled-components/macro";

export const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const reverseSpin = keyframes`
  from { transform: rotate(180deg); }
  to { transform: rotate(0deg); }
`;
