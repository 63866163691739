import React from "react";
import { Switch, Route } from "react-router-dom";

import routes from "lib/routes";

import FeatureBlocks from "components/Accent/FeatureBlocks";
import CustomizationGradient from "components/Accent/CustomizationGradient";
import { Billing, Reservation } from "components/Accent/AccentImages";

// TODO add the other graphic components as they are build
// and associate them to the routes they belong to.
const AccentContentRouter = () => {
  return (
    <Switch>
      <Route path={routes.reservation} component={Reservation} />
      <Route path={routes.billing} component={Billing} />
      <Route
        path={[routes.customization, routes.team]}
        component={CustomizationGradient}
      />
      <Route path="*" component={FeatureBlocks} />
    </Switch>
  );
};

export default AccentContentRouter;
