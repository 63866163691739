import styled, { css, createGlobalStyle } from "styled-components/macro";

const height = "20px";
const lineThickness = "2px";

const bounce = css`
  timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
`;

const preventDragging = css`
  user-drag: none;
  user-select: none;
`;

type DiscreteValueSliderProps = {
  faded?: boolean;
};

export const DiscreteValueSlider = styled.div<DiscreteValueSliderProps>`
  position: relative;
  margin: 2rem calc(${height} / 2);
  cursor: pointer;
  display: flex;
  align-items: center;
  ${preventDragging}

  ${({ faded }) =>
    faded &&
    css`
      filter: grayscale(0.8) opacity(0.7);
    `}
`;

type Draggable = {
  dragging: boolean;
};

export const WindowCursor = createGlobalStyle<Draggable>`
  body {
    cursor: ${({ dragging }) => (dragging ? "grabbing" : "default")};
  }
`;

export const Handle = styled.div<Draggable>`
  cursor: grab;
  z-index: 10;
  position: absolute;
  width: calc(${height} * 1.1);
  height: calc(${height} * 1.1);
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 999rem;
  border: 2px solid ${({ theme }) => theme.colors.primary};

  transform: scale(1);
  filter: brightness(1);
  transition: transform, filter;

  ${bounce}

  ${({ dragging }) =>
    !dragging &&
    css`
      transition: transform, filter, left;
    `}

  ${({ dragging }) =>
    dragging &&
    css`
      cursor: grabbing;
      transform: scale(1.2);
      filter: brightness(0.8);
    `}

  transition-duration: 200ms;
`;

export const Slider = styled.ul`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${preventDragging}
`;

type DiscreteValueProps = {
  last: boolean;
};

export const DiscreteValue = styled.li<DiscreteValueProps>`
  line-height: 0;
  position: relative;
  flex: ${({ last }) => (last ? 0 : 1)};
  ${preventDragging}
`;

export const Step = styled.div`
  left: 0;
  top: 0;
  width: ${lineThickness};
  height: ${height};
  background-color: ${({ theme }) => theme.colors.blueFaint};
  position: relative;
  z-index: 1;
  ${preventDragging}
`;

export const Track = styled.div`
  flex: 1;
  position: absolute;
  top: 50%;
  left: 0;
  height: ${lineThickness};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blueFaint};
  margin-top: -1px;
  ${preventDragging}
`;

export const Label = styled.label`
  cursor: pointer;
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  top: -10px;
  left: 0;
  color: ${({ theme }) => theme.colors.blueFaint};
  font-size: 1.4rem;
  font-weight: bold;

  transition: transform, color, filter;
  transition-duration: 250ms;
  filter: brightness(0.8);

  ${bounce}
  ${preventDragging}

  &:hover {
    filter: brightness(0.6);
    transform: scale(1.1);
  }
`;
